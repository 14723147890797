/*
  GuiForcesGroup.tsx
  (c) 2024 Human Cube Inc.

  Displays a group of forces with their count values.
  Does not support any cargo rendering.
*/

import { Flag, FlagNameValues } from 'semantic-ui-react';

import { Game } from '../../bfcore/gameTypes';
import GuiForce from './GuiForce';
import { Dictionary } from '../../helper/types';


const ForcesGroup = (
  {game, playerIndex, counts, transportsByCargoSimple, liftersByLiftsSimple}:
  {game: Game, playerIndex: number, counts: number[],
   transportsByCargoSimple: Dictionary<number>, 
   liftersByLiftsSimple: Dictionary<number>, 
  }
) => {
  let factory;
  const items = [];
  for(let ft = 0; ft < counts.length; ft++) {
    if(counts[ft]) {
      if(game.forceCargoCapacity[ft] === 0 && game.forceLiftCapacity[ft] === 0) {
        // Non-Transports and non-lifters, they are handled later.
        if(ft === counts.length - 1) {
          // Save factory for later to be put at the end of the list.
          factory = <span key={ playerIndex + 'cf:' + ft }>
                      <GuiForce forceType={ game.forceID[ft] } />
                      <b style={{ marginLeft: 6 }}>{counts[ft]}</b>
                    </span>;
        } 
        else {
          items.push(
            <span key={ playerIndex + 'cf:' + ft }>
              <GuiForce forceType={ game.forceID[ft] } />
              <b style={{ marginLeft: 6 }}>{counts[ft]}</b>
            </span>
          );
        }
      }
    }
  }

  for (let key in transportsByCargoSimple) {
    const count = transportsByCargoSimple[key];
    // key is: owner:transportForceTypeIndex:cargotypeindex1:cargotypeindex2 ...
    const parts = key.split(':');
    const owner = parseInt(parts[0]);
    if(owner === playerIndex) {
      const f = parseInt(parts[1]);
      const cargo = [];
      for(let ci = 2; ci < parts.length; ci++) {
        if(parts[ci].length > 0) {
          cargo.push(
            <GuiForce key={ 'c' + ci } forceType={ game.forceID[parseInt(parts[ci])] } half />
          );
        }
      }
      const item =
        <div key={'ft:' + key}>
          <div className='flex flex-row'>
            <GuiForce forceType={ game.forceID[f] } />
            <div className='flex flex-col'>{cargo}</div>
          </div>
          <b>&nbsp;{count}</b>
        </div>;
      items.push(item);
    }
  }

  for (let key in liftersByLiftsSimple) {
    const count = liftersByLiftsSimple[key];
    // key is: owner:transportForceTypeIndex:cargotypeindex1:cargotypeindex2 ...
    const parts = key.split(':');
    const owner = parseInt(parts[0]);
    if(owner === playerIndex) {
      const f = parseInt(parts[1]);
      const cargo = [];
      for(let ci = 2; ci < parts.length; ci++) {
        if(parts[ci].length > 0) {
          cargo.push(
            <GuiForce key={ 'c' + ci } forceType={ game.forceID[parseInt(parts[ci])] } half />
          );
        }
      }
      const item =
        <div key={'ft:' + key}>
          <div className='flex flex-row'>
            <GuiForce forceType={ game.forceID[f] } />
            <div className='flex flex-col'>{cargo}</div>
          </div>
          <b>&nbsp;{count}</b>
        </div>;
      items.push(item);
    }
  }

  if(factory) {
    items.push(factory);
  }

  return(
    <div key={ 'ccf' + playerIndex } className='flex flex-row'>
      <div className='mt-2 ml-2' style={{ marginRight: -6 }}>
        <Flag name={game.playerFlag[playerIndex] as FlagNameValues} />
      </div>
      { items }
    </div>
  );
};

export default ForcesGroup;
