/*
  GuiZoneDouble.tsx
  (c) Human Cube Inc.
*/

import { Flag, FlagNameValues, Step } from 'semantic-ui-react';

import bfH from '../../bfcore/bf_helper';
import { Game } from '../../bfcore/gameTypes';


export default function GuiZoneDouble (
  { game, zoneID, zoneID2 }: { game: Game, zoneID: number, zoneID2: number }
) {

  function stopPropagation (e: any) {
    e.stopPropagation();
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Description>
            <b>Move From</b>
          </Step.Description>
          <Step.Title><center>{ game.zoneName[zoneID] }</center></Step.Title>
          <Step.Description>
            <center>
              <Flag name={ bfH.zoneOwnerFlag(game, zoneID) as FlagNameValues } />
              { bfH.zoneOwnerName(game, zoneID) }
            </center>
          </Step.Description>
        </Step.Content>
      </Step>
      <Step link>
        <Step.Content>
          <Step.Description>
            <b>Move To</b>
          </Step.Description>
          <Step.Title><center>{ game.zoneName[zoneID2] }</center></Step.Title>
          <Step.Description>
            <center>
              <Flag name={ bfH.zoneOwnerFlag(game, zoneID2) as FlagNameValues } />
              { bfH.zoneOwnerName(game, zoneID2) }
            </center>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
