/*
  BaseLocalSoloGames.tsx
*/

import { Button, Icon } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";
import { useLiveQuery } from 'dexie-react-hooks';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { soloGameDB, SoloGameDBEntry } from '../../helper/localSoloGameDB';
import { Game } from '../../bfcore/gameTypes';
import { decodeGameString, encodeGameString } from '../../bfcore/gameStringDecodeEncode';


export const LocalSoloGameList = ({uid} : {uid: number}) => {
  const navigate = useNavigate();

  const games = useLiveQuery(async () => {
    // Query the DB using our promise based API. Lazy fetches.
    return await soloGameDB.soloGames.where('uid').equals(uid).toArray();
  });

  if(!games || !Array.isArray(games)) {
    return null;
  }

  // Convert to array of Game[]:
  const soloGames = games.map((item: SoloGameDBEntry) => item.game);

  return (
    <div className='flex items-center justify-center'>
      <div className='flex flex-row flex-wrap justify-center pt-1'>
        { soloGames.map((game: Game) => {
          let localPlayerIndex = 0;
          let turnRequiredFlag = false;
          for(let p = 1; p <= game.players; p++) {
            if(game.playerID[p] === uid) {
              localPlayerIndex = p;
              if(game.required & (1 << p)) {
                turnRequiredFlag = true;
              }
              break;
            }
          }
          return (
            <div
              key={'lsg' + game._id}
              style={{padding: 1}}
              onMouseUp={() => {
                store.dispatch({type: actions.SET_GAME, game, localPlayerIndex});
                const gameStatuses = [decodeGameString(encodeGameString(game))];
                store.dispatch({type: actions.SET_GAME_STATUS, data: gameStatuses});
                navigate('/game/' + game._id);
              }}
            >
              <Button color={ turnRequiredFlag ? 'green' : 'olive' } >
                <div>
                  LOCAL
                  { ' ' + game.name }<br />
                  { '#' + game._id }
                  { turnRequiredFlag ? <Icon color='red' name='exclamation' /> : null }
                </div>
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
