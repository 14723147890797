/*
  Forces.tsx
  (c) Human Cube Inc.
*/

import { Component } from 'react';
// import PropTypes from 'prop-types';
import { withPixiApp } from '@pixi/react';
import { store } from '../../app/store';
import actions from '../../reducers/actions';
import mainModes from '../../reducers/mainModes'
import ExplosionBG from './ExplosionBG';
import ExplosionFG from './ExplosionFG';
import FlagIcon from './FlagIcon';
import Force from './Force';
import BF from '../../bfcore/bfconst1';
import { Game, Scenario } from '../../bfcore/gameTypes';
import { WorkingData } from '../../bfworking/workingTypes';
import { NumberDictionary } from '../../helper/types';

const RENDERORDER_NORMAL = 1;
const RENDERORDER_AIRCRAFT = 2;

interface ForceActual {
  ro: number;
  ft: number;
  cc: string;
  tint: number;
  x: number;
  y: number;
  z: number;
  sx: number;
  sy: number;
  sz: number;
};

interface ForceDisplay {
  ro: number;
  ft: number;
  cc: string;
  tint: number;
  x: number;
  y: number;

  sx: number;
  sy: number;
  sz: number;

  ex: number;
  ey: number;
  ez: number;

  scale: number;

  progress: number;
};

interface ForcesState {
  direction: number;

  mainMode: number;
  replayStage: number;
  replayPlay: boolean;

  lastMainMode: number | null;
  lastReplayStage: number | null;

  game: Game | null;
  working: WorkingData | {};
  scenarioEdit: Scenario | {};

  actual: NumberDictionary<ForceActual>,
  display: NumberDictionary<ForceDisplay>,

  x: number;
  y: number;
  zoom: number;
};

class Forces extends Component<any, ForcesState> {
  state: ForcesState = {
    direction: 1,

    mainMode: 0,
    replayStage: 0,
    replayPlay: true,

    lastMainMode: null,
    lastReplayStage: null,

    game: null,
    working: {},
    scenarioEdit: {},

    actual: {},
    display: {},

    x: 0,
    y: 0,
    zoom: 1,
  };

  _generateLocalState(
    game: Game, working: WorkingData, scenarioEdit: Scenario, mainMode: number, replayStage: number,
  ) {
    const actual: NumberDictionary<ForceActual> = {};
    if(mainMode === mainModes.GAME_SUBMITTING_TURN) {
      // Set all forces to final position with no animation.
      for (let f = 0; f < working.force.length; f++) {
        let force = working.force[f];
        let renderOrder = RENDERORDER_NORMAL;
        if(game.forceFlags[force.ft] &
           (BF.FORCE_FLAG_MUST_LAND_FRIENDLY | BF.FORCE_FLAG_MUST_LAND_ANY)) {
          renderOrder = RENDERORDER_AIRCRAFT;
        }
        actual[force.id] = {
          ro: renderOrder,
          ft: game.forceID[force.ft],
          cc: mainMode !== mainModes.SCENARIO_EDIT ?
              game.playerFlag[force.owner] :
              scenarioEdit.playerFlag[force.owner],
          tint: game.playerColor[force.owner],
          x: force.x,
          y: force.y,
          z: force.z,
          sx: force.x,
          sy: force.y,
          sz: force.z,
        };
      }
    }
    else if(mainMode === mainModes.GAME_REPLAY) {
      for (let f = 0; f < working.force.length; f++) {
        let force = working.force[f];
        let renderOrder = RENDERORDER_NORMAL;
        if(game.forceFlags[force.ft] &
           (BF.FORCE_FLAG_MUST_LAND_FRIENDLY | BF.FORCE_FLAG_MUST_LAND_ANY)) {
          renderOrder = RENDERORDER_AIRCRAFT;
        }
        let sx = force.rsx;
        let sy = force.rsy;
        let sz = force.rsz;
        if(replayStage > 0) {
          sx = force.rx[replayStage - 1];
          sy = force.ry[replayStage - 1];
          sz = force.rz[replayStage - 1];
        }

        actual[force.id] = {
          ro: renderOrder,
          ft: game.forceID[force.ft],
          cc: mainMode !== mainModes.SCENARIO_EDIT ?
              game.playerFlag[force.owner] :
              scenarioEdit.playerFlag[force.owner],
          tint: game.playerColor[force.owner],
          x: force.rx[replayStage],
          y: force.ry[replayStage],
          z: force.rz[replayStage],
          sx,
          sy,
          sz,
        };
      }
    }
    else {
      for (let f = 0; f < working.force.length; f++) {
        let force = working.force[f];
        let renderOrder = RENDERORDER_NORMAL;
        if(game.forceFlags[force.ft] &
           (BF.FORCE_FLAG_MUST_LAND_FRIENDLY | BF.FORCE_FLAG_MUST_LAND_ANY)) {
          renderOrder = RENDERORDER_AIRCRAFT;
        }
        actual[force.id] = {
          ro: renderOrder,
          ft: game.forceID[force.ft],
          cc: mainMode !== mainModes.SCENARIO_EDIT ?
              game.playerFlag[force.owner] :
              scenarioEdit.playerFlag[force.owner],
          tint: game.playerColor[force.owner],
          x: force.x,
          y: force.y,
          z: force.z,
          sx: force.ax,
          sy: force.ay,
          sz: force.az,
        };
      }
    }
    return actual;
  }

  constructor(props: any) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.game = props.store.game;
    this.state.working = props.store.working;
    this.state.scenarioEdit = props.store.scenarioEdit;

    this.state.zoom = props.store.camera.zoom;
    this.state.x = props.store.camera.x;
    this.state.y = props.store.camera.y;

    this.state.mainMode = props.store.mainMode;
    this.state.replayStage = props.store.replayStage;
    this.state.replayPlay = props.store.replayPlay;

    this.state.actual = this._generateLocalState(
      props.store.game,
      props.store.working,
      props.store.scenarioEdit,
      props.store.mainMode,
      props.store.replayStage,
    );
  }

  componentDidMount() {
    if(this.props.app.ticker) {
      this.props.app.ticker.add(this.animate);
    }
  }

  componentWillUnmount() {
    if (this.props.app.ticker) {
      this.props.app.ticker.remove(this.animate);
    }
  }

  animate = (delta: number) => {
    // delta is 1 if running at 100% performance
    // creates frame-independent tranformation

    this.setState((state: any) => {
      const CARGO_SCALE = 0.5;
      const mapFlags = state.game.mapFlags;
      const wrapX = (mapFlags & BF.MAP_FLAG_CAN_X_PAN_BOARD);
      const wrapY = (mapFlags & BF.MAP_FLAG_CAN_Y_PAN_BOARD);
      const { actual, game, mainMode, replayStage } = state;
      const newDisplay: NumberDictionary<ForceDisplay> = {};
      let display = state.display;

      if(state.lastMainMode !== mainMode || state.lastReplayStage !== replayStage) {
        display = {};  // reset all rendering for all forces.
      }

      let progressDelta = 0.005 * delta;
      if(mainMode === mainModes.GAME_REPLAY && !state.replayPlay) {
        progressDelta = 0.0;  // Currently in replay mode and replay is paused.
      }

      let allProgressed = true;
      for (let forceID in actual) {
        const force = actual[forceID];

        let progress = 0.0;
        if(display[forceID] &&
            display[forceID].ex === force.x &&
            display[forceID].ey === force.y &&
            display[forceID].ez === force.z &&
            display[forceID].sx === force.sx &&
            display[forceID].sy === force.sy &&
            display[forceID].sz === force.sz) {
          progress = display[forceID].progress;
        }

        if(progress < 1.0) {
          progress += progressDelta;
          if(progress > 1.0) {
            progress = 1.0;
          }
          else {
            allProgressed = false;
          }
        }
        let x = force.x;
        let y = force.y;
        if(progress < 1.0) {
          if(!wrapX) {
            x = (force.x - force.sx) * progress + force.sx;
          }
          else {
            if(force.sx < 0.25 && force.x > 0.75) {
              // Go the long way, starting going left.
              x = (force.x - force.sx - 1) * progress + force.sx;
              if(x >= 1.0) {
                x--;
              }
              if(x < 0.0) {
                x++;
              }
            }
            else if(force.sx > 0.75 && force.x < 0.25) {
              // Go the long way, starting going right.
              x = (force.x - force.sx + 1) * progress + force.sx;
              if(x >= 1.0) {
                x--;
              }
              if(x < 0.0) {
                x++;
              }
            }
            else {
              x = (force.x - force.sx) * progress + force.sx;
            }
          }

          if(!wrapY) {
            y = (force.y - force.sy) * progress + force.sy;
          }
          else {
            if(force.sy < 0.25 && force.y > 0.75) {
              // Go the long way, starting going up.
              y = (force.y - force.sy - 1) * progress + force.sy;
              if(y >= 1.0) {
                y--;
              }
              if(y < 0.0) {
                y++;
              }
            }
            else if(force.sy > 0.75 && force.y < 0.25) {
              // Go the long way, starting going down.
              y = (force.y - force.sy + 1) * progress + force.sy;
              if(y >= 1.0) {
                y--;
              }
              if(y < 0.0) {
                y++;
              }
            }
            else {
              y = (force.y - force.sy) * progress + force.sy;
            }
          }
        }

        let scale = 1.0;
        // if(force.z <= BF.FORCE_ZONE_CARGO && force.sz <= BF.FORCE_ZONE_CARGO) {
        if(force.z <= BF.FORCE_ZONE_CARGO ) {
          scale = CARGO_SCALE;
        }
        else if(force.z <= 0 && force.sz <= 0) {
          scale = 0;
        }
        else if(force.z !== force.sz) {
          if(force.z <= 0) {
            scale = 1.0 - progress;
          }
          else if(force.sz <= 0) {
            scale = progress;
          }
        }

        const forceIDNumber = parseInt(forceID);
        newDisplay[forceIDNumber] = {
          ro: force.ro,
          ft: force.ft,
          cc: force.cc,
          tint: force.tint,
          scale,
          x,
          y,
          sx: force.sx,
          sy: force.sy,
          sz: force.sz,
          ex: force.x,
          ey: force.y,
          ez: force.z,
          progress,
        };
      }

      if(allProgressed) {
        // Automatically move to next replay stage if appropriate:
        if(state.mainMode === mainModes.GAME_REPLAY && state.replayPlay) {
          if(state.replayStage < BF.MAX_REPLAY_STAGES - 1) {
            let nextReplayStage = state.replayStage + 1;
            // Skip movement replay stages that do not have any moves this turn.
            // Always show show move stage 1 at it might contain retreats and clearly shows that
            // nothing moved.
            while(nextReplayStage >= BF.REPLAY_STAGE_MOVE2
                && nextReplayStage <= BF.REPLAY_STAGE_MOVE8 ) {
              if(game.replay && Array.isArray(game.replay.move)) {
                const replayArray = game.replay.move[nextReplayStage + 1 - BF.REPLAY_STAGE_MOVE1];
                if(Array.isArray(replayArray) && replayArray.length > 0) {
                  break;
                }
              }
              // Skipping empty replay stage.
              nextReplayStage++;
            }
            store.dispatch({type: actions.SET_REPLAY_STAGE, stage: nextReplayStage});
          }
        }
      }

      return({
        display: newDisplay,
        lastMainMode: mainMode,
        lastReplayStage: replayStage,
      });
    });
  };

  UNSAFE_componentWillReceiveProps (nextProps: any) {
    if(nextProps.store.camera.zoom !== this.state.zoom ||
        nextProps.store.camera.x !== this.state.x ||
        nextProps.store.camera.y !== this.state.y ||
        nextProps.store.game !== this.state.game ||
        nextProps.store.working !== this.state.working ||
        nextProps.store.scenarioEdit !== this.state.scenarioEdit ||
        nextProps.store.mainMode !== this.state.mainMode ||
        nextProps.store.replayStage !== this.state.replayStage ||
        nextProps.store.replayPlay !== this.state.replayPlay) {
      let display = this.state.display;
      if(nextProps.store.game !== this.state.game) {
        display = {};
      }
      this.setState({
        x: nextProps.store.camera.x,
        y: nextProps.store.camera.y,
        zoom: nextProps.store.camera.zoom,
        game: nextProps.store.game,
        working: nextProps.store.working,
        scenarioEdit: nextProps.store.scenarioEdit,
        mainMode: nextProps.store.mainMode,
        replayStage: nextProps.store.replayStage,
        replayPlay: nextProps.store.replayPlay,
        actual: this._generateLocalState(
          nextProps.store.game,
          nextProps.store.working,
          nextProps.store.scenarioEdit,
          nextProps.store.mainMode,
          nextProps.store.replayStage,
        ),
        display,
      });
    }
  }

  render() {
    let { game, mainMode, replayStage } = this.state;
    game = game as Game;  // It will always exist as Game at this point.
    const { mapXScale = 256, mapYScale = 256 } = game;

    let mapXS = game.mapXS;
    let mapYS = game.mapYS;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      mapXS = 512;
      mapYS = 512;
    }

    let { x, y, zoom } = this.state;
    x -= mapXS - (zoom * mapXScale * mapXS);
    y -= mapYS - (zoom * mapYScale * mapYS);

    const MAX_SPRITE_ZOOM = 2;
    let spriteZoom = Math.min(zoom, MAX_SPRITE_ZOOM);

    let xMult = mapXS * 2 * zoom * mapXScale;
    let yMult = mapYS * 2 * zoom * mapYScale;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      spriteZoom *= 0.5;
      const HEX_SIZE = 8;
      xMult *= (game.mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2)) / mapXS;
      yMult *= (game.mapYS * HEX_SIZE) / mapYS;
    }

    let showExplosion = false;
    if( mainMode === mainModes.GAME_REPLAY && replayStage === BF.REPLAY_STAGE_DESTROY) {
      showExplosion = true;
    }

    // TODO: we could make showOwner a setting, ON, AUTO, OFF. Currently it is alway AUTO.
    let showOwnerFlag = true;
    const checkZoom = (game.mapFlags & BF.MAP_FLAG_HEXMAP) ? spriteZoom * 2 : spriteZoom;
    if(checkZoom < MAX_SPRITE_ZOOM - 0.25) {
      showOwnerFlag = false;
    }

    let forces = [];
    // Render non-cargo and non-aircraft first:
    for (let forceID in this.state.display) {
      let force = this.state.display[forceID];
      if(force.ro === RENDERORDER_NORMAL && force.ez > BF.FORCE_ZONE_CARGO && force.scale > 0) {
        if(showExplosion) {
          if(force.scale < 1.0 && force.scale > 0.25) {
            forces.push(
              <ExplosionBG
                key={ 'eb' + forceID }
                scale={ force.scale * spriteZoom }
                x={ force.x * xMult - x }
                y={ force.y * yMult - y }
              />
            );
          }
        }

        forces.push(
          <Force
            key={ 'f' + forceID }
            type={ force.ft }
            spriteProps={{
              tint: force.tint,
              scale: force.scale * spriteZoom,
              x: force.x * xMult - x,
              y: force.y * yMult - y,
            }}
          />
        );

        if(showOwnerFlag && force.cc) {
          forces.push(
            <FlagIcon
              key={ 'cf' + forceID }
              scale={ force.scale * spriteZoom * 0.5 }
              x={ force.x * xMult - x }
              y={ force.y * yMult - y }
              cc={ force.cc }
            />
          );
        }

        if(showExplosion) {
          if(force.scale < 1.0 && force.scale > 0.25) {
            forces.push(
              <ExplosionFG
                key={ 'ef' + forceID }
                scale={ force.scale * spriteZoom }
                x={ force.x * xMult - x }
                y={ force.y * yMult - y }
              />
            );
          }
        }
      }
    }

    // Render cargo next, above any ships etc:
    for (let forceID in this.state.display) {
      let force = this.state.display[forceID];
      if(force.ro === RENDERORDER_NORMAL && force.ez <= BF.FORCE_ZONE_CARGO && force.scale > 0) {
        forces.push(
          <Force
            key={ 'f' + forceID }
            type={ force.ft }
            spriteProps={{
              tint: force.tint,
              scale: force.scale * spriteZoom,
              x: force.x * xMult - x,
              y: force.y * yMult - y,
            }}
          />
        );
      }
    }

    // Render aircraft last:
    for (let forceID in this.state.display) {
      let force = this.state.display[forceID];
      if(force.ro === RENDERORDER_AIRCRAFT && force.scale > 0) {
        if(showExplosion) {
          if(force.scale < 1.0 && force.scale > 0.25) {
            forces.push(
              <ExplosionBG
                key={ 'eb' + forceID }
                scale={ force.scale * spriteZoom }
                x={ force.x * xMult - x }
                y={ force.y * yMult - y }
              />
            );
          }
        }

        forces.push(
          <Force
            key={ 'f' + forceID }
            type={ force.ft }
            spriteProps={{
              tint: force.tint,
              scale: force.scale * spriteZoom,
              x: force.x * xMult - x,
              y: force.y * yMult - y,
            }}
          />
        );

        if(showOwnerFlag && force.cc) {
          forces.push(
            <FlagIcon
              key={ 'cf' + forceID }
              scale={ force.scale * spriteZoom * 0.5 }
              x={ force.x * xMult - x }
              y={ force.y * yMult - y }
              cc={ force.cc }
            />
          );
        }

        if(showExplosion) {
          if(force.scale < 1.0 && force.scale > 0.25) {
            forces.push(
              <ExplosionFG
                key={ 'ef' + forceID }
                scale={ force.scale * spriteZoom }
                x={ force.x * xMult - x }
                y={ force.y * yMult - y }
              />
            );
          }
        }
      }
    }

    return forces;
  }
}

// Forces.contextTypes = {
//   app: PropTypes.object
// };

export default withPixiApp(Forces);
