/*
  ai/index.ts
  (c) Human Cube Inc.

  Interface between UI and standard BATTLE FORCES AI code.
  In other words, the interface for the local user to use AI to complete their
  turn.
  Respects any moves the player has already made in their current turn.

  TODO:
    - Could add an interface to allow players to flag zones or forces that they
      do not want AI to move. Currently it will not move forces that have
      already been moved by the player this turn.
*/

import cloneDeep from 'clone-deep';

import actions from '../reducers/actions';
import BF from '../bfcore/bfconst1';
import { Game, PlayerNormalTurn, PlayerPickTurn } from '../bfcore/gameTypes';
import { generatePickCapital, generatePickZone } from '../bfcore/bf_defaultpick';
import { WorkingData } from '../bfworking/workingTypes';
import { aiTurn } from '../bfai/ai_turn';


const storeSelectZone = (storeDispatch: any, zoneID: number) => {
  storeDispatch({ type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID] });
}

const storeBuyForces = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  zoneID: number, forceTypeIndex: number, count: number,
) => {
  storeDispatch({
    type: actions.PURCHASE_SOME_FORCES,
    game,
    playerIndex,
    zoneID, forceTypeIndex, count,
  });
};

const storeMoveOne = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  forceID: number, zoneID: number, x: number, y: number,
) => {
  storeDispatch({
    type: actions.MOVE_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, zoneID, x, y,
  });
}

const storeLoadOne = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  forceID: number, cargoForceID: number,
) => {
  storeDispatch({
    type: actions.LOAD_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, cargoForceID,
  });
}

const storeUnloadOne = (
  storeDispatch: any,
  game: Game,
  playerIndex: number,
  forceID: number, zoneID: number, x: number, y: number, cargoForceID: number,
) => {
  storeDispatch({
    type: actions.UNLOAD_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, zoneID, x, y, cargoForceID,
  });
}

const storeSetWorkingAI = (storeDispatch: any, ai: any) => {
  storeDispatch({
    type: actions.SET_WORKING_AI,
    ai,
  });
}

export const aiMyTurn = (storeDispatch: any, working: WorkingData, game: Game) => {
  const playerIndex = working.localPlayerIndex;
  console.log('AI My Turn for', playerIndex, 'has begun.');

  const turnID = game.turn;

  if (turnID < BF.TURN_PICK_ZONE) {
    console.log('AI has nothing to do for', playerIndex, 'game not started.');
    return;  // Game has not started yet, nothing to do.
  }
  if (turnID < BF.TURN_PICK_CAPITAL) {  // pick zone
    const turn: PlayerPickTurn = generatePickZone(game, playerIndex);
    // Just highlight the zone the AI has chosen, player can confirm and submit:
    storeSelectZone(storeDispatch, turn.pick);
    console.log('AI completed for', playerIndex, 'zone pick turn.');
    return;
  }
  if (turnID === BF.TURN_PICK_CAPITAL) {  // pick capital
    const turn: PlayerPickTurn = generatePickCapital(game, playerIndex);
    // Just highlight the zone the AI has chosen, player can confirm and submit:
    storeSelectZone(storeDispatch, turn.pick);
    console.log('AI completed for', playerIndex, 'capital pick turn.');
    return;
  }

  // normal turn:
  const turn: PlayerNormalTurn = aiTurn(
    cloneDeep(working),
    game,
    true,  // Set to false to do one move stage at a time.
    ) as PlayerNormalTurn;

  if(turn.purchase && Array.isArray(turn.purchase)) {
    for (let i = 0; i < turn.purchase.length; i++) {
      const item = turn.purchase[i];
      storeBuyForces(storeDispatch, game, playerIndex, item[1], item[0], 1);
    }
  }

  if(turn.move) {
    Object.entries(turn.move).forEach(([key, value]) => {
      const forceID = parseInt(key, 10);
      if(forceID) {
        let array = (value as number[]);
        for (let i = 0; i < array.length; i += 4) {
          const zoneID = array[i];
          const x = array[i + 1];
          const y = array[i + 2];
          const cargoID = array[i + 3];
          if(cargoID === 0) {
            storeMoveOne(storeDispatch, game, playerIndex, forceID, zoneID, x, y);
          }
          else if (cargoID === -1) {
            storeLoadOne(storeDispatch, game, playerIndex, forceID, zoneID);
          }
          else {
            storeUnloadOne(storeDispatch, game, playerIndex, forceID, zoneID, x, y, cargoID);
          }
        }
      }
    });
  }

  storeSetWorkingAI(storeDispatch, turn.ai);

  console.log('AI completed for', playerIndex, 'normal turn.');
};
