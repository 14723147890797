/*
  scenarioEditUtil.ts
  (c) Human Cube Inc.

  Functions used during scenario editing.
*/

import BF from '../bfcore/bfconst1';
import { getNewZoneXY } from '../bfcore/bf_hitmap';
import { forceTypeCache } from '../bfcore/bf_forceTypeFixtures';
import { Game } from '../bfcore/gameTypes';
import { WorkingData } from '../bfworking/workingTypes';


export function deleteForcesInZone (working: WorkingData, zoneID: number) {
  // Only used during scenarioEdit.
  // Removes any loaded cargo as well.
  // Modifies working.
  const force = working.force;
  let forces = force.length;
  const cargoZoneIDs = [];  // zoneIDs of related cargo to remove.
  for(let i = 0; i < forces; i++) {
    if(force[i].z === zoneID) {
      cargoZoneIDs.push(BF.FORCE_ZONE_CARGO - force[i].id);
      force.splice(i, 1);
      i--;
      forces--;
    }
  }

  // Remove any related cargo:
  for(let i = 0; i < forces; i++) {
    if(cargoZoneIDs.includes(force[i].z)) {
      force.splice(i, 1);
      i--;
      forces--;
      break;
    }
  }
}

export function changeForcesOwnerInZone (
  working: WorkingData, zoneID: number, playerIndex: number,
) {
  // Only used during scenarioEdit.
  // Changes any loaded cargo as well.
  // Modifies working.
  const force = working.force;
  const forces = force.length;
  const cargoZoneIDs = [];  // zoneIDs of related cargo to remove.
  for(let i = 0; i < forces; i++) {
    if(force[i].z === zoneID) {
      cargoZoneIDs.push(BF.FORCE_ZONE_CARGO - force[i].id);
      force[i].owner = playerIndex;
    }
  }

  // Change any related cargo:
  for(let i = 0; i < forces; i++) {
    if(cargoZoneIDs.includes(force[i].z)) {
      force[i].owner = playerIndex;
      break;
    }
  }
}

function createForce (
  working: WorkingData, game: Game, forceTypeIndex: number, owner: number, zoneID: number,
) {
  // Only used during scenarioEdit.
  // Creates a new force.
  // Modifies working.
  const z = zoneID;
  const p = getNewZoneXY(game, z);
  let { x, y } = p;
  x /= BF.WORLD_WIDTH;
  y /= BF.WORLD_HEIGHT;

  working.force.push({
    // Fixed attributes:
    id: working.nextForceID++,
    ft: forceTypeIndex,
    owner,
    // Current Turn Starting Positions:
    sx: x,
    sy: y,
    sz: z,
    sr: 0,
    slp: 0,
    sllp: 0,
    szr: 0,
    // Current Turn Animation Start Positions:
    ax: x,
    ay: y,
    az: z,
    // Current Turn Positions:
    x,
    y,
    z,
    r: 0,
    lp: 0,
    llp: 0,
    zr: 0,
    // Replay Starting Postions:
    rsx: x,
    rsy: y,
    rsz: z,
    rsr: 0,
    // Replay state (stage 0):
    rx: [x],
    ry: [y],
    rz: [z],
    // This turns movement commands:
    move: []  // Array of moves
  });
}

export function createStartingForces (
  working: WorkingData,
  game: Game,
  forceTypeIDs: number[],
  startingForces: number[],
  owner: number,
  zoneID: number,
  navyZoneID: number,
) {
  // Only used during scenarioEdit.
  // Creates the land starting forces if zoneID non-zero.
  // Creates the water starting forces if navyZoneID non-zero.
  // Modifies working.
  for(let f = 0; f < forceTypeIDs.length; f++) {
    const c = startingForces[f];
    if(c) {
      for(let cc = 0; cc < c; cc++) {
        const forceType = forceTypeCache[forceTypeIDs[f]];
        if(zoneID && (forceType.flags & BF.FORCE_FLAG_STARTS_ON_LAND)) {
          createForce(working, game, f, owner, zoneID);
        }
        else if(navyZoneID && (forceType.flags & BF.FORCE_FLAG_STARTS_ON_WATER)) {
          createForce(working, game, f, owner, navyZoneID);
        }
      }
    }
  }
}
