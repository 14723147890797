/*
  GuiLoadLiftersWindow.tsx
  (c) Human Cube Inc.

  Loading same zone lifters window.
  Hardcoded to 'Paratroopers' text for better human readabiliity.
*/

import { useEffect, useState } from 'react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiForce from './GuiForce';
import GuiForceTransport from './GuiForceTransport';
import { Game } from '../../bfcore/gameTypes';
import GuiTile from './GuiTile';
import GuiButton from './GuiButton';


function GuiForceLoadLine (
  { game, zoneID, localPlayerIndex, forceTypeIndex, count, selectedLifterLiftKey }:
  { game: Game, zoneID: number, localPlayerIndex: number,
    forceTypeIndex: number, count: number, selectedLifterLiftKey: string }
) {

  function loadOne () {
    store.dispatch({
      type: actions.LOAD_SOME_LIFT_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      lifterZoneID: zoneID,  // Same zone.
      forceTypeIndex: forceTypeIndex,
      count: 1,
      lifterLiftKey: selectedLifterLiftKey,
    });
  };

  function loadAll () {
    store.dispatch({
      type: actions.LOAD_SOME_LIFT_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      lifterZoneID: zoneID,  // Same zone.
      forceTypeIndex: forceTypeIndex,
      count: count,
      lifterLiftKey: selectedLifterLiftKey,
    });
  };

  return (
    <div className='flex flex-row' style={{ padding: 1 }}>
      <div className='mt-2 mx-1'>
        <b>{ count }</b>&nbsp;
      </div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;
      <div style={{ marginLeft: 6 }}>
        <GuiButton key='loadOne' onClick={ loadOne }> Load 1 </GuiButton>
      </div>
      <div style={{ marginLeft: 6 }}>
        <GuiButton key='loadAll' onClick={ loadAll }> Load All </GuiButton>
      </div>
    </div>
  );
}


export default function GuiLoadLiftersWindow (
  { game, details, zoneID, localPlayerIndex, hideWindow }:
  { game: Game, details: ForceDetailsForZone, zoneID: number, localPlayerIndex: number,
    hideWindow: () => void }
) {

  function calculateSelectedLifterLiftKey (): string {
    let suggestedLifterLiftKey = '';
    let suggestedLifterLiftScore = 0;
    for (var key in details.liftersByLifts) {
      // key is:  lifterForceTypeIndex:range:loadPoints:lifttypeindex1:lifttypeindex2 ...
      const parts = key.split(':');
      const range = parseInt(parts[1]);
      const loadPoints = parseInt(parts[2]);
      if(loadPoints > 0) {
        const count = details.liftersByLifts[key];
        if(count > 0) {
          const score = range * 1000000 + loadPoints;
          if(score >= suggestedLifterLiftScore) {
            suggestedLifterLiftKey = key;
            suggestedLifterLiftScore = score;
          }
        }
      }
    }
    return suggestedLifterLiftKey;
  }

  const [selectedLifterLiftKey, setSelectedLifterLiftKey] =
    useState(calculateSelectedLifterLiftKey());

  useEffect(() => {
    if(!details.liftersByLifts[selectedLifterLiftKey]) {
      for (var key in details.liftersByLifts) {
        const parts = key.split(':');
        const loadPoints = parseInt(parts[2]);
        if(loadPoints > 0) {
          const count = details.liftersByLifts[key];
          if(count > 0) {
            setSelectedLifterLiftKey(key);
            break;
          }
        }
      }
    }
  }, [details.liftersByLifts, selectedLifterLiftKey]);

  if(details.zoneLiftPoints <= 0) {
    return (
      <GuiTile edge='left'>
        <p><b>No remaining lift points.</b></p>
        <p>These transports are unable to load any additional paratroopers this turn.</p>
        <GuiButton onClick={ hideWindow } center={ true }>
          Close
        </GuiButton>
      </GuiTile>
    );
  }

  let loadLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    const r = game.forceRange[f];  // Only those with maximum range.
    const count = details.forcesByRange[localPlayerIndex][r][f];
    if(count <= 0) {
      continue;
    }
    if(game.forceLiftSpace[f] >= 0) {
      loadLine.push(
        <GuiForceLoadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
          localPlayerIndex={ localPlayerIndex } count={ count }
          selectedLifterLiftKey={ selectedLifterLiftKey }
        />
      );
    }
  }

  if(loadLine.length < 1) {
    return (
      <GuiTile edge='left'>
        <p><b>No available paratroopers.</b></p> 
        <p>Paratroopers must not be blocked and must have full available range to be able to be loaded.</p>
        <GuiButton onClick={ hideWindow } center={ true }>Close</GuiButton>
      </GuiTile>
    );
  }

  const lifterChoices = [];

  for (let key in details.liftersByLifts) {
    const count = details.liftersByLifts[key];
    // key is:  lifterForceTypeIndex:range:liftPoints:lifttypeindex1:lifttypeindex2 ...
    const parts = key.split(':');
    const liftPoints = parseInt(parts[2]);
    if(liftPoints > 0) {
      const kkey = key;
      lifterChoices.push(
        <div
          key={'liftc' + key}
          className={ key === selectedLifterLiftKey ? 'border-2 border-green-400 p-2 bg-slate-200' : 'border-2 p-2' }
          style={{ width: 96, margin: 1 }}
          onClick={ (_e: any) => { setSelectedLifterLiftKey(kkey); } }
        >
          <GuiForceTransport transportCargoKey={ key } game={ game } count={ count } />
        </div>
      );
    }
  }

  return (
    <GuiTile edge='left' maxWidth={ 234 }>
      Loadable Paratroopers:<br />
      { loadLine }
      <br />
      Destination Transport Force:
      <div className='flex flex-row flex-wrap'>
        { lifterChoices }
      </div>
      <p>Total Lift Points Remaining: { details.zoneLiftPoints }</p>
      <GuiButton onClick={ hideWindow } center={ true }>
        Close
      </GuiButton>
    </GuiTile>
  );
}
