/*
  GuiForceTransport.tsx
  (c) Human Cube Inc.
*/

import { Game } from '../../bfcore/gameTypes';
import GuiForce from './GuiForce';


function GuiForceTransport (
  {game, count, transportCargoKey}:
  {game: Game, count: number, transportCargoKey: string}
) {
  const cargo = [];
  let loadPointsDisplay = null;
  const cargoParts = transportCargoKey.split(':');
  const forceType = game.forceID[parseInt(cargoParts[0])];
  const range = cargoParts[1];
  loadPointsDisplay = <small>LP:&nbsp;{cargoParts[2]}&nbsp;</small>;
  for(let i = 3; i < cargoParts.length; i++) {
    if(cargoParts[i].length > 0) {
      cargo.push(
        <GuiForce key={'c' + i} forceType={game.forceID[parseInt(cargoParts[i])]} half />
      );
    }
  }
  return (
    <div className='flex flex-row'>
      <div className='flex flex-col'>
        <div className='flex flex-row'>
          {count &&
            <div key='count' className='mt-1'><b>{count}</b>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          <GuiForce forceType={forceType} />
          {cargo}
        </div>
        <div key='points' className='mt-1'>
          { loadPointsDisplay }&nbsp;&nbsp;<small>Range:&nbsp;{ range }</small>
        </div>
      </div>
    </div>
  );
}

export default GuiForceTransport;
