/*
  GuiPlayerRow.tsx
  (c) Human Cube Inc.
*/

import { Flag, Icon, Table } from 'semantic-ui-react';

import BF from '../../bfcore/bfconst1';
import { linkTextLight } from '../../helper/style';
import { Game } from '../../bfcore/gameTypes';


function ordinalStr (n: number) {
  return n + (['','st','nd','rd'][~~(n/10%10)-1?n%10:0] || 'th');
}

function finishedPosStr (pos: number) {
  if(!pos) {
    return '-';
  }
  return ordinalStr(pos);
}

function playerForcesCount (game: Game, playerIndex: number) {
  // Returns count.
  let count = 0;
  if(Array.isArray(game.force) && Array.isArray(game.force[playerIndex]))
    for(let i = 0; i < game.force[playerIndex].length; i++) {
      const zoneID = game.force[playerIndex][i][BF.FORCE_INDEX_ZONE];
      if(zoneID >= BF.FORCE_ZONE_PRODUCED || zoneID <= BF.FORCE_ZONE_CARGO)
          count++;  // Only count live forces.
    }
  return count;
}

export default function GuiPlayerRow (
  {playerIndex, playerIncome, playerZones, store, select}:
  {playerIndex: number, playerIncome: number, playerZones: number, store: any, select: any}
) {

  const gotoPlayer = (e: any) => {
    e.stopPropagation();
    if(select) {
      select(playerIndex);
    }
  };

  const playerTitle = () => {
    const { game, gameID, gameStatus } = store;
    let playerName = [];
    if(gameID) {
      if( gameStatus[gameID]) {
        ({ playerName } = gameStatus[gameID]);
      }
      else if(gameID === game._id) {
        playerName = game.playerName;
      }
    }
    const userName = playerName[playerIndex];
    if(userName) {
      return(
        <span onPointerDown={ gotoPlayer } style={ linkTextLight } >
          { userName }
        </span>
      );
    }
    return <span>- Empty Slot -</span>;
  };

  const { game, gameID, gameStatus } = store;
  const p = playerIndex;

  let required = 0;
  let submitted = 0;
  if(gameStatus[gameID]) {
    ({ required, submitted } = gameStatus[gameID]);
  }

  return(
    <Table.Row key={ 'tr' + p } >
      { game.state !== BF.GAME_STATE_FINISHED &&
        <Table.Cell textAlign='center'>
          {(required & (1<<p)) ? ((submitted & (1<<p)) ?
            <Icon color='green' name='check circle' /> :
            <Icon color='red' name='exclamation' />) : '-'
          }
        </Table.Cell>
      }
      { game.teams > 1 &&
        <Table.Cell>
          { game.teamName[game.team[p]] }
        </Table.Cell>
      }
      <Table.Cell>
        <Icon name='square' style={{color: '#' + game.playerColor[p].toString(16) }} />&nbsp;
        <Flag name={ game.playerFlag[p] } />&nbsp;
        { playerTitle() }
      </Table.Cell>
      <Table.Cell>{ game.capital[p] > 0 ? game.zoneName[game.capital[p]] : '-' }</Table.Cell>
      <Table.Cell textAlign='center'>
        {(Array.isArray(game.finished) && game.finished[p][0]) ?
            (game.finished[p][0] === 1 ? <b>1st</b> :
        finishedPosStr(game.finished[p][0])) :
        '-'
        }
      </Table.Cell>

      <Table.Cell textAlign='center'>{ playerIncome }</Table.Cell>
      <Table.Cell textAlign='center'>
        { Array.isArray(game.cash) ? game.cash[p] : '-' }
      </Table.Cell>

      <Table.Cell textAlign='center'>{ playerForcesCount(game, p)}</Table.Cell>
      { game.state !== BF.GAME_STATE_FINISHED &&
        <Table.Cell textAlign='center'>
          { (Array.isArray(game.maxBuy) && Array.isArray(game.finished) &&
              Array.isArray(game.finished[p]) && !game.finished[p][0]) ? game.maxBuy[p] : '-' }
        </Table.Cell>
      }
      <Table.Cell textAlign='center'>{ playerZones }</Table.Cell>

      { game.state === BF.GAME_STATE_FINISHED &&
        <Table.Cell textAlign='center'>
          {(Array.isArray(game.finished) && game.finished[p][2]) ?
            (game.finished[p][2] > 0 ? '+' + game.finished[p][2] : game.finished[p][2]) : '0'
          }
        </Table.Cell>
      }
      { game.state === BF.GAME_STATE_FINISHED &&
        <Table.Cell textAlign='center'>
          {(Array.isArray(game.finished) && game.finished[p][3]) ?
            (game.finished[p][3] > 0 ? '+' + game.finished[p][3] : game.finished[p][2]) : '0'
          }
        </Table.Cell>
      }

    </Table.Row>
  );
}
