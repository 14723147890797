/*
  GuiPlayers.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';
import { Segment, Table } from 'semantic-ui-react';

import GuiPlayerDetail from './GuiPlayerDetail';
import GuiPlayerRow from './GuiPlayerRow';
import { sendPacket } from '../../network/network';
import { calculateIncomeAndZones } from '../../helper/game';
import BF from '../../bfcore/bfconst1';
import GuiButton from './GuiButton';


export default function GuiPlayers ({close, store}: {close: any, store: any}) {

  const [selectedUID, setSelectedUID] = useState(0);

  const hidePlayers = () => {
    if(close) {
      close();
    }
  };

  const selectPlayer = (playerIndex: number) => {
    console.log('selectPlayer', playerIndex);
    const { gameID, gameStatus } = store;
    if(gameID && gameStatus[gameID]) {
      const { playerID } = gameStatus[gameID];
      const actualPlayerID = playerID[playerIndex];
      if(selectedUID === actualPlayerID) {
        setSelectedUID(0);  // Toggle off.
      }
      else if(actualPlayerID) {
        sendPacket('bf_get', {e: 'playerInfo', uid: actualPlayerID});  // TODO: could check if in store before requesting again.
        setSelectedUID(actualPlayerID);
      }
    }
  };

  const hidePlayer = (e: any) => {
    e.stopPropagation();
    setSelectedUID(0);
  };

  const { gameID, game } = store;
  if(game._id !== gameID) {
    return null;
  }

  const { playerIncome, playerZones } = calculateIncomeAndZones(game);

  const playerRows = [];
  for(let p = 1; p <= game.players; p++) {
    playerRows.push(
      <GuiPlayerRow
        key={ 'p' + p }
        store={ store }
        playerIndex={ p }
        playerIncome={ playerIncome[p] }
        playerZones={ playerZones[p] }
        select={ selectPlayer }
      />
    );
  }

  return (
    <>
      <div
        className='max-h-[75%] bg-slate-900'
        style={{
          overflowY: 'scroll', 
          position: 'absolute', top: '42px', right: '0px', opacity: '90%',
        }}
        onPointerDown={(e) => { e.stopPropagation(); }}
      >
        <div className='flex flex-row justify-between'>
          <div className='text-xl text-slate-50 pl-6 pt-3'>
            Players In This Game
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ({ game.humanPlayers } Human, { game.computerPlayers } AI)
          </div>
          <div className='pt-1'>
            <GuiButton onClick={ hidePlayers }>
              Close
            </GuiButton>
          </div>
        </div>
        <Table inverted attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
              { game.state !== BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>Turn</Table.HeaderCell>
              }
              { game.teams > 1 &&
                <Table.HeaderCell textAlign='center'>Team</Table.HeaderCell>
              }
              <Table.HeaderCell textAlign='center'>Player</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Capital</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Finish</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Income</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Cash</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Forces</Table.HeaderCell>
              { game.state !== BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>MaxBuy</Table.HeaderCell>
              }
              <Table.HeaderCell textAlign='center'>Zones</Table.HeaderCell>
              { game.state === BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>Points</Table.HeaderCell>
              }
              { game.state === BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>XPs</Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { playerRows }
          </Table.Body>
        </Table>
      </div>
      { selectedUID &&
        <Segment compact inverted onPointerDown={ hidePlayer }
                  style={{ position: 'absolute', top: '50px', right: '12px'}}>
          <GuiPlayerDetail uid={ selectedUID }
                            playerInfos={ store.playerInfos }
                            playerStrings={ store.playerStrings }/>
        </Segment>
      }
    </>
  );
}
