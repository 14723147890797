/*
  GuiForceTypeRow.tsx
  (c) Human Cube Inc.
*/

import { Table } from 'semantic-ui-react';

import { linkTextLight } from '../../helper/style';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import { Game } from '../../bfcore/gameTypes';


export default function GuiForceTypeRow (
  { forceTypeIndex, game, select }: { forceTypeIndex: number, game: Game, select: any }
) {

  const showForce = (e: any) => {
    e.stopPropagation();
    if(select) {
      select(forceTypeIndex);
    }
  };

  let range = '-';
  if(game.forceRange[forceTypeIndex] > 0) {
    range = '' + game.forceRange[forceTypeIndex];
    if(game.forceFlags[forceTypeIndex] &
        (BF.FORCE_FLAG_MUST_LAND_FRIENDLY | BF.FORCE_FLAG_MUST_LAND_ANY)) {
      range += '*';
    }
  }

  let attack = '-';
  if(game.forceAttack[forceTypeIndex] > 0) {
    attack = game.forceAttack[forceTypeIndex] + '%';
    if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_ONESHOTPERTARGET) {
      attack += '*';
    }
    if(Array.isArray(game.forceAttackMultiple) && game.forceAttackMultiple[forceTypeIndex] > 1) {
      attack += ' x' + game.forceAttackMultiple[forceTypeIndex];
    }
  }

  let defense = '-';
  if( game.forceDefense[forceTypeIndex] > 0) {
    defense = game.forceDefense[forceTypeIndex] + '%';
    if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_ONESHOTPERTARGET) {
      defense += '*';
    }
    if(Array.isArray(game.forceDefenseMultiple) && game.forceDefenseMultiple[forceTypeIndex] > 1) {
      defense += ' x' + game.forceDefenseMultiple[forceTypeIndex];
    }
  }

  return(
    <Table.Row key={ 'tr' + forceTypeIndex } >
      <Table.Cell>
        <span onPointerDown={ showForce } style={ linkTextLight } >
          { game.forceName[forceTypeIndex] }
        </span>
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <div style={{height: 24, width: 24}}>
          <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;&nbsp;&nbsp;
        </div>
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { game.forceCost[forceTypeIndex] }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { range }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { attack }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { defense }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { game.forceCargoCapacity[forceTypeIndex] ?
            game.forceCargoCapacity[forceTypeIndex] : '-' }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { game.forceLiftCapacity[forceTypeIndex] ?
            game.forceLiftCapacity[forceTypeIndex] : '-' }
      </Table.Cell>
      <Table.Cell textAlign='center'>
        { game.forceCarrierCapacity[forceTypeIndex] ?
            game.forceCarrierCapacity[forceTypeIndex] : '-' }
      </Table.Cell>
    </Table.Row>
  );
}
