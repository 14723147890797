/*
  GuiUnloadTransportsWindow.tsx
  (c) Human Cube Inc.
*/

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { Game } from '../../bfcore/gameTypes';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiForce from './GuiForce';
import GuiTile from './GuiTile';
import GuiButton from './GuiButton';


function GuiForceUnloadLine (
  { count, forceTypeIndex, game, localPlayerIndex, zoneID, zoneID2 }:
  { count: number, forceTypeIndex: number, game: Game,
    localPlayerIndex: number, zoneID: number, zoneID2: number }
) {

  function unloadOne () {
    store.dispatch({
      type: actions.UNLOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
      zoneID2,
      forceTypeIndex,
      count: 1,
    });
  };

  function unloadAll () {
    store.dispatch({
      type: actions.UNLOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
      zoneID2,
      forceTypeIndex,
      count,
    });
  };

  return (
    <div className='flex flex-row space-x-2' style={{ marginTop: 2 }}>
      <div style={{ marginTop: 5 }}><b>{ count }</b></div>
      <div style={{ marginTop: 3 }}>
        <GuiForce forceType={ game.forceID[forceTypeIndex] } />
      </div>
      <GuiButton key='unloadOne' onClick={ unloadOne }> Unload 1 </GuiButton>
      <GuiButton key='unloadAll' onClick={ unloadAll }> Unload All </GuiButton>
    </div>
  );
}

export default function GuiForceUnLoadWindow (
  { details, game, localPlayerIndex, zoneID, zoneID2 }:
  { details: ForceDetailsForZone, game: Game,
    localPlayerIndex: number, zoneID: number, zoneID2: number }
) {

  function hideUnloadTransportsWindow () {
    store.dispatch({
      type: actions.HIDE_UNLOAD_TRANSPORTS_WINDOW,
    });
  }

  if(!details.hasCargo) {
    return (
      <GuiTile>
        <b>No remaining cargo to unload.</b>
      </GuiTile>
    );
  }

  let unloadLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    const count = details.cargo[f]
    if(count > 0) {
      unloadLine.push(
        <GuiForceUnloadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
          zoneID2={ zoneID2 } localPlayerIndex={ localPlayerIndex } count={ count }
        />
      );
    }
  }

  let blockedText = null;
  if(details.blockedSurfaceCount) {
    blockedText =
      <div style={{ color: 'red' }}>
        <br />
        <b>
          There are opposing forces in the transport's<br />
          zone that may block the unloading of cargo.
        </b>
      </div>;
  }

  return (
    <GuiTile>
      { unloadLine }
      { blockedText }
      <br />
      <GuiButton onClick={ hideUnloadTransportsWindow } center={ true } >
        Close
      </GuiButton>
    </GuiTile>
  );
}
