/*
  GuiForce.tsx
  (c) Human Cube Inc.
*/

import fi1 from '../GamePixi/img/forcetype/fi1.png';
import fi2 from '../GamePixi/img/forcetype/fi2.png';
import fi3 from '../GamePixi/img/forcetype/fi3.png';
import fi4 from '../GamePixi/img/forcetype/fi4.png';
import fi5 from '../GamePixi/img/forcetype/fi5.png';
import fi6 from '../GamePixi/img/forcetype/fi6.png';
import fi7 from '../GamePixi/img/forcetype/fi7.png';
import fi8 from '../GamePixi/img/forcetype/fi8.png';
import fi9 from '../GamePixi/img/forcetype/fi9.png';
import fi10 from '../GamePixi/img/forcetype/fi10.png';
import fi11 from '../GamePixi/img/forcetype/fi11.png';
import fi12 from '../GamePixi/img/forcetype/fi12.png';
import fi13 from '../GamePixi/img/forcetype/fi13.png';
import fi14 from '../GamePixi/img/forcetype/fi14.png';
import fi15 from '../GamePixi/img/forcetype/fi15.png';
import fi16 from '../GamePixi/img/forcetype/fi16.png';
import fi17 from '../GamePixi/img/forcetype/fi17.png';
import fi18 from '../GamePixi/img/forcetype/fi18.png';
import fi19 from '../GamePixi/img/forcetype/fi19.png';
import { NumberDictionary } from '../../helper/types';


const textures: NumberDictionary<any> = {
  1: fi1,
  2: fi2,
  3: fi3,
  4: fi4,
  5: fi5,
  6: fi6,
  7: fi7,
  8: fi8,
  9: fi9,
  10: fi10,
  11: fi11,
  12: fi12,
  13: fi13,
  14: fi14,
  15: fi15,
  16: fi16,
  17: fi17,
  18: fi18,
  19: fi19,
};

export default function GuiForce ({forceType, half}: {forceType: number, half?: boolean}) {
  let texture = '';
  if(textures[forceType]) {
    texture = textures[forceType];
  }
  const size = half ? 12 : 24;
  return <img src={ texture }
              style={{ width: size, height: size }}
              alt={'fi' + forceType}
         />;
}
