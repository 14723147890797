/*
  GuiButton.tsx
  (c) Human Cube Inc.
  Standard BATTLE FORCES Game GUI Button.
*/


function GuiButton (
  {children, height = 32, center = false, onClick}:
  {
    children: any,
    height?: number,  // Button height, default 32.
    center?: boolean,  // Center button contents, default false.
    onClick: () => void,
  }
) {
  return (
    <div
      className=
        {
          `flex flex-row items-center ${
          center ? 'justify-center' : ''
          } space-x-2 px-3 py-2 rounded bg-slate-200 hover:bg-slate-50 cursor-pointer`
        }
      style={{ height, userSelect: 'none' }}
      onPointerUp={ (e: any) => {
        e.stopPropagation();
        if(onClick) {
          onClick();
        }
      }} 
    >
      { children }
    </div>
  );
}

export default GuiButton;
