/*
  GuiZone.tsx
  (c) Human Cube Inc.

  Notes:
    - Supports transports that load from neighbouring zones only.
    - Supports lifters that load from same zone only.
*/

import BF from '../../bfcore/bfconst1';
import mainModes from '../../reducers/mainModes';
import { forceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiForcePurchaseWindow from './GuiForcePurchaseWindow';
import GuiForceRetreatWindow from './GuiForceRetreatWindow';
import GuiLandingHereWindow from './GuiLandingHereWindow';
import GuiLandingListWindow from './GuiLandingListWindow';
import GuiLandingSpecifyWindow from './GuiLandingSpecifyWindow';
import GuiLoadTransportsWindow from './GuiLoadTransportsWindow';
import GuiUnloadTransportsWindow from './GuiUnloadTransportsWindow';
import GuiZoneDouble from './GuiZoneDouble';
import GuiZoneSingle from './GuiZoneSingle';
import GuiZoneMoveInfo from './GuiZoneMoveInfo';


export default function GuiZone ({ store }: { store: any }) {

  const { game, mainMode, replayStage, showLandingSpecifyWindow, working } = store;
  const [zoneID, zoneID2] = store.selectedZoneIDs;

  let { localPlayerIndex } = working;

  if(mainMode !== mainModes.GAME_DOING_TURN) {
    if(
      mainMode !== mainModes.GAME_REPLAY ||
      !(game.turn >= BF.TURN_PICK_ZONE && game.turn <= BF.TURN_PICK_CAPITAL) ||
      localPlayerIndex <= 0 ||
      !(game.required & (1<<localPlayerIndex)) ||
      (game.submitted & (1<<localPlayerIndex))
    ) {
      // It is not a picking turn and not doing turn, so do not allow special direct picking play
      // in replay mode:
      localPlayerIndex = -1;  // General display unless doing turn, or replay during picking turn.
    }
  }

  if(store.showForceRetreatWindow) {
    return <GuiForceRetreatWindow zoneID={ zoneID }
                                  game={ game }
                                  localPlayerIndex={ localPlayerIndex }
                                  working={ working } />;
  }

  if(showLandingSpecifyWindow.show) {
    return <GuiLandingSpecifyWindow zoneID={ zoneID }
                                    game={ game }
                                    localPlayerIndex={ localPlayerIndex }
                                    working={ working }
                                    forceTypeIndex={ showLandingSpecifyWindow.forceTypeIndex }
                                    range={ showLandingSpecifyWindow.range }
                                    destination={ showLandingSpecifyWindow.destination }
            />;
  }
  if(store.showLandingListWindow) {
    return <GuiLandingListWindow zoneID={ zoneID }
                                  game={ game }
                                  localPlayerIndex={ localPlayerIndex }
                                  working={ working } />;
  }
  if(store.showLandingHereWindow) {
    return <GuiLandingHereWindow game={ game } working={ working } zoneID={ zoneID } />;
  }

  const details = forceDetailsForZone(game, working, zoneID, zoneID2,
                                      mainMode === mainModes.GAME_REPLAY ? replayStage : -1);

  if(store.showForcePurchaseWindow) {
    return <GuiForcePurchaseWindow zoneID={ zoneID } game={ game } details={ details }
                                    localPlayerIndex={ localPlayerIndex } />;
  }

  let otherWindow = null;
  if(store.showLoadTransportsWindow) {
    otherWindow =
      <GuiLoadTransportsWindow zoneID={zoneID} zoneID2={zoneID2} game={game} details={details}
        localPlayerIndex={localPlayerIndex}
      />;
  }
  else if(store.showUnloadTransportsWindow) {
    otherWindow =
      <GuiUnloadTransportsWindow zoneID={zoneID} zoneID2={zoneID2} game={game} details={details}
        localPlayerIndex={localPlayerIndex}
      />;
  }
  else {
    otherWindow =
      <GuiZoneMoveInfo zoneID={ zoneID } zoneID2={ zoneID2 } game={ game } details={ details }
        localPlayerIndex={ localPlayerIndex }
      />;
  }

  if(zoneID2 > 0) {
    return (
      <div className='flex flex-col'>
        <GuiZoneDouble zoneID={ zoneID } zoneID2={ zoneID2 } game={ game } />
        <div>{ otherWindow }</div>
      </div>
    );
  }

  if(zoneID > 0) {
    return <GuiZoneSingle zoneID={ zoneID } game={ game } details={ details }
                          localPlayerIndex={ localPlayerIndex }
                          mainMode={ mainMode } replayStage={ replayStage }
                          working={ working } />;
  }

  return null;
}
