/*
  GuiModal.tsx
  (c) Human Cube Inc.
*/

import { Button, Modal } from 'semantic-ui-react';


const GuiModal = ({
  title,
  body,
  cancelCallback,
  cancelText = 'Cancel',
  okCallback,
  okText = 'OK',
}: {
  title: string,
  body: string,
  cancelCallback?: any,
  cancelText?: string,
  okCallback?: any,
  okText?: string,
}) => {

  const cancelClose = (e: any) => {
    e.stopPropagation();
    if( cancelCallback ) {
      cancelCallback();
    }
  };

  const okClose = (e: any) => {
    e.stopPropagation();
    if( okCallback ) {
      okCallback();
    }
  };

  return (
    <Modal
      closeOnDimmerClick={ false }
      closeOnEscape={ false }
      dimmer='inverted'
      open={ true }
      size='small'
    >
      <Modal.Content>
        <Modal.Description>
          { title &&
            <p>
              <b>{ title }</b>
            </p>
          }
          { body &&
            <p>
              { body }
            </p>
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={ cancelClose } positive >
          { cancelText }
        </Button>
        <Button
          onClick={ okClose }
          negative
          content={ okText }
        />
      </Modal.Actions>
    </Modal>
  );
};


export default GuiModal;
