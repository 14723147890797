/*
  Map.js
  (c) Human Cube Inc.
*/

import React, { Component } from 'react';

import FlagIcon from './FlagIcon';
import ZoneSprite from './ZoneSprite';
import mainModes from '../../reducers/mainModes';
import BF from '../../bfcore/bfconst1';
import { getHitmapFromCache } from '../../map/hitmapCache';


export default class Map extends Component {
  state = {
    zoneCanvas: null,

    mainMode: null,
    replayStage: null,
    replayPlay: null,

    game: {},
    scenarioEdit: {},

    x: 0,
    y: 0,
    zoom: 1.0,

    selectedZoneIDs: [],
    landingZoneID: 0,
  };

  _createZoneCanvasFromString (map, zone) {
    const MAP_RENDER_WIDTH = 1024;
    const MAP_RENDER_HEIGHT = 1024;

    var minx = MAP_RENDER_WIDTH;
    var miny = MAP_RENDER_HEIGHT;
    var maxx = 0;
    var maxy = 0;

    // const zoneIsWater = (map.zoneFlags[zone] & BF.ZONE_FLAG_WATER_ZONE);

    for(let y = 0; y < map.mapYS; y++) {
      for(let x = 0; x < map.mapXS; x++) {
        if(map.mapData.charCodeAt(y * map.mapXS + x) === zone) {
          minx = Math.min(minx, x);
          miny = Math.min(miny, y);
          maxx = Math.max(maxx, x);
          maxy = Math.max(maxy, y);
        }
      }
    }

    var xSize = maxx - minx + 1;
    var ySize = maxy - miny + 1;

    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = xSize;
    canvas.height = ySize;

    for(let y = 0; y < map.mapYS; y++) {
      for(let x = 0; x < map.mapXS; x++) {
        if(map.mapData.charCodeAt(y * map.mapXS + x) === zone) {
          if(x < 1 || y < 1 || x > map.mapXS - 2 || y > map.mapYS - 2 ||
              map.mapData.charCodeAt((y - 1) * map.mapXS + x) !== zone ||
              map.mapData.charCodeAt((y + 1) * map.mapXS + x) !== zone ||
              map.mapData.charCodeAt(y * map.mapXS + x - 1) !== zone ||
              map.mapData.charCodeAt(y * map.mapXS + x + 1) !== zone) {
            ctx.fillStyle = 'rgb(200,200,200)';
          }
          else {
            // if(zoneIsWater) {
            //   let c = x & 1 && (y + x * 2) & 7 ? 200 : 255;
            //   ctx.fillStyle = 'rgb(' + c + ',' + c + ',' + c + ')';
            // }
            // else {
              ctx.fillStyle = 'rgb(255,255,255)';
            // }
          }
          ctx.fillRect(x - minx, y - miny, 1, 1);
        }
      }
    }

    return {
      x: minx,
      y: miny,
      xSize,
      ySize,
      canvas,
    };
  }

  _createZoneCanvasFromHitmap (map, zone, bitmap) {

    let mapXS = map.mapXS;
    let mapYS = map.mapYS;
    if(map.mapFlags & BF.MAP_FLAG_HEXMAP) {
      const HEX_SIZE = 8;
      mapXS = map.mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2);
      mapYS = map.mapYS * HEX_SIZE;
    }

    let zoneKey = zone;
    if(map.mapFlags & BF.MAP_FLAG_BITMAP) {
      zoneKey = map.zoneKey[zone];
    }

    let minx = mapXS;
    let miny = mapYS;
    let maxx = 0;
    let maxy = 0;
    for(let y = 0; y < mapYS; y++) {
      for(let x = 0; x < mapXS; x++) {
        if(bitmap[y * mapXS + x] === zoneKey) {
          minx = Math.min(minx, x);
          miny = Math.min(miny, y);
          maxx = Math.max(maxx, x);
          maxy = Math.max(maxy, y);
        }
      }
    }

    const xSize = maxx - minx + 1;
    const ySize = maxy - miny + 1;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = xSize;
    canvas.height = ySize;

    // const zoneIsWater = (map.zoneFlags[zone] & BF.ZONE_FLAG_WATER_ZONE);

    for(let y = 0; y < mapYS; y++) {
      for(let x = 0; x < mapXS; x++) {
        if(bitmap[y * mapXS + x] === zoneKey) {
          if(x < 1 || y < 1 || x > mapXS - 2 || y > mapYS - 2 ||
              bitmap[(y - 1) * mapXS + x] !== zoneKey ||
              bitmap[(y + 1) * mapXS + x] !== zoneKey ||
              bitmap[y * mapXS + x - 1] !== zoneKey ||
              bitmap[y * mapXS + x + 1] !== zoneKey) {
            ctx.fillStyle = 'rgb(200,200,200)';
          }
          else {
            // if(zoneIsWater) {
            //   let c = x & 1 && (y + x * 2) & 7 ? 200 : 255;
            //   ctx.fillStyle = 'rgb(' + c + ',' + c + ',' + c + ')';
            // }
            // else {
              ctx.fillStyle = 'rgb(255,255,255)';
            // }
          }
          ctx.fillRect(x - minx, y - miny, 1, 1);
        }
      }
    }

    return {
      x: minx,
      y: miny,
      xSize,
      ySize,
      canvas,
    };
  }

  _createZoneCanvasesFromString (map) {
    const zoneCanvas = [];
    for (let i = 1; i <= map.zones; i++) {
      zoneCanvas[i] = this._createZoneCanvasFromString(map, i);
    }
    return zoneCanvas;
  }

  _createZoneCanvasesFromHitmap (map) {
    const zoneCanvas = [];
    const hitmap = getHitmapFromCache(map.mapID);
    if(hitmap) {
      for (let i = 1; i <= map.zones; i++) {
        zoneCanvas[i] = this._createZoneCanvasFromHitmap(map, i, hitmap);
      }
    }
    return zoneCanvas;
  }

  _createZoneCanvases (map) {
    if(map.mapFlags & (BF.MAP_FLAG_BITMAP | BF.MAP_FLAG_HEXMAP)) {
      return this._createZoneCanvasesFromHitmap(map);
    }
    return this._createZoneCanvasesFromString(map);
  }

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.mainMode = props.store.mainMode;
    this.state.replayStage = props.store.replayStage;
    this.state.replayPlay = props.store.replayPlay;

    this.state.game = props.store.game;
    this.state.scenarioEdit = props.store.scenarioEdit;

    this.state.x = props.store.camera.x;
    this.state.y = props.store.camera.y;
    this.state.zoom = props.store.camera.zoom;

    this.state.selectedZoneIDs = props.store.selectedZoneIDs;

    if(props.store.showLandingSpecifyWindow.show &&
        props.store.showLandingSpecifyWindow.destination) {
      this.state.landingZoneID = props.store.showLandingSpecifyWindow.destination;
    }
    else this.state.landingZoneID = 0;

    this.state.zoneCanvas = this._createZoneCanvases(this.state.game);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    let potentialLandingZoneID = 0;
    if(nextProps.store.showLandingSpecifyWindow.show &&
        nextProps.store.showLandingSpecifyWindow.destination) {
      potentialLandingZoneID = nextProps.store.showLandingSpecifyWindow.destination;
    }

    if(nextProps.store.camera.zoom !== this.state.zoom ||
        nextProps.store.camera.x !== this.state.x ||
        nextProps.store.camera.y !== this.state.y ||
        nextProps.store.selectedZoneIDs !== this.state.selectedZoneIDs ||
        nextProps.store.landingZoneID !== potentialLandingZoneID ||
        nextProps.store.game !== this.state.game ||
        nextProps.store.scenarioEdit !== this.state.scenarioEdit ||
        nextProps.store.mainMode !== this.state.mainMode ||
        nextProps.store.replayStage !== this.state.replayStage ||
        nextProps.store.replayPlay !== this.state.replayPlay) {

      let zoneCanvas = this.state.zoneCanvas;
      if(nextProps.store.game !== this.state.game) {
        zoneCanvas = this._createZoneCanvases(nextProps.store.game);
      }

      this.setState({
        zoom: nextProps.store.camera.zoom,
        x: nextProps.store.camera.x,
        y: nextProps.store.camera.y,
        selectedZoneIDs: nextProps.store.selectedZoneIDs,
        landingZoneID: potentialLandingZoneID,
        game: nextProps.store.game,
        scenarioEdit: nextProps.store.scenarioEdit,
        mainMode: nextProps.store.mainMode,
        replayStage: nextProps.store.replayStage,
        replayPlay: nextProps.store.replayPlay,
        zoneCanvas,
      });
    }
  }

  render() {
    let { x, y, zoom, mainMode, game, landingZoneID, replayStage, scenarioEdit, selectedZoneIDs,
        zoneCanvas } = this.state;
    zoom *= 2;
    const { mapXScale, mapYScale } = game;

    let flagZoom = zoom;
    let mapXS = game.mapXS;
    let mapYS = game.mapYS;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      flagZoom *= 0.5;
      mapXS = 512;
      mapYS = 512;
    }

    let xMult = mapXScale * mapXS;
    let yMult = mapYScale * mapYS;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      const HEX_SIZE = 8;
      xMult *= (game.mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2)) / mapXS;
      yMult *= (game.mapYS * HEX_SIZE) / mapYS;
    }

    x -= mapXS - (zoom * mapXScale * mapXS / 2);
    y -= mapXS - (zoom * mapYScale * mapYS / 2);

    let showPrev = false;
    if(mainMode === mainModes.GAME_REPLAY && replayStage < BF.REPLAY_STAGE_ZONE_CHANGE) {
      showPrev = true;
    }

    let zones = [];
    for (let i = 1; i <= game.zones; i++) {
      let tint = 0xFFFFFF;
      if(landingZoneID === i) {
        tint = 0x00FF00;
      }
      else if(selectedZoneIDs[1] === i) {
        tint = 0x00FF00;
      }
      else if(selectedZoneIDs[0] === i) {
        tint = 0xFF0000;
      }
      else if(game.zoneFlags[i] & BF.ZONE_FLAG_WATER_ZONE) {
        tint = 0x2299DF; // 0x0077BE;
      }
      else if(showPrev) {
        tint = game.playerColor[game.zoneOwnerPrev[i]];
      }
      else {
        tint = game.playerColor[game.zoneOwner[i]];
      }
      zones.push(
        <ZoneSprite
          key={ 'Zone' + i }
          canvas={ zoneCanvas[i].canvas }
          x={(zoneCanvas[i].x * mapXScale - x / zoom) * zoom}
          y={(zoneCanvas[i].y * mapYScale - y / zoom) * zoom}
          tint={ tint }
          xScale={ mapXScale * zoom }
          yScale={ mapYScale * zoom }
        />
      );
    }

    for (let i = 1; i <= game.players; i++) {
      if(game.capital[i]) {
        let tint = 0xFFFFFF;
        if(showPrev) {
          if(game.zoneOwnerPrev[game.capital[i]] !== i) {  // Capital is captured, dim flag.
            tint = 0x666666;
          }
        }
        else {
          if(game.zoneOwner[game.capital[i]] !== i) {  // Capital is captured, dim flag.
            tint = 0x666666;
          }
        }
        zones.push(
          <FlagIcon
            key={ 'CapitalFlag' + i }
            x={ (game.capitalX[i] * xMult / BF.WORLD_WIDTH - x / zoom) * zoom }
            y={ (game.capitalY[i] * yMult / BF.WORLD_HEIGHT - y / zoom) * zoom }
            scale={ flagZoom }
            cc={ mainMode !== mainModes.SCENARIO_EDIT ?
                 game.playerFlag[i] :
                 scenarioEdit.playerFlag[i] }
            tint={ tint }
          />
        );
      }
    }

    // for (let i = 1; i <= game.zones; i++) {
    //   zones.push(
    //     <ZoneGraphic
    //       key={'Zone' + i}
    //       zone={i}
    //       game={game}
    //       x={0}
    //       y={0}
    //       fill={0xFF0000 + i*2}
    //       scale={this.state.scale}
    //     />
    //   );
    // }

    // for (let i = 1; i <= game.zones; i++) {
    //   zones.push(
    //     <ZoneBorderGraphic
    //       key={'ZoneB' + i}
    //       zone={i}
    //       game={game}
    //       x={0}
    //       y={0}
    //       fill={0xFF0000 + i*2}
    //       scale={this.state.scale}
    //       alpha={0.33}
    //     />
    //   );
    // }

    // for (let xo = 0; xo < 50; xo++) {
    //   for (let yo = 0; yo < 50; yo++) {
    //     zones.push(
    //       <Hexagon
    //         key={ 'hx' + xo + ':' + yo }
    //         x={ ((20 + xo * 20 + (yo&1) * 10) - x) * zoom }
    //         y={ ((20 + yo * 18) - y) * zoom }
    //         height={ 19 * zoom }
    //         fill={ 0xaf0000 + xo * 4 + yo * 1024 }
    //         scale={ 1 }
    //       />
    //     );
    //   }
    // }

    return zones;
  }
}
