/*
  bf_playerfixtures.ts
  (c) 2024 Human Cube Inc.
  Player constants utilized during game creation.
*/

export const playerColour1 = [ // BGR format since it is from a BMP file. :-)
  0xffffff,0x536bbf,0x7c7c7c,0xb1f9f9,0x1bcfff,0x82b081,0xf0405b,0x43ffff,
  0xffbfff,0xffffac,0x3fd188,0x3c14dc,0x943fac,0x3f3fff,0xe93fac,0x94d188,
  0xe9ac88,0xbfbf7f,0xfff563,0xe59ded,0x2f92ff,0x5defd9,0xaceeff,0xe9f5ac,
  0x94ff63,0x3fbfbf,0x3fffff,0xf0ffff,0xfff4e4,0xffc0ff,0x666666,0xff3399
];

export function playerColor (p: number): number {
  // Return the RGB color integer for the given playerIndex. 0 if out of range.
  if(p < 0 || p >= playerColour1.length ) {
    return 0;
  }
  return ((playerColour1[p] & 0xFF0000) >> 16) |
         (playerColour1[p] & 0xFF00) |
         ((playerColour1[p] & 0xFF) << 16);
}

export const playerCountryCode = [ '',
  'us', 'sa', 'gb', 'cn', 'in', 'kr', 'br',
  'de', 'fr', 'za', 'pk', 'es', 'fi', 'gr', 'hu',
  'jm', 'it', 'jp', 'ph', 'nz', 'qa', 'ru', 'se',
  'tw', 'cr', 'pl', 'tr', 'ug', 'ye', 'no', 'ca',
];

// Censor out any uNames that fully match the following BF computer player names:
export const botNames = [ '',  // '' for 0 player, and 30 potentials BOT names, one for each slot.
  'AGENTSMITH',    'CYBERMAN',     'BORG',        'DANNYDIODE',    'BALTAR',       'ENIGMA',
  'NATAKA',        'HAL',          'ROBOCOP',     'R2D2',          'CYLON',        'DALEK',
  'MAXFAX',        'TURING',       'CRYPTO',      'ED209',         'SECTOR7',      'SHA256',
  'MRROBOTO',      'OMNICONTROL',  'KILROY',      'CYBERKGB',      'INTEL',        'CPU',
  'ENCRYPTOR',     'DEEPBLUE',     'DAVROS',      '2600COMPUTER',  'VIC20',        'KELLOR',
];
