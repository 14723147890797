/*
  GuiLoadTransportsWindow.tsx
  (c) Human Cube Inc.
*/

import { useEffect, useState } from 'react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import GuiForceTransport from './GuiForceTransport';
import { Game } from '../../bfcore/gameTypes';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiTile from './GuiTile';
import GuiButton from './GuiButton';


function GuiForceLoadLine (
  { game, zoneID, zoneID2, localPlayerIndex, forceTypeIndex, count, selectedTransportCargoKey }:
  { game: Game, zoneID: number, zoneID2: number, localPlayerIndex: number,
    forceTypeIndex: number, count: number, selectedTransportCargoKey: string }
) {

  function loadOne () {
    store.dispatch({
      type: actions.LOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      transportZoneID: zoneID2,
      forceTypeIndex: forceTypeIndex,
      count: 1,
      transportCargoKey: selectedTransportCargoKey,
    });
  };

  function loadAll () {
    store.dispatch({
      type: actions.LOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      transportZoneID: zoneID2,
      forceTypeIndex: forceTypeIndex,
      count: count,
      transportCargoKey: selectedTransportCargoKey,
    });
  };

  return (
    <div className='flex flex-row' style={{ padding: 1 }}>
      <div className='mt-2 mx-1'>
        <b>{ count }</b>&nbsp;
      </div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;
      <div style={{ marginLeft: 6 }}>
        <GuiButton key='loadOne' onClick={ loadOne }> Load 1 </GuiButton>
      </div>
      <div style={{ marginLeft: 6 }}>
        <GuiButton key='loadAll' onClick={ loadAll }> Load All </GuiButton>
      </div>
    </div>
  );
}


export default function GuiForceLoadWindow (
  { game, details, zoneID, zoneID2, localPlayerIndex }:
  { game: Game, details: ForceDetailsForZone, zoneID: number, zoneID2: number,
    localPlayerIndex: number }
) {

  function calculateSelectedTransportCargoKey (): string {
    let suggestedTransportCargoKey = '';
    let suggestedTransportScore = 0;
    for (var key in details.transportsByCargo2) {
      // key is:  transportForceTypeIndex:range:loadPoints:cargotypeindex1:cargotypeindex2 ...
      const parts = key.split(':');
      const range = parseInt(parts[1]);
      const loadPoints = parseInt(parts[2]);
      if(loadPoints > 0) {
        const count = details.transportsByCargo2[key];
        if(count > 0) {
          const score = range * 1000000 + loadPoints;
          if(score >= suggestedTransportScore) {
            suggestedTransportCargoKey = key;
            suggestedTransportScore = score;
          }
        }
      }
    }

    return suggestedTransportCargoKey;
  }

  const [selectedTransportCargoKey, setSelectedTransportCargoKey] =
    useState(calculateSelectedTransportCargoKey());

  useEffect(() => {
    if(!details.transportsByCargo2[selectedTransportCargoKey]) {
      for (var key in details.transportsByCargo2) {
        const parts = key.split(':');
        const loadPoints = parseInt(parts[2]);
        if(loadPoints > 0) {
          const count = details.transportsByCargo2[key];
          if(count > 0) {
            setSelectedTransportCargoKey(key);
            break;
          }
        }
      }
    }
  }, [details.transportsByCargo2, selectedTransportCargoKey]);

  function hideLoadTransportsWindow () {
    store.dispatch({
      type: actions.HIDE_LOAD_TRANSPORTS_WINDOW,
    });
  }

  if(details.zone2LoadPoints <= 0) {
    return (
      <GuiTile edge='left'>
        <p><b>No remaining load points.</b></p>
        <p>These transports are unable to load any more cargo this turn.</p>
        <GuiButton onClick={ hideLoadTransportsWindow } center={ true }>
          Close
        </GuiButton>
      </GuiTile>
    );
  }

  let loadLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    const r = game.forceRange[f];  // Only those with maximum range.
    const count = details.forcesByRange[localPlayerIndex][r][f];
    if(count <= 0) {
      continue;
    }
    if(game.forceCargoSpace[f] >= 0) {
      loadLine.push(
        <GuiForceLoadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
          zoneID2={ zoneID2 } localPlayerIndex={ localPlayerIndex } count={ count }
          selectedTransportCargoKey={ selectedTransportCargoKey }
        />
      );
    }
  }

  if(loadLine.length < 1) {
    loadLine.push(
      <div>
        <b>No available loadable forces.</b><br />
        Forces must have full available<br />
        range to be able to be loaded.<br />
      </div>
    );
  }

  const transportChoices = [];

  for (let key in details.transportsByCargo2) {
    const count = details.transportsByCargo2[key];
    // key is:  transportForceTypeIndex:range:loadPoints:cargotypeindex1:cargotypeindex2 ...
    const parts = key.split(':');
    const loadPoints = parseInt(parts[2]);
    if(loadPoints > 0) {
      const kkey = key;
      transportChoices.push(
        <div
          key={'tc' + key}
          className={ key === selectedTransportCargoKey ? 'border-2 border-green-400 p-2 bg-slate-200' : 'border-2 p-2' }
          style={{ width: 96, margin: 1 }}
          onClick={
            (_e: any) => { setSelectedTransportCargoKey(kkey); }
          }
        >
          <GuiForceTransport transportCargoKey={ key } game={ game } count={ count } />
        </div>
      );
    }
  }

  return (
    <GuiTile edge='left' maxWidth={ 234 }>
      Loadable Forces:<br />
      { loadLine }
      <br />
      Destination Transport Force:
      <div className='flex flex-row flex-wrap'>
        { transportChoices }
      </div>
      <p>Total Load Points Remaining: { details.zone2LoadPoints }</p>
      <GuiButton onClick={ hideLoadTransportsWindow } center={ true }>
        Close
      </GuiButton>
    </GuiTile>
  );
}
