import React, { Component } from "react";
import { Text } from '@pixi/react';
import Arrow from './Arrow';
// import FlagIcon from './FlagIcon';
import LandingStrip from './LandingStrip';
import mainModes from '../../reducers/mainModes'
import BF from '../../bfcore/bfconst1'


class MapIncome extends Component {
  state = {
    mainMode: null,
    replayStage: null,
    replayPlay: null,

    game: {},
    working: {},

    x: 0,
    y: 0,
    zoom: 1.0,

    selectedZoneIDs: [],
  };

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.mainMode = props.store.mainMode;
    this.state.replayStage = props.store.replayStage;
    this.state.replayPlay = props.store.replayPlay;

    this.state.game = props.store.game;
    this.state.working = props.store.working;

    this.state.x = props.store.camera.x;
    this.state.y = props.store.camera.y;
    this.state.zoom = props.store.camera.zoom;

    this.state.selectedZoneIDs = props.store.selectedZoneIDs;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store.camera.zoom !== this.state.zoom ||
        nextProps.store.camera.x !== this.state.x ||
        nextProps.store.camera.y !== this.state.y ||
        nextProps.store.selectedZoneIDs !== this.state.selectedZoneIDs ||
        nextProps.store.game !== this.state.game ||
        nextProps.store.working !== this.state.working ||
        nextProps.store.mainMode !== this.state.mainMode ||
        nextProps.store.replayStage !== this.state.replayStage ||
        nextProps.store.replayPlay !== this.state.replayPlay) {
      this.setState({
        zoom: nextProps.store.camera.zoom,
        x: nextProps.store.camera.x,
        y: nextProps.store.camera.y,
        selectedZoneIDs: nextProps.store.selectedZoneIDs,
        game: nextProps.store.game,
        working: nextProps.store.working,
        mainMode: nextProps.store.mainMode,
        replayStage: nextProps.store.replayStage,
        replayPlay: nextProps.store.replayPlay,
      });
    }
  }

  render() {
    const MAX_INCOME_ZOOM = 2;

    let { x, y, game, mainMode, selectedZoneIDs, zoom, working } = this.state;
    const { mapXScale, mapYScale } = game;
    zoom *= 2;
    let textScale = Math.min(zoom / 3, MAX_INCOME_ZOOM);
    let arrowScale = zoom * 0.075;

    let mapXS = game.mapXS;
    let mapYS = game.mapYS;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      mapXS = 512;
      mapYS = 512;
      textScale *= 0.5;
      arrowScale *= 0.5;
    }

    let xMult = mapXScale;
    let yMult = mapYScale;
    if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
      const HEX_SIZE = 8;
      xMult *= (game.mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2)) / mapXS;
      yMult *= (game.mapYS * HEX_SIZE) / mapYS;
    }

    x -= mapXS - (zoom * mapXScale * mapXS / 2);
    y -= mapYS - (zoom * mapYScale * mapYS / 2);

    let zones = [];
    for (let i = 1; i <= game.zones; i++) {
      if(mainMode !== mainModes.GAME_REPLAY && (working.landingUsed[i] || working.carrierUsed[i])) {
        if(working.landingUsed[i] > working.landingCapacity[i] ||
            working.carrierUsed[i] > working.carrierCapacity[i]) {
          zones.push(
            <LandingStrip
              key={ 'Lno' + i }
              x={ (game.zoneBoxX[i] * xMult - x / zoom) * zoom - 4 * zoom }
              y={ (game.zoneBoxY[i] * yMult - y / zoom) * zoom - 4 * zoom }
              scale={ textScale }
              tint={ 0xFF0000 }
            />
          );
        }
        else {
          zones.push(
            <LandingStrip
              key={ 'Lyes' + i }
              x={ (game.zoneBoxX[i] * xMult - x / zoom) * zoom - 4 * zoom }
              y={ (game.zoneBoxY[i] * yMult - y / zoom) * zoom - 4 * zoom }
              scale={ textScale }
            />
          );
        }
      }

      let income = game.zoneIncome[i];
      if(income || (game.zoneFlags[i] & BF.ZONE_FLAG_LAND_ZONE)) {
        zones.push(
          <Text
            key={ 'I' + i }
            x={ (game.zoneBoxX[i] * xMult - x / zoom) * zoom - 4 * zoom }
            y={ (game.zoneBoxY[i] * yMult - y / zoom) * zoom - 4 * zoom }
            text={ income + '' }
            scale={ textScale }
            style={{
               fill: 0xFFFFFF, fontWeight: 900,
               dropShadow: true, dropShadowAlpha: 0.75, dropShadowBlur: 6, dropShadowDistance: 2
            }}
          />
        );
      }
    }

    if(selectedZoneIDs.length === 2) {
      const zoneID1 = selectedZoneIDs[0];
      const zoneID2 = selectedZoneIDs[1];
      const dx = game.zoneBoxX[zoneID2] - game.zoneBoxX[zoneID1];
      const dy = game.zoneBoxY[zoneID2] - game.zoneBoxY[zoneID1];
      const dir = Math.atan2(dy, dx) + Math.PI / 2;
      const ax = dx / 2 + game.zoneBoxX[zoneID1];
      const ay = dy / 2 + game.zoneBoxY[zoneID1];
      zones.push(
        <Arrow
          key={ 'moveArrow' }
          x={ (ax * xMult - x / zoom) * zoom }
          y={ (ay * yMult - y / zoom) * zoom }
          scale={ arrowScale }
          rotation={ dir }
          alpha={ 0.3 }
        />
      );
    }

    return zones;
  }
}

export default MapIncome;
