/*
  Arrow.tsx
  (c) Human Cube Inc.
*/

import { Sprite } from '@pixi/react';
import * as PIXI from 'pixi.js';

import arrow from './img/arrow.png';

const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function Arrow (props: any) {
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(arrow) }
      { ...props }
    />
  );
}
