/*
  LandingStrip.tsx
  (c) Human Cube Inc.
*/

import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import texture from './img/landingstrip.png';


const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function LandingStrip (props: any) {
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(texture) }
      { ...props }
    />
  );
}
