/*
  GuiPurchasesSummary.tsx
  (c) Human Cube Inc.
*/

import { Button, Segment } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import mainModes from '../../reducers/mainModes';
import GuiForce from './GuiForce';


export default function GuiPurchasesSummary (
  {store, closeCallback}: {store: any, closeCallback: any}
) {

  const close = (e: any) => {
    e.stopPropagation();
    if(closeCallback) {
      closeCallback();
    }
  };

  const selectZone = (e: any, zoneID: number) => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    store.dispatch({type: actions.SHOW_FORCE_PURCHASE_WINDOW});
  }

  const { gameID, game, mainMode, working } = store;
  if(game._id !== gameID) {
    return null;
  }

  const rows = [];
  if(mainMode === mainModes.GAME_DOING_TURN) {
    rows.push(<p key={ 'buyline0' }>Cash: <b>{ working.cash }</b></p>);
    for(let z = 1; z <= game.zones; z++) {
      if(working.buy[z]) {
        const purchasesHere = [];
        for(const forceTypeIndex in working.buy[z]) {
          if(working.buy[z][forceTypeIndex]) {
            purchasesHere.push(
              <div key={ 'buy' + forceTypeIndex } className='flex flex-col'>
                <GuiForce forceType={ game.forceID[forceTypeIndex] } />
                {working.buy[z][forceTypeIndex]}
              </div>
            );
          }
        }
        if(purchasesHere.length > 0) {
          rows.push(
            <p key={ 'buyline' + z }>
              <Button onPointerDown={ (e: any) => selectZone(e, z) }>
                { game.zoneName[z] + ':' }&nbsp;&nbsp;&nbsp;
                <div className='flex flex-row'>{purchasesHere}</div>
              </Button>
            </p>
          );
        }
      }
    }
    if(rows.length < 2) {
      rows.push(
        <p key={ 'buyline-2' }>
          Tap a zone you own with a factory to purchase additional forces.
        </p>
      );
    }
  }
  else {
    rows.push(
      <p key={ 'buyline-1' }>
        While doing your turn, this panel will list all purchases you have made.
      </p>
    );
  }

  return (
    <Segment compact onPointerDown={ close }
        style={{ position: 'absolute', top: '30px', left: '250px', opacity: '90%' }}>
      <h4>Forces Purchased this Turn</h4>
      { rows }
    </Segment>
  );
}
