/*
  ZoneSprite.tsx
  (c) Human Cube Inc.
*/

import { Sprite } from '@pixi/react';
import * as PIXI from 'pixi.js';


const centerAnchor = new PIXI.Point(0, 0);

export default function ZoneSprite ({x, y, canvas, tint, xScale, yScale}:
  {x: number, y: number, canvas: any, tint: number, xScale: number, yScale: number}
) {
  return (
    <Sprite
      x={ x }
      y={ y }
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(canvas) }
      tint={ tint }
      scale={ new PIXI.Point(xScale, yScale) }
    />
  );
}
