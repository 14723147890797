/*
  GuiTile.tsx
  (c) Human Cube Inc.

  Standard container tile for the game GUI.
*/

export default function GuiTile (
  { children, maxWidth = 256, edge }:
  { children: any, maxWidth?: number, edge?: 'none' | 'left' | 'right' }
) {

  let rounded = 'rounded-md';
  if(edge === 'left') {
    rounded = 'rounded-r-md';
  }
  else if(edge === 'right') {
    rounded = 'rounded-l-md';
  }

  return (
    <div
      className={'px-4 py-3 border border-slate-500 ' + rounded }
      style={{
        maxWidth,
        backgroundColor: 'rgba(203,213,225,0.90)', // bg-slate-300 + alpha.
        userSelect: 'none',
      }}
      onPointerDown={ (e: any) => { e.stopPropagation(); } }
    >
      { children }
    </div>
  );
}
