/*
  BaseLocalSoloStart.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Message, Table } from 'semantic-ui-react';

import { localSoloGameCreate } from '../../helper/localSoloGame';
import BaseAdSense from './BaseAdSense';
import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BF from '../../bfcore/bfconst1';


const playersOptions = [
  { key: 2, text: '2 Players', value: 2 },
  { key: 3, text: '3 Players', value: 3 },
  { key: 4, text: '4 Players', value: 4 },
  { key: 5, text: '5 Players', value: 5 },
  { key: 6, text: '6 Players', value: 6 },
  { key: 7, text: '7 Players', value: 7 },
  { key: 8, text: '8 Players', value: 8 },
  { key: 9, text: '9 Players', value: 9 },
  { key: 10, text: '10 Players', value: 10 },
  { key: 11, text: '11 Players', value: 11 },
  { key: 12, text: '12 Players', value: 12 },
  { key: 13, text: '13 Players', value: 13 },
  { key: 14, text: '14 Players', value: 14 },
  { key: 15, text: '15 Players', value: 15 },
  { key: 16, text: '16 Players', value: 16 },
  { key: 16, text: '18 Players', value: 18 },
  { key: 20, text: '20 Players', value: 20 },
  { key: 24, text: '24 Players', value: 24 },
  { key: 30, text: '30 Players', value: 30 },
];

const pickingOrderOptions = [
  { key: BF.PICKING_ORDER_NO_PICKING, text: 'No Zone Picking', value: BF.PICKING_ORDER_NO_PICKING },
  { key: BF.PICKING_ORDER_DISTRIBUTED, text: 'Auto Distributed Zone Picking', value: BF.PICKING_ORDER_DISTRIBUTED },
  { key: BF.PICKING_ORDER_123321321, text: 'Manual 123321321 Zone Picking', value: BF.PICKING_ORDER_123321321 },
  { key: BF.PICKING_ORDER_123123123, text: 'Manual 123123123 Zone Picking', value: BF.PICKING_ORDER_123123123 },
  { key: BF.PICKING_ORDER_AUTO_123321321, text: 'Auto 123321321 Zone Picking', value: BF.PICKING_ORDER_AUTO_123321321 },
  { key: BF.PICKING_ORDER_AUTO_123123123, text: 'Auto 123123123 Zone Picking', value: BF.PICKING_ORDER_AUTO_123123123 },
  { key: BF.PICKING_ORDER_SCATTERED, text: 'Auto Scattered Zone Picking', value: BF.PICKING_ORDER_SCATTERED },
];


function BaseLocalSoloStart ({ store }: { store: any }) {
  const navigate = useNavigate();

  const [gameFlags, setGameFlags] =
    useState(BF.GAME_FLAG_FACTORY_AT_CAPITAL | BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL);
  const [players, setPlayers] = useState(5);
  const [pickingOrder, setPickingOrder] = useState(BF.PICKING_ORDER_DISTRIBUTED);

  const { uid, uName } = store.userSession;

  const createNewSoloGame = (e: any) => {
    const localPlayerIndex = 1;
    const srand = 123;
    const forceTypes = [1, 2, 3, 4, 5, 11, 6, 7, 8, 9, 10];
    const addToCapital: number[] = [0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 1];

    const gameID = localSoloGameCreate(localPlayerIndex, {
      uid, uName, localPlayerIndex, gameFlags, players, srand, pickingOrder,
      forceTypes, addToCapital,
    });
    if(gameID > 0) {
      navigate(-1);  // navigate('/game/' + gameID);
    }
    else {
      // TODO: display create game error message.
    }
  };

  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='Play Local Solo Game' />
      <center>
        <br /><br /><br />
        <BaseAdSense />
        <br />
        <Message compact style={{ opacity: 0.9 }}>
          <p>
            Local Solo Games use random maps and are sort of like BATTLE FORCES solitaire.<br />
            A great way to try out some game variations and strategies.<br />
            They run completely locally and are only visible to you.<br />
            They are automatically saved to the local browser and can be resumed<br />
            at a later date only on this machine and with this browser.<br />
            How long they will be available is dependent on your browser data settings.<br />
          </p>
        </Message>
        <Table collapsing striped selectable style={{ opacity: 0.9 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Options
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Dropdown
                  onChange={ (e, { value }) => { setPlayers(parseInt(value as string));} }
                  options={ playersOptions }
                  placeholder='Choose an option'
                  selection
                  value={ players }
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Dropdown
                  onChange={ (e, { value }) => { setPickingOrder(parseInt(value as string));} }
                  options={ pickingOrderOptions }
                  placeholder='Choose an option'
                  selection
                  value={ pickingOrder }
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Button onClick={ createNewSoloGame } positive >
                  Play this Local Solo Game!
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}


export default BaseLocalSoloStart;