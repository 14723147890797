/*
  GuiVictory.tsx
  (c) Human Cube Inc.
*/

import { useState } from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';

import BF from '../../bfcore/bfconst1';
import { Game } from '../../bfcore/gameTypes';


const GuiVictory = ({game} : {game: Game}) => {

  const [open, setOpen] = useState(true);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  const handleOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  }

  const handleClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  }

  if(!game._id || !game.replay || !game.replay.victory ) {
    // Wait for all game data to load before attempting to render any victory stuff.
    return null;
  }

  if( !open ) {
    return (
      <div key='VB' onPointerDown={stopPropagation} style={{float: 'right'}} >
        <Button color='black' style={{ opacity: 0.86 }} onPointerUp={ handleOpen }>
          Show Victory
        </Button>
        <br />
      </div>
    );
  }

  const { reason = 0, winner = [], winningTeam = 0 } = game.replay.victory;

  const reasonRender = [];
  if(reason & BF.VICTORY_GAME_CANCELLED ) {
    reasonRender.push(<p key='r1'>Game was canceled.</p>);
  }
  if(reason & BF.VICTORY_TURN_LIMIT ) {
    reasonRender.push(<p key='r2'>Maximum game turns was reached.</p>);
  }
  if(reason & BF.VICTORY_TIME_LIMIT ) {
    reasonRender.push(<p key='r4'>Maximum game time was reached.</p>);
  }
  if(reason & BF.VICTORY_AUTOPLAY_LIMIT ) {
    reasonRender.push(<p key='r8'>All human players autoplayed consecutively too many times.</p>);
  }
  if(reason & BF.VICTORY_NO_HUMANS_LEFT ) {
    reasonRender.push(<p key='r16'>All human players have been eliminated.</p>);
  }
  if(reason & BF.VICTORY_CAPITALS ) {
    reasonRender.push(<p key='r32'>Early victory condition of captured capitals was met.</p>);
  }
  if(reason & BF.VICTORY_ZONES ) {
    reasonRender.push(<p key='r64'>Early victory condition of zones controlled was met.</p>);
  }
  if(reason & BF.VICTORY_INCOME ) {
    reasonRender.push(<p key='r128'>Early victory condition of income was met.</p>);
  }
  if(reason & BF.VICTORY_CASH ) {
    reasonRender.push(<p key='r256'>Early victory condition of cash was met.</p>);
  }
  if(reason & BF.VICTORY_ELIMINATION ) {
    reasonRender.push(<p key='r512'>All opponents have been eliminated.</p>);
  }

  let winnersRender = 'There were no absolute victors.';
  let title = 'Game Over';
  if(winningTeam) {
    title = 'Team Victory!!!';
    winnersRender = 'Victorious Team: ' + game.teamName[winningTeam] + ' (';
    for(let i = 0; i < winner.length; i++) {
      winnersRender += ' ' + game.playerName[winner[i]];
    }
    winnersRender += ' )';
  }
  else if(winner.length > 0) {
    title = 'Victory!!!';
    if(winner.length === 1) {
      winnersRender = 'Winner:';
    }
    else {
      winnersRender = 'Winners:';
    }
    for(let i = 0; i < winner.length; i++) {
      winnersRender += ' ' + game.playerName[winner[i]];
    }
  }

  return (
    <Segment inverted compact
      style={{
        opacity: 0.86,
        maxWidth: 256,
      }}
      onPointerDown={ stopPropagation }
      onPointerUp={ handleClose }
    >
      <Header>{ title }</Header>
      { reasonRender }
      <p key='wr'>{ winnersRender }</p>
    </Segment>
  );
};


export default GuiVictory;
