/*
  GuiCircleNumber.tsx
  (c) 2024 Human Cube Inc.
*/

export default function GuiCircleNumber ({ value }: { value: number }) {
  return (
    <div style={{
      display: 'flex',
      width: '3ch',  // Set this to slightly wider than the longest string.
      height: '3ch',
      alignItems: 'center',  
      justifyContent: 'center',
      aspectRatio: '1 / 1',  // does this even do anything?
      borderRadius: '50%',  
      border: '2px solid #666',
      // marginTop: -3,  // used to align with other items.
      fontWeight: 800,
      color: 'black',
      backgroundColor: 'white',
    }}>
      { value }
    </div>
  );
}
