/*
  mapGenerate.ts
  (c) 2016-present Human Cube Inc.
*/

import BF from '../bfcore/bfconst1';
import { assignForceTypesInfo } from '../bfcore/bf_forceTypeFixtures';
import { Game } from '../bfcore/gameTypes';
import { botNames, playerColor, playerCountryCode } from '../bfcore/bf_playerfixtures';
import { createInitialForces } from '../bfcore/bf_turn';

const xor4096 = require('../lib/xor4096');


function randInt (rng: any, min: number, max: number): number {
  return Math.floor(rng.quick() * (max - min + 1)) + min;
  // return Math.floor(Math.random() * (max - min + 1)) + min;
}

function addBorder (game: Game, zone: number, border: number) {
  // Adds border as appropriate land or water neighbour if applicable.
  // Returns true if a different zone was bordering, else false.
  // Supports the concept of land, water and both a land-water.
  let ret = false;
  if(border && border !== zone) {
    if(game.zoneFlags[border] & BF.ZONE_FLAG_LAND_ZONE) {
      if(game.bordersLand[zone].indexOf(border) === -1) {
  	    game.bordersLand[zone].push(border);
    	}
      ret = true;
    }
    if(game.zoneFlags[border] & BF.ZONE_FLAG_WATER_ZONE) {
      if(game.bordersWater[zone].indexOf(border) === -1) {
  	    game.bordersWater[zone].push(border);
    	}
      ret = true;
    }
	}
  return ret;
}

export interface SoloGameCreateParams {
  uid: number;
  uName: string;
  gameFlags: number;
  zones?: number;
  waterPercentage?: number;
  boardYSize?: number;
  boardXSize?: number;
  zoneBoxYSize?: number;
  zoneBoxXSize?: number;
  mapXScale?: number;
  mapYScale?: number;
  players?: number;
  humanPlayers?: number;
  srand?: number;

  pickingOrder?: number;
  forceTypes?: number[];
  addToCapital?: number[];
  year?: number;

  localPlayerIndex?: number;  // Play as this player index.
};

export function generateSoloGame (params: SoloGameCreateParams): Game {
	// Returns new a solo game object with a randomly generated map.
  const {
    uid,
    uName,
    gameFlags = BF.GAME_FLAG_FACTORY_AT_CAPITAL | BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL,
    zones = 100,
    waterPercentage = 30,
    boardYSize = 512,
    boardXSize = 512,
    zoneBoxYSize = 8,
    zoneBoxXSize = 8,
    mapXScale = 2,
    mapYScale = 2,
    srand = 123,
    players = 4,
    humanPlayers = 1,
    pickingOrder = BF.PICKING_ORDER_DISTRIBUTED,
    forceTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //  [1, 2, 3, 4, 5, 11, 12, 6, 7, 8, 9, 10],  // [1, 2, 10],
    addToCapital = [], // [0, 0, 0, 0, 0,  3,  2, 0, 0, 0, 0,  1],
    year = 2000,
    localPlayerIndex = 1,
  } = params;

  const flags = gameFlags |
    BF.GAME_FLAG_SOLO_GAME |
    BF.GAME_FLAG_DO_NOT_SCORE |
    BF.GAME_FLAG_CLIENT_CALCULATED;

  const zonesWater = Math.floor(zones * waterPercentage / 100);
  const zonesLand = zones - zonesWater;

  const nowMS = Date.now();

  // Important to not use the other random generator, as it will always be the same for the same srand.
  // Important to be unique to this game as the map is randomly generated:
  const mapID = Math.floor(Math.random() * 1000000) + 1000000;  // TODO: should reserver a range for this that does not collide with builtin maps and custom maps.

  const game: Game = {
    // Local solo games use gameIDs in the range of [1000000..1999999]:
    _id: Math.floor(Math.random() * 1000000) + 1000000,  // Important to not use the other random generator, as it will always be the same for the same srand. Give it a low value so it does not collide with real gameIDs. TODO: could maybe use a negative number so something else.
    state: BF.GAME_STATE_STARTING,
  	name: 'Solo Game',  // Game name, up to 47 characters.
    uid,        // Creator uid.
    uName,      // Creator name.
  	scenarioID: 0,  // Source scenario id.
    scenarioName: 'Local Random ' + srand,
    scenarioUID: uid,
    scenarioUName: uName,

    factories: 0,  // Quick reference: Total number of initial production forces (factories). (filled below).

  	mapID,  // Source map id (provided by scenario but stored here for convenience).
    mapName: 'Local Random ' + srand,
    mapUID: uid,
    mapUName: uName,
    mapFlags: BF.MAP_FLAG_LOCKED | BF.MAP_FLAG_CAN_SHOW_AS_BOARD | BF.MAP_FLAG_RAWMAP,
		zones,
    zonesLand,
    zonesWater,

    mapXS: boardXSize,
    mapYS: boardYSize,
    mapData: '',
    zoneKey: [0],
    zoneFlags: [0],

    zoneName: [''],
    bordersLand: [[]],
		bordersWater: [[]],
    zoneIncome: [0],
    zoneBonus: [],

    players,  // Total player count (2 to 30).
    humanPlayers,
    computerPlayers: players - humanPlayers,

    flags,  // GAME_FLAG_* bits.
    pickingOrder,  // PICKING_ORDER_* constants.

    maxDuration: 0,  // Max duration of game in seconds.  0 for unlimited.
    maxTurns: 0,     // Max number of turns, 0 for unlimited.

  	regScore: [0, 1000000],  // Minimum and Maximum score to register.
  	regMembership: [0, 10],  // Minimum and maximum membership to register.

  	playTimes: [],  // Empty array for any time, or 7 integers with bits set for each hour of
  					        // enabled play.

  	// TODO: timePrestart: 0,  // TODO:  Maybe have a setting for the amount of time to delay before
    							             //        starting after the registration has filled up.
    timeZonePicking: 60*60*24 * 30,     // Seconds for each zone picking turn, 0 for no limit.
    timeCapitalPicking: 60*60*24 * 30,  // Seconds for capital picking turn, 0 for no limit.
    timeTurn: 60*60*24 * 30,            // Seconds for each spring turn, 0 for no limit.
  	// timeEnd: 0,          // Maybe need some time to show the end, so the result surprise is
    						            // not given away too early.

    victoryCapitals: 0,  // 0 for not a victory condition, else number of capitals to own to win.
    victoryLandZones: 0, // 0 for not a victory condition, else number of land zones to own to win.
    victoryIncome: 0,    // 0 for not a victory condition, else income to have to win.
    victoryCash: 0,      // 0 for not a victory condition, else total cash to have to win.

  	sourceGameID: 1342,       // Game id of first parent, if it is an auto-respawn.

  	privatePIN: 0,   // PIN number needed to register or 0 if not a private game.

  	//====== Game State Data Starts ======:

  	turn: 0,	// Turn Index.
              // (000000000) = pre-pre-registration (not-displayed)
              // (000000001+) = pre-registration (displayed)
    				  // (100000000+) = registering
    				  // (200000000+) = pick land zone
    				  // (300000000)  = pick capital
    				  // (400000000+) = normal turns

  	timeLimit: '',  // Date time of time limit for current turn index, '' for none.

    required: 0,   // Turn required bits.
    noWait: 0,     // No wait for player turn bits.

    submitted: 0,  // Player turns already submitted bits.

    autoPlay: 0,  // Bit set for each player whether their auto-play is on.
                  // Option controlled by player but will be automatically set if they
                  // miss game.missedAllowed (2) turns.

    autoSpend: [0],  // AutoPlay percentage of cash to spend 0..100.  Default 100.
    autoMove: [0],   // AutoPlay percentage of movement 0..100.  Default 100.

    teammatesSubmit: 0,  // Bit set for each player whether they allow their teammates
                         // to submit their turn for them.  Option controlled by player
                         // but will be automatically set if they miss a turn in team play.

    //====== All player data also includes a 0 player. ===========
    playerID: [0],
  	playerName: [''],
    playerFlags: [0], // Player flags for each player.
                      // PLAYER_FLAG_* constant bits.
                      // Includes 0 player.
    playerFlag: [''],	// Player flag display, two digit code.
    playerColor: [playerColor(0)],   // Player colors as integers.
    playerScore: [0],  // Starting score for the player (used for elo calculations).
    slotName: [''],
    slotFlag: [''],
    showBuy: [0],  // Bits set for each force type for each player for which they can see in buy window.
    canBuy: [0],  // Bits set for each force type for each player for which they can buy.

  	finished: [[]], // [rank,turnIndex of finish] (0 for not yet eliminated or finished).
                    // Extends to [rank,turnIndex,scoreDelta,xpDelta] once the game ends.
                    // Note, some older games never have the last last two fields.

    playerAutoTotal: [0],  // Total number of turns auto-played for the player.
                           // New field, so may be missing from some games.
    playerAutoCurrent: [0],  // Number of turns auto-played in current streak.
                             // New field, so may be missing from some games.

    playerMissedTotal: [0],  // Total number of turns missed for the player (not auto-played).
                             // New field, so may be missing from some games.
    playerMissedCurrent: [0],  // Number of turns missed in current streak (not auto-played).
                               // New field, so may be missing from some games.

    capital: [0],   // Capital zone id for each player, 0 for unchosen.
    capitalX: [0],  // Capital zone flag X position for each player.
    capitalY: [0],  // Capital zone flag Y position for each player.
    cash: [0],      // Current cash for each player.

    team: [0],  // Team player is in.  0 for no team.  1 for team A, 2 for team B, etc.
    fixedAlly: [0],  // Allies fixed for the entire game, for example team game team members.
                           // Bit mask for each player.
    declaredAlly: [0],  // Allies that are optionally declared, and can be later undeclared as well.
                              // Bit mask for each player.  (not fully implemented)
    // TODO:  nap: [0],   // Player declared NAP, or something.  Work on this.
    force: [[]],
        // An array of a single array for each player (including 0 player) for their forces.
        // BF.FORCE_INDEX_* constants define the indexes below.
        // In that array is then an integer array for each force in the format:
        // [force id,       // [0]
        //  type index,     // [1]
        //  zoneID,         // [2]
        //  zoneIDPrev,     // [3]
        //  retreatZoneID,  // [4]
        //  movementPoints, // [5]
        //  loadPoints,     // [6]
        //  x, y,           // [7] [8]
        //  xPrev, yPrev]   // [9] [10]

    // Force Type Info (all here, we could do dynamic value changes during game as a future feature):
    forceID: forceTypes,
    forceName: [],
    forceNamePlural: [],
    forceDescription: [],
    forceFlags: [],
    forceCost: [], // Array of force prices.  Allow for dynamic force pricing in the future.
    forceRange: [],
    forceAttack: [],
    forceAttackMultiple: [],
    forceDefense: [],
    forceDefenseMultiple: [],
    forceCargoCapacity: [],
    forceCargoSpace: [],
    forceCarrierCapacity: [],
    forceCarrierSpace: [],
    forceLiftCapacity: [],
    forceLiftSpace: [],
    forceProduction: [],
    forceZ: [],
    forceScale: [],
    forceSfxMake: [],
    forceSfxMove: [],
    forceSfxDie: [],
    forceVersion: [],

    losses: [],

    year,     // For display, year to display for first turn.  Range of 0 to 8000.

    missedAllowed: 2,   // Number of turns allowed to be missed before put on auto-play.

    autoTotal: 0,    // # of turns over the whole game have been autoplayed by all humans.
    autoCurrent: 0,  // # of turns in current streak have been autoplayed by all humans.
    autoAllowed: 2,  // # of turns of all humans on autoplay to allow before auto ending the game.

    teams: 0,   // 0 for not a team game, else 2 or 3.
    teamName: [],  // Team slot name array.  Max length 24 each for up to 8 teams.
                   // Includes the non-team entry.

    zoneOwner: [0],  // Current turn zone owner, 0 for no owner.  Includes first 0.
    zoneOwnerPrev: [0],  // Previous turn zone owner, 0 for no owner.  Includes first 0.

    nextForceID: 1,   // ID incrementer for creating the next ID for a force.

    maxBuy: [0],  // Maximum number of forces each player may purchase this turn.

    addToCapital: addToCapital ? addToCapital : Array<number>(forceTypes.length).fill(0),

    replay: {},   // Dictionary of replay arrays for the previous turn.  This includes movement,
                  // casualties, take overs, picks, eliminations and victories.

  	//====================================

  	created: nowMS,  // time game was created.
  	started: nowMS,  // time game was started.
    ended: '',

    //====================================

    // New fields for SOLO frontend created games:
		zoneBoxXSize: zoneBoxXSize,
		zoneBoxYSize: zoneBoxYSize,
    mapXScale: mapXScale,
    mapYScale: mapYScale,
		zoneBoxX: [],
		zoneBoxY: [],
    navyZone: [0],

    startingForces: [[]],  // Traditionally in the scenario, but needed for Local Solo Games.
                           // This data is later also optionally used by autopicking if required.
	};

  assignForceTypesInfo(game);

  const board: number[][] = [];
  for(let y = 0; y < boardYSize; y++) {
    board[y] = [];
    for(let x = 0; x < boardXSize; x++) {
      board[y].push(0);
    }
  }

  const rng = new xor4096(srand);
  for(let zone = 1; zone <= zones; zone++) {
    game.startingForces![zone] = Array<number>(game.forceID.length).fill(0);
    game.zoneKey[zone] = zone;
    if(zone <= game.zonesLand) {
      game.zoneFlags[zone] = BF.ZONE_FLAG_LAND_ZONE;
      game.zoneName[zone] = 'Land Zone ' + zone;
      game.zoneIncome[zone] = randInt(rng, 0, 9);

      // TODO: Maybe Move these later, so to keep the Game object more standard:
      // TODO: also make better way of configuring:
      game.startingForces![zone][0] = 2;
      game.startingForces![zone][1] = 1;
    }
    else {
      game.zoneFlags[zone] = BF.ZONE_FLAG_WATER_ZONE;
      game.zoneName[zone] = 'Water Zone ' + zone;
      game.zoneIncome[zone] = 0;

      // TODO: Maybe Move these later, so to keep the Game object more standard:
      if(Array.isArray(game.startingForces)) {
        game.startingForces[zone] = [];
      }
    }
    if(Array.isArray(game.navyZone)) {
      game.navyZone[zone] = 0;   // TODO: later this needs to be filled properly.
    }
    game.zoneOwner[zone] = 0;
    game.zoneOwnerPrev[zone] = game.zoneOwner[zone];
    game.bordersLand[zone] = [];
 		game.bordersWater[zone] = [];

    let done;
    do {
      done = true;
      // Allow one pixel border around box and an additional one distance from the sides.
      let y = randInt(rng, 2, boardYSize - zoneBoxYSize - 2);
      let x = randInt(rng, 2, boardXSize - zoneBoxXSize - 2);

      for(let yc = -1; yc < zoneBoxYSize + 1; yc++) {
        for(let xc = -1; xc < zoneBoxXSize + 1; xc++) {
          if(board[y + yc][x + xc]) {
            done = false;
            break;
          }
        }
        if(!done) {
        	break;
        }
	    }

	    if(done) {
        for(let yc = -1; yc < zoneBoxYSize + 1; yc++) {
	        for(let xc = -1; xc < zoneBoxXSize + 1; xc++) {
            board[y + yc][x + xc] = zone;
          }
        }
        if(Array.isArray(game.zoneBoxX) && game.zoneBoxXSize &&
            Array.isArray(game.zoneBoxY) && game.zoneBoxYSize) {
          game.zoneBoxX[zone] = x + Math.floor(game.zoneBoxXSize / 2);
          game.zoneBoxY[zone] = y + Math.floor(game.zoneBoxYSize / 2);
        }
	    }

    } while(!done);
  }

  let more;
  do {
  	more = false;
    for(var y = 0; y < boardYSize; y++) {
      for(var x = 0; x < boardXSize; x++) {
        if(!board[y][x]) {
          switch(randInt(rng, 0, 3)) {
            case 0:
              if(y > 0 && board[y - 1][x]) {
                board[y][x] = board[y - 1][x];
              }
              else {
                more = true;
              }
              break;
            case 1:
              if(x < boardXSize - 1 && board[y][x + 1]) {
                board[y][x] = board[y][x + 1];
              }
              else {
                more = true;
              }
              break;
            case 2:
              if(y < boardYSize - 1 && board[y + 1][x]) {
                board[y][x] = board[y + 1][x];
              }
              else {
                more = true;
              }
              break;
            case 3:
              if(x > 0 && board[y][x - 1]) {
                board[y][x] = board[y][x - 1];
              }
              else {
                more = true;
              }
              break;
            default:
              // should never happen.
              more = false;
              break;
          }
        }
      }
    }
  } while(more);

  // Calculate borders:
  for(let y = 0; y < boardYSize; y++) {
    for(let x = 0; x < boardXSize; x++) {
    	const zone = board[y][x];
    	if(zone) {
    		if(y > 0) {
    		  addBorder(game, zone, board[y - 1][x]);
    		}
    		if(x > 0) {
    		  addBorder(game, zone, board[y][x - 1]);
    		}
    		if(y < boardYSize - 1) {
    		  addBorder(game, zone, board[y + 1][x]);
    		}
    		if(x < boardXSize - 1) {
    		  addBorder(game, zone, board[y][x + 1]);
    		}
    	}
  	}
	}

  // Finalize mapData string:
  for(let y = 0; y < boardYSize; y++) {
    for(let x = 0; x < boardXSize; x++) {
      game.mapData += String.fromCharCode(board[y][x]);
    }
  }

  // Initialize losses array (includes 0 player):
  for(let p = 0; p <= game.players; p++) {
    game.losses[p] = [];
    for(let pp = 0; pp <= game.players; pp++) {
      game.losses[p].push(0);
    }
  }

  // Assign Player Data (also use localPlayerIndex to specify our local human player):
  for(let p = 1; p <= game.players; p++) {
    game.autoSpend[p] = 100;
    game.autoMove[p] = 100;
    if(p === localPlayerIndex) {
      game.playerID[p] = uid;
      game.playerName[p] = uName;
      game.playerFlags[p] = BF.PLAYER_FLAG_HUMAN;
    }
    else {
      game.playerID[p] = -p;
      game.playerName[p] = botNames[p];
      game.playerFlags[p] = BF.PLAYER_FLAG_COMPUTER;
    }
    game.playerFlag[p] = playerCountryCode[p];
    game.playerColor[p] = playerColor(p);
    game.playerScore[p] = 0;
    game.slotName[p] = '';
    game.slotFlag[p] = playerCountryCode[p];
    game.showBuy[p] = (1 << game.forceID.length) - 1;  // Show all force types.
    game.canBuy[p] = (1 << game.forceID.length) - 1;   // Can buy all force types.
    game.maxBuy[p] = 250;  // TODO: calculate or clear value, or something.

    game.finished[p] = [0, 0];

    game.playerAutoTotal[p] = 0;
    game.playerAutoCurrent[p] = 0;
    game.playerMissedTotal[p] = 0;
    game.playerMissedCurrent[p] = 0;
    game.capital[p] = 0;
    game.capitalX[p] = 0;
    game.capitalY[p] = 0;
    game.cash[p] = 0;

    game.team[p] = 0;
    game.fixedAlly[p] = (1 << p);  // Just themselves (not a team game).

    game.declaredAlly[p] = 0;
    game.force[p] = [];
  }

  createInitialForces(game, game.startingForces, game.navyZone);

  return game;
}
