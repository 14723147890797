/*
  GuiLandingListWindow.tsx
  (c) Human Cube Inc.
*/

import { Flag, FlagNameValues, Button, Step } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';
import { Game } from '../../bfcore/gameTypes';
import { WorkingData } from '../../bfworking/workingTypes';
import { Dictionary } from '../../helper/types';


function GuiForceLandingLine (
  { count, forceTypeIndex, game, landingZoneID, range, zoneID }:
  { count: number, forceTypeIndex: number, game: Game, landingZoneID: number,
     range: number, zoneID: number }
) {

  function landOne (e: any) {
    store.dispatch({
      type: actions.SHOW_LANDING_SPECIFY_WINDOW,
      forceTypeIndex: forceTypeIndex,
      range,
      zoneID,
    });
  }

  return (
    <div className='flex flex-row justify-between'>
      <b>{ count }</b>&nbsp;&nbsp;
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />
      <small>range { range }</small>&nbsp;&nbsp;
      <Button key="landOne" onPointerUp={ landOne } size="small" compact>
        { 'to ' + game.zoneName[landingZoneID] }
      </Button>
    </div>
  );
}

export default function GuiLandingListWindow (
  { game, localPlayerIndex, working, zoneID }:
  { game: Game, localPlayerIndex: number, working: WorkingData, zoneID: number }
) {
  const force = working.force;
  const forces = force.length;

  function stopPropagation (e: any) {
    e.stopPropagation();
  }

  function hideLandingListWindow (e: any) {
    store.dispatch({
      type: actions.HIDE_LANDING_LIST_WINDOW,
    });
  }

  const landingForceCounts: Dictionary<number> = {};  // Indexed by 'forceType|landingZoneID|range'
  for(let f = 0; f < forces; f++) {
    if(force[f].z === zoneID && force[f].owner === localPlayerIndex) {
      const forceType = force[f].ft;
      let landingZoneID =  working.landing[f];
      if(!landingZoneID) {
        landingZoneID = 0;
      }
      if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
        const key = forceType + '|' + landingZoneID + '|' + force[f].r;
        if(!landingForceCounts[key]) {
          landingForceCounts[key] = 0;
        }
        landingForceCounts[key]++;
      }
    }
  }

  // TODO low: could sort landingForceCounts here by forceType (or by the actual key).

  const landingLine = [];
  for(const key in landingForceCounts) {
    const f = key.split('|');
    landingLine.push(
      <GuiForceLandingLine key={ 'f' + key }
                           forceTypeIndex={ parseInt(f[0], 10) }
                           landingZoneID={ parseInt(f[1], 10) }
                           range={ parseInt(f[2], 10) }
                           count={ landingForceCounts[key] }
                           game={ game }
                           zoneID={ zoneID }
      />
    );
  }

  if(landingLine.length === 0) {
    landingLine.push(
      <div>
        You do not have any forces<br />
        here that require landing.
      </div>
    );
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
          <Step.Description>
            <Flag name={ bfH.zoneOwnerFlag(game, zoneID) as FlagNameValues } />
            {bfH.zoneOwnerName(game, zoneID)}<br />
            <div style={{marginBottom: '3px', marginTop: '3px'}}>Land aircraft to:</div>
            { landingLine }
            ----------------------------------------
            <div>
              <Button key='close' onPointerUp={ hideLandingListWindow } size='medium'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Close
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
