/*
  BaseMyMaps.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Segment } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import BaseMapTile from './BaseMapTile';
import BaseMapCreate from './BaseMapCreate';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/mainModes';
import { userCanCreateMap } from '../../helper/user';
import background from './img/background/map4326857.jpg';


export default class BaseMyMaps extends React.Component {
  state = {
    store: {},
    showCreateMapOptions: false,
  };

  constructor(props) {
    super(props);

    this.state.store = props.store;
    this.state.showCreateMapOption = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
        showCreateMapOption: false,
      });
    }
  }

  upgradeRequired = (body) => {
    store.dispatch({
      type: actions.NOTIFY_MODAL_PUSH,
      title: 'Membership Upgrade Required',
      body,
    });
    store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MEMBERSHIP_OPTIONS}});
  }

  closeCreateMap = () => {
    this.setState({ showCreateMapOptions: false });
  }

  createMap = (e) => {
    e.stopPropagation();

    if(!userCanCreateMap()) {
      this.upgradeRequired('To create your own unique maps, please upgrade your membership.');
      return;
    }

    this.setState({ showCreateMapOptions: true });
  };

  render() {
    const { showCreateMapOptions, store } = this.state;
    const { map } = store.userSession.bf;

    const mapTiles = [];
    for(let i = 0; i < map.length; i++) {
      const mapID = map[i];
      mapTiles.push(
        <div key={ mapID }>
          <br />
          <BaseMapTile
            mapID={ mapID }
            store={ this.state.store }
          />
        </div>
      );
    }

    return (
      <BaseBackScroll background={ background } >
        <BaseMenuBasic pageName='My Custom Maps' />
        <center>
          <br /><br />
          <BaseAdSense />
          <Segment compact style={{ opacity: 0.86 }}>
            Maps are used to create Scenarios. Games are started from a Scenario.<br />
            Extreme and Premium members may create their own unique custom Maps and Scenarios.<br />
            <br />
            Goto any Map or Scenario page to create your own version of a custom Map or Scenario.
            <br />
            All custom Maps you create will be listed here.<br />
            <br />
            <Button color='olive' onPointerUp={ this.createMap } >
              Create a New Map
            </Button>
            <center>
              { mapTiles }
            </center>
          </Segment>
          <br /><br /><br />
          <br /><br /><br />
        </center>
        { showCreateMapOptions &&
          <BaseMapCreate store={ store } closeHandle={ this.closeCreateMap } />
        }
      </BaseBackScroll>
    );
  }
}
