/*
  GuiScenarioEditPlayerRow.tsx
  (c) Human Cube Inc.
*/

import { ChangeEvent, SyntheticEvent } from 'react';
import { Dropdown, DropdownProps, Flag, FlagNameValues, Icon, Input, InputOnChangeData, Table } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import BF from '../../bfcore/bfconst1';
import { countryCodes } from '../../helper/countryCodes';
import { Game } from '../../bfcore/gameTypes';


const ASCII_a = 'a'.charCodeAt(0);
const ASCII_z = 'z'.charCodeAt(0);
const ASCII_A = 'A'.charCodeAt(0);
const ASCII_Z = 'Z'.charCodeAt(0);
const ASCII_0 = '0'.charCodeAt(0);
const ASCII_9 = '9'.charCodeAt(0);

function isString (v: any) {
	return (typeof v === 'string' || v instanceof String);
}

function sanitizeScenarioPlayerSlotName (teamName: string) {
  // returns a cleaned player name string.
	let s = teamName;
	if( !isString(s) )
		s = '';
	let s2 = '';
	let k = s.length;
	// clean the names here:
	let i;
	for(i=0; i < k; i++)
		if( s.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		let c = s.charAt(i)
		let cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) ) {
			s2 += c;
			lastspace = 0;
		}
		else if( c === ' ' ) {
			if( lastspace === 0 ) {
				lastspace = 1;
				s2 += ' ';
			}
		}
	}

	k = s2.length;
	for(i = k - 1; i >= 0; i--)
		if( s2.charAt(i) !== ' ' )
			break;
	if( i >= 0 )
		s = s2.substr(0, i + 1);
	else s = '';

	if( s.substr(0, 6).toLowerCase() === 'player' )
		s = '';
	return s.substr(0, BF.MAX_PLAYER_SLOT_NAME);
}

function playerForcesCount (game: Game, playerIndex: number) {
  // Returns count.
  let count = 0;
  if(Array.isArray(game.force) && Array.isArray(game.force[playerIndex]))
    for(let i = 0; i < game.force[playerIndex].length; i++) {
      const zoneID = game.force[playerIndex][i][BF.FORCE_INDEX_ZONE];
      if(zoneID >= BF.FORCE_ZONE_PRODUCED || zoneID <= BF.FORCE_ZONE_CARGO)
          count++;  // Only count live forces.
    }
  return count;
}

export default function GuiScenarioEditPlayerRow (
  { playerIndex, playerIncome, playerZones, store }:
  { playerIndex: number, playerIncome: number, playerZones: number, store: any }
) {

  const capitalChange = (e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    e.stopPropagation();
    const { game, scenarioEdit } = store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_CAPITAL,
      game,
      scenarioEdit,
      playerIndex,
      capitalID: value,
    });
  };

	const playerFlagChange = (e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    e.stopPropagation();
    const { game, scenarioEdit } = store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_PLAYER_FLAG,
      game,
      scenarioEdit,
      playerIndex,
      playerFlag: value,
    });
  };

  const playerFlagsChange = (e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    e.stopPropagation();
    const { game, scenarioEdit } = store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_PLAYER_FLAGS,
      game,
      scenarioEdit,
      playerIndex,
      playerFlags: value,
    });
  };

  const cashChange = (e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    e.stopPropagation();
    const { game, scenarioEdit } = store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_CASH,
      game,
      scenarioEdit,
      playerIndex,
      cash: value,
    });
  };

  const changePlayerName = (e: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    const { game, scenarioEdit } = store;
    const playerName = sanitizeScenarioPlayerSlotName(value);
    if(playerName) {
      store.dispatch({
        type: actions.SCENARIO_EDIT_PLAYER_NAME,
        game,
        scenarioEdit,
        playerIndex,
        playerName,
      });
    }
  };

  const teamChange = (e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    e.stopPropagation();
    const { game, scenarioEdit } = store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_TEAM,
      game,
      scenarioEdit,
      playerIndex,
      team: value,
    });
  };

  const { game, scenarioEdit } = store;

  const capitalOptions = [{ key: 0, text: 'Picking Round', value: 0 }];
  for(let z = 1; z <= scenarioEdit.zones; z++) {
    if(scenarioEdit.owner[z] === playerIndex) {
      capitalOptions.push({
        key: z,
        text: game.zoneName[z],
        value: z,
      });
    }
  }

  const playerFlagsOptions = [
    { key: BF.PLAYER_FLAG_HUMAN, text: 'Human Player', value: BF.PLAYER_FLAG_HUMAN },
    { key: BF.PLAYER_FLAG_COMPUTER, text: 'Standard Bot', value: BF.PLAYER_FLAG_COMPUTER },
  ];

  const cashOptions = [];
  for(let c = 0; c <= 250; c += 10) {
    cashOptions.push({ key: c, text: c + '', value: c });
  }

  const teamOptions = [];
  for(let t = 1; t <= scenarioEdit.teams; t++) {
    teamOptions.push({ key: t, text: scenarioEdit.teamName[t], value: t });
  }

  const playerFlagOptions = [];
  for(let f = 0; f < countryCodes.length; f++) {
    const index = scenarioEdit.playerFlag.indexOf(countryCodes[f].code.toLowerCase());
    if(index < 0 || index === playerIndex) {
      playerFlagOptions.push({
        key: countryCodes[f].code.toLowerCase(),
        text: <label>
                <Flag name={ countryCodes[f].code.toLowerCase() as FlagNameValues} />
              </label>,
        value: countryCodes[f].code.toLowerCase(),
      });
    }
  }

  return(
    <Table.Row key={ 'tr' + playerIndex } >
      { scenarioEdit.teams > 1 &&
        <Table.Cell>
          <Dropdown
            onChange={ teamChange }
            options={ teamOptions }
            placeholder='Select'
            compact
            selection
            value={ scenarioEdit.team[playerIndex] }
          />
        </Table.Cell>
      }
      <Table.Cell>
        <Icon name='square' style={{color: '#' + game.playerColor[playerIndex].toString(16) }} />
        &nbsp;
        <Dropdown
          onChange={ playerFlagChange }
          options={ playerFlagOptions }
          placeholder='Select'
          compact
          selection
          value={ scenarioEdit.playerFlag[playerIndex] }
        />
        &nbsp;
        <Input placeholder='Player Name...'
                defaultValue={ scenarioEdit.playerName[playerIndex] ?
                              scenarioEdit.playerName[playerIndex] :
                              ('Player' + playerIndex) }
                onChange={ changePlayerName }
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          onChange={ playerFlagsChange }
          options={ playerFlagsOptions }
          placeholder='Select'
          compact
          selection
          value={ scenarioEdit.playerFlags[playerIndex] }
        />
      </Table.Cell>

      <Table.Cell textAlign='center'>
        <Dropdown
          onChange={ cashChange }
          options={ cashOptions }
          placeholder='Select'
          compact
          selection
          value={ scenarioEdit.cash[playerIndex] }
        />
      </Table.Cell>

      <Table.Cell>
        <Dropdown
          onChange={ capitalChange }
          options={ capitalOptions }
          placeholder='Select'
          compact
          selection
          value={ scenarioEdit.capital[playerIndex] }
        />
      </Table.Cell>

      <Table.Cell textAlign='center'>{ playerIncome }</Table.Cell>

      <Table.Cell textAlign='center'>{ playerForcesCount(game, playerIndex)}</Table.Cell>
      <Table.Cell textAlign='center'>{ playerZones }</Table.Cell>
    </Table.Row>
  );
}
