/*
  GuiTurnsRequired.tsx
  (c) Human Cube Inc.
*/

import BF from '../../bfcore/bfconst1';
import { Game } from '../../bfcore/gameTypes';
import GuiTile from './GuiTile';


export default function GuiTurnsRequired (
  {game, required, submitted}: {game: Game, required: number, submitted: number}
) {
  // Displays turns required tile.
  const requiredNames = [];

  for(let p = 1; p <= game.players; p++) {
    if( (required & (1<<p)) && !(submitted & (1<<p)) ) {
      requiredNames.push(game.playerName[p]);
    }
  }

  const renderedNames = [];
  if(requiredNames.length === 1) {
    renderedNames.push(<div key={'rn' + Math.random() }><b>{ requiredNames[0] }</b><br /></div>);
  }
  else {
    for(let r = 0; r < requiredNames.length; r++) {
      if(r === requiredNames.length - 1) {
        renderedNames.push(
          <div key={'rn' + Math.random() }>
            and <b>{ requiredNames[r] }</b>
            <br />
          </div>
        );
      }
      else {
        renderedNames.push(
          <div key={'rn' + Math.random() }>
            <b>{ requiredNames[r] }</b>
            { (r < requiredNames.length - 2) ? ',' : '' }
            <br />
          </div>
        );
      }
    }
  }

  return (
    <GuiTile edge='right'>
      Waiting for<br />
      { renderedNames }
      to complete<br />
      their turn{ renderedNames.length > 1 ? 's' : '' }.<br />
      { (game.turn > BF.TURN_NORMAL) &&
        <>
          <br />
          Previous turn<br />
          replay shown.
        </>
      }
    </GuiTile>
  );
}
