/*
  BaseHome.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Icon, Message } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseFooter from './BaseFooter';
import BaseGameTile from './BaseGameTile';
import BaseHomeJumpToGame from './BaseHomeJumpToGame';
import BaseHomeJumpToMap from './BaseHomeJumpToMap';
import BaseHomeJumpToScenario from './BaseHomeJumpToScenario';
import BaseNewsItem from './BaseNewsItem';
import BaseRank from './BaseRank';
import { sendPacket } from '../../network/network';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';
import { isBetaTester, userBeforeFirstGame, userOnFirstGame, userJustFinishedFirstGame }
        from '../../helper/user';
import background from './img/background/fleet1920x1200.jpg';

import { LocalSoloGameList } from './BaseLocalSoloGames';


const tipsDivStyle = {
  width: '580px',
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '6px',
  border: '2px solid'
};

export default class BaseHome extends React.Component {

  state = {
    store: {},
    showJumpToGame: false,
    showJumpToScenario: false,
    showJumpToMap: false,
  };

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
      });
    }
  }

  startFirstGame (e) {
    sendPacket('bf_gameCreate', {firstGame: 1});
  }

  jumpToGame = (e) => {
    this.setState({ showJumpToGame: true });
  };

  closeJumpToGame = (e) => {
    this.setState({ showJumpToGame: false });
  };

  jumpToScenario = (e) => {
    this.setState({ showJumpToScenario: true });
  };

  closeJumpToScenario = (e) => {
    this.setState({ showJumpToScenario: false });
  };

  jumpToMap = (e) => {
    this.setState({ showJumpToMap: true });
  };

  closeJumpToMap = (e) => {
    this.setState({ showJumpToMap: false });
  };

  toggleEmailNotifications = (e) => {
    const { flags } = this.state.store.userSession.bf;
    let newValue = 1;
    if(flags & BF.USER_FLAG_EMAIL_NOTIFY_TURN) {
      newValue = 0;
    }
    sendPacket('bf_in', {e: 'setEmailNotify', flags: newValue});
  };

  render() {
    const { showJumpToGame, showJumpToScenario, showJumpToMap } = this.state;

    const { news, userSession } = this.state.store;
    const { uid } = userSession;
    const { flags, uName, rank, score, xp, membershipLevel, plays, finishedCount, game}
            // TODO:  map and scenario arrays
          = userSession.bf;

    let emailNotficationsOn = false;
    if(flags & BF.USER_FLAG_EMAIL_NOTIFY_TURN) {
      emailNotficationsOn = true;
    }

    let gameTiles = [];
    for(let i = 0; i < game.length; i++) {
      gameTiles.push(
        <BaseGameTile
          key={ game[i] }
          gameID={ game[i] }
          gameStatus={ this.state.store.gameStatus[game[i]] }
          uid={ uid }
        />
      );
    }

    const newsItems = [];
    for(let i = 0; i < 10 && i < news.length; i++) {
      newsItems.push(
        <BaseNewsItem key={ 'n' + i } newsItem={ news[i] } />
      );
    }

    const gridColumns = [
      <Grid.Column key='gc93'>
        <center>
          <h1>
            <br />
            Welcome {uName}
          </h1>
        </center>
          <div className='flex flex-row justify-center mt-3'>
            <Link to='/ranks'>
              <Button inverted color='olive' size='small'>
                <BaseRank rank={ rank } />
              </Button>
            </Link>
            <Link to='/leaderboard/score'>
              <Button inverted color='olive' size='small' style={{ lineHeight: '116%' }} >
                {score}<br />Score
              </Button>
            </Link>
            <Link to='/leaderboard/experience'>
              <Button inverted color='olive' size='small' style={{lineHeight: '116%'}} >
                { xp }<br />XP
              </Button>
            </Link>
            <Link to='/leaderboard/experience'>
              <Button inverted color='olive' size='small' style={{lineHeight: '116%'}} >
                { plays }<br />Plays
              </Button>
            </Link>
            <Link to='/membership'>
              <Button inverted color='olive' size='small'
                      style={{lineHeight: '116%'}} >
                { bfH.membershipLevelAsString(membershipLevel) }<br />Member
              </Button>
            </Link>
            {!userBeforeFirstGame() &&
              <Link to='/friendsfoes'>
                <Button inverted color='olive' size='small' style={{lineHeight: '116%'}} >
                  Friends<br />and Foes
                </Button>
              </Link>
            }
            {!userBeforeFirstGame() &&
              <Link to='/blocklist'>
                <Button inverted color='olive' size='small' onPointerUp={this.showBlockList}
                        style={{lineHeight: '116%'}} >
                  Block<br />List
                </Button>
              </Link>
            }
          </div>
      </Grid.Column>
    ];

    if(userBeforeFirstGame()) {
      gridColumns.push(
        <Grid.Column key='gc94'>
          <center>
            <div style={ tipsDivStyle }>
              <h3>Ready to join your first game?</h3>
              <h5>
                Don't worry! It will just be you against two easy computer players on a simple map.<br />
                Once you complete this intro game, more complex game options will be available for you.
              </h5>
              <Button color='olive' size='small' onPointerUp={ this.startFirstGame } >
                Start Your First Game
              </Button>
            </div>
          </center>
        </Grid.Column>
      );
    }
    else {
      if(userOnFirstGame()) {
        gridColumns.push(
          <Grid.Column key='gc95'>
            <center>
              <h4>Active Games</h4>
              {gameTiles}
            </center>
          </Grid.Column>
        );
        gridColumns.push(
          <Grid.Column key='gc94a'>
            <center>
              <div style={ tipsDivStyle }>
                <h5>
                  Congratulations, you have joined your first game. Tap above to play.<br />
                  Win or lose, once you have completed the game, more advance options will be available.<br />
                  These will include multiplayer games and games with aircraft and ships.
                </h5>
              </div>
            </center>
          </Grid.Column>
        );
        gridColumns.push(
          <Grid.Column key='gc111'>
            <center>
              <Link to='/rules/landwar'>
                <Button inverted color='olive' size='small'>
                  Land War Game Rules
                </Button>
              </Link>
            </center>
          </Grid.Column>
        );
      }
      else {
        gridColumns.push(
          <Grid.Column key='gc95'>
            <center>
              <BaseAdSense />
              <h4>Active Games</h4>
              {gameTiles}<br />
              <LocalSoloGameList uid={uid} />
              <div style={{ height: '12px' }}></div>
              <Link to='/join/solo'>
                <Button inverted color='olive' size='small'>
                  Join Solo Game...
                </Button>
              </Link>
              <Link to='/join/multi'>
                <Button inverted color='olive' size='small'>
                  Join Multiplayer Game...
                </Button>
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to='/games/finished'>
                <Button inverted color='olive' size='small'>
                  Finished Games ({ finishedCount })
                </Button>
              </Link>
            </center>
          </Grid.Column>
        );

        if(userJustFinishedFirstGame()) {
          gridColumns.push(
            <Grid.Column key='gc94a'>
              <center>
                <div style={ tipsDivStyle }>
                  <h5>
                    Great! You finished your first game. That was easy.<br />
                    You now have lots of different single player games to choose from.<br />
                    If you are ready for the challenge, there are also plenty of<br />
                    multiplayer games ready to be joined.
                  </h5>
                </div>
              </center>
            </Grid.Column>
          );
        }

        gridColumns.push(
          <Grid.Column key='gc111'>
            <center>
              <Link to='/rules/conduct'>
                <Button inverted color='olive' size='small'>Rules of Conduct</Button>
              </Link>
              <Link to='/rules/landwar'>
                <Button inverted color='olive' size='small'>Land War Rules</Button>
              </Link>
              <Link to='/rules/standard'>
                <Button inverted color='olive' size='small'>Standard Rules</Button>
              </Link>
              <Link to='/rules/forcetypes'>
                <Button inverted color='olive' size='small'>Force Types</Button>
              </Link>
            </center>
          </Grid.Column>
        );

        gridColumns.push(
          <Grid.Column key='gc98'>
            <center>
              <div style={{
                  width: '580px',
                  background: 'rgba(255, 255, 255, 0.25)',
                  padding: '6px',
                  border: '2px solid',
                  textAlign: 'left',
                }} >
                <h3>Player and Game News</h3>
                <ul>
                  { newsItems }
                </ul>
                <Link to='/news'>
                  <Button inverted color='olive' size='small'>
                    More News
                  </Button>
                </Link>
              </div>
            </center>
          </Grid.Column>
        );

        gridColumns.push(
          <Grid.Column key='gc100'>
            <center>
              <Button inverted color='olive' size='small'
                      onPointerUp={ this.toggleEmailNotifications } >
                Email Notification are
                { emailNotficationsOn ? <span style={{color:'green'}}> ON</span> :
                                        <span style={{color:'red'}}> OFF</span> }
              </Button>
              <Button inverted color='olive' size='small' onPointerUp={ this.jumpToGame } >
                Jump To Game...
              </Button>
              <Button inverted color='olive' size='small' onPointerUp={ this.jumpToScenario } >
                Jump To Scenario...
              </Button>
              <Button inverted color='olive' size='small' onPointerUp={ this.jumpToMap } >
                Jump To Map...
              </Button>
            </center>
          </Grid.Column>
        );

        gridColumns.push(
          <Grid.Column key='gc101'>
            <center>
              <Link to='/mymaps'>
                <Button inverted color='olive' size='small'>
                  My Maps ({ userSession.bf.map.length })
                </Button>
              </Link>
              <Link to='/myscenarios'>
                <Button inverted color='olive' size='small'>
                  My Scenarios ({ userSession.bf.scenario.length })
                </Button>
              </Link>
            </center>
          </Grid.Column>
        );
      }
    }

    if( isBetaTester() ) {
      gridColumns.push(
        <Grid.Column key='gc96'>
          <center>
            <Link to='/localsolostart'>
              <Button inverted color='yellow' size='tiny'>
                Game Generator (BETA feature)
              </Button>
            </Link>
          </center>
        </Grid.Column>
      );
    }

/*
      <div
        className="full-bg-size"
        style={{
          backgroundImage: `url(${background})`,
          height: "100vh",
          color: "white"
        }}>
*/
    return (
      <BaseBackScroll background={ background } >
        <div style={{ color: 'white' }} >
          <Grid centered columns={1} style={{padding: '6px'}}>
            { gridColumns }
          </Grid>
          <br />
          <br />
          <center>Version {this.state.store.version}</center>
          <BaseFooter />
        </div>
        <Message compact style={{
            position: 'absolute', margin: '2px', right: '0px', top: '0px', opacity:'0.85'
          }}>
          <a target='_blank' href='https://forum.battleforces.com' rel='noopener noreferrer'>
            <Icon name='share square'/> BATTLE FORCES Forum
          </a>
        </Message>
        { showJumpToGame && <BaseHomeJumpToGame closeHandle={ this.closeJumpToGame } /> }
        { showJumpToScenario && <BaseHomeJumpToScenario closeHandle={ this.closeJumpToScenario }/> }
        { showJumpToMap && <BaseHomeJumpToMap closeHandle={ this.closeJumpToMap } /> }
        <br /><br /><br />
        <br /><br /><br />
      </BaseBackScroll>
    );
  }
}
