/*
  forceDetailsForZone.tsx
  (c) Human Cube Inc.

  Notes:
    - Supports transports that load from neighbouring zones only.
    - Supports lifters that load from same zone only.
*/

import BF from '../bfcore/bfconst1';
import { Game } from '../bfcore/gameTypes';
import { WorkingData } from '../bfworking/workingTypes';
import { Dictionary, NumberDictionary } from './types';


export interface ForceDetailsForZone {
  forcesByRange: number[][][];  // [playerIndex][range][forceTypeIndex]
  forcesTotal: number[][];    // [playerIndex][forceTypeIndex] (not to be confused with forceCount).
  forcesUndoable: number,  // Number of forces in this zone for localPlayerIndex that can undo movement.
  playersWithForces: number[]; // List of playerIndexes of players that have one or more forces here.
  forceCount: number[];   // Number of forces per player in zone (not to be confused with forcesTotal).
  hasFactory: boolean;    // Does this zone have a factory.
  productionPoints: number;  // Production points for localPlayerIndex.
  cash: number;
  buy: NumberDictionary<number>;  // {fti:0, fti:0, fti:0, ... } indexed by forceTypeIndex,
                               // count of purchases by localPlayerIndex for this zone.

  zoneLifters: number;     // Number of forces (cargo plane) in this zone for localPlayerIndex.
  zoneLiftPoints: number;  // Total available lift points this zone for localPlayerIndex.
  hasLift: boolean;        // localPlayerIndex has lift in zone1.
  lift: number[];  // [forceTypeIndex] indexed by forceTypeIndex,
                   // count of lift of localPlayerIndex's on lifters in zone1.
  liftersByLifts: Dictionary<number>,  // In zone1 for local player only and not during replay:
              // [forceTypeIndex:Range:LiftPoints:Lift1TypeIndex:Lift2TypeIndex...]: count
              // Lifts sorted type forceTypeIndex.
  liftersByLiftsSimple: Dictionary<number>,  // In zone1, all players all lifters and lifts if applicable.
              // Has owner, but not any range or load information. Lifts sorted type forceTypeIndex.
              // [owner:forcetypeIndex:LiftTypeIndex:Lift2TypeIndex...]

  zone2Transports: number;   // Number of transports in zone2 for localPlayerIndex.
  zone2LoadPoints: number;   // Total available load points in zone2 for localPlayerIndex.
  hasCargo: boolean;  // localPlayerIndex has cargo in zone1.
  cargo: number[];  // [forceTypeIndex] indexed by forceTypeIndex,
                    // count of cargo of localPlayerIndex's on transports in zone1.
  transportsByCargo: Dictionary<number>;  // In zone1 for local player only and not during replay:
              // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
              // Cargo sorted type forceTypeIndex.
  transportsByCargo2: Dictionary<number>;  // In zone2 for local player only and not during replay:
              // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
              // Cargo sorted type forceTypeIndex.
  transportsByCargoSimple: Dictionary<number>;  // In zone1, all players all transports and cargo if applicable.
              // Has owner, but not any range or load information.
              // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]

  moveableCount: number[];   // number of forces that can move per player (excludes retreats).
  retreatableCount: number[];   // number of forces that can retreat per player.
  landingCount: number;  // number of aircraft localPlayerIndex has set to land here.
  aircraftCount: number; // number of aircraft in this zone that need to land for localPlayerIndex.
  blockedAirCount: number;   // number of opposing forces blocking air movement in this zone.
  blockedSurfaceCount: number;  // number of opposing forces blocking surface movement in this zone.
};

export function forceDetailsForZone (
  game: Game, working: WorkingData, zoneID: number, zoneID2: number, replayStage: number,
): ForceDetailsForZone {
  // Returns an object of info based on the current game state adjusted with local moves for the
  // given zone.
  // If in replay mode, it will gather info based on the current replay stage. Pass in a
  // replayStage of -1 if not in replay mode.
  const ret: ForceDetailsForZone = {
    forcesByRange: [], // [playerIndex][range][forceTypeIndex]
    forcesTotal: [],   // [playerIndex][forceTypeIndex] (not to be confused with forceCount).
    forcesUndoable: 0, // Number of forces in this zone for localPlayerIndex that can undo movement.
    playersWithForces: [], // List of playerIndexes of players that have one or more forces here.
    forceCount: [],   // Number of forces per player in zone (not to be confused with forcesTotal).
    hasFactory: false,    // this zone has a factory or not.
    productionPoints: 0,  // Production points for localPlayerIndex.
    cash: 0,
    buy: {}, // {fti:0, fti:0, fti:0, ... } indexed by forceTypeIndex,
             // count of purchases by localPlayerIndex for this zone.
    zoneLifters: 0,     // Number of forces (cargo plane) in this zone for localPlayerIndex.
    zoneLiftPoints: 0,  // Total available lift points this zone for localPlayerIndex.
    hasLift: false,
    lift: [],
    liftersByLifts: {},  // In zone1 for local player only and not during replay:
                // [forceTypeIndex:Range:LiftPoints:Lift1TypeIndex:Lift2TypeIndex...]: count
                // Lifts sorted type forceTypeIndex.
    liftersByLiftsSimple: {},  // In zone1, all players all lifters and lifts if applicable.
                // Has owner, but not any range or load information. Lifts sorted type forceTypeIndex.
                // [owner:forcetypeIndex:LiftTypeIndex:Lift2TypeIndex...]

    zone2Transports: 0,   // Number of friendly transports in zone2 for localPlayerIndex.
    zone2LoadPoints: 0,   // Total available load points in zone2 for localPlayerIndex.
    hasCargo: false,  // localPlayerIndex has cargo in zone1.
    cargo: [],  // [forceTypeIndex] indexed by forceTypeIndex,
                // count of cargo of localPlayerIndex's on transports in zone1.
    transportsByCargo: {},  // In zone1 for local player only and not during replay:
                // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
                // Cargo sorted type forceTypeIndex.
    transportsByCargo2: {},  // In zone2 for local player only and not during replay:
                // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
                // Cargo sorted type forceTypeIndex.
    transportsByCargoSimple: {},  // In zone1, all players all transports and cargo if applicable.
                // Has owner, but not any range or load information.
                // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]

    moveableCount: [],   // number of forces that can move per player (excludes retreats).
    retreatableCount: [],   // number of forces that can retreat per player.
    landingCount: 0,  // number of aircraft localPlayerIndex has set to land here.
    aircraftCount: 0, // number of aircraft in this zone that need to land for localPlayerIndex.
    blockedAirCount: 0,   // number of opposing forces blocking air movement in this zone.
    blockedSurfaceCount: 0,  // number of opposing forces blocking surface movement in this zone.
  };

  if(!game || !working || !zoneID) {
    return ret;
  }

  const { force, localPlayerIndex } = working;

  ret.forceCount = new Array(game.players + 1).fill(0);   // Number of forces per player in zone (not to be confused with forcesTotal).
  ret.lift = new Array(game.forceID.length).fill(0);  // [forceTypeIndex] indexed by forceTypeIndex,
  ret.cargo = new Array(game.forceID.length).fill(0);  // [forceTypeIndex] indexed by forceTypeIndex,
  ret.moveableCount = new Array(game.players + 1).fill(0);   // number of forces that can move per player (excludes retreats).
  ret.retreatableCount = new Array(game.players + 1).fill(0);   // number of forces that can retreat per player.

  for(let p = 0; p <= game.players; p++) {
    ret.forcesByRange[p] = [];
    for(let r = 0; r <= BF.FORCE_MAX_MOVES; r++) {
      ret.forcesByRange[p].push(new Array(game.forceID.length).fill(0));
    }
    ret.forcesTotal[p] = new Array(game.forceID.length).fill(0);
  }

  if(replayStage < 0) {
    // Normal mode, not a replay.

    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID) {
        const forceOwner = force[f].owner;
        const forceType = force[f].ft;

        ret.forcesByRange[forceOwner][force[f].r][forceType]++;
        ret.forcesTotal[forceOwner][forceType]++;
        ret.forceCount[forceOwner]++;

        if(force[f].move.length > 0) {  // force has already moved, so could be undo'ed.
          ret.forcesUndoable++;
        }

        if(force[f].r > 0) {
          ret.moveableCount[forceOwner]++;
        }
        if(force[f].zr) {
          ret.retreatableCount[forceOwner]++;
        }

        if(forceOwner === localPlayerIndex) {
          const forceID = force[f].id;
          const cargoForceZoneID = BF.FORCE_ZONE_CARGO - forceID;
          if(game.forceCargoCapacity[forceType] > 0) {
            // It is a transport ship or something similiar.
            for(let c = 0; c < force.length; c++) {
              if(force[c].z === cargoForceZoneID) {
                ret.cargo[force[c].ft]++;
                ret.hasCargo = true;
              }
            }
          }
          if(game.forceLiftCapacity[forceType] > 0) {
            // It is a cargo plane or something similiar.
            for(let c = 0; c < force.length; c++) {
              if(force[c].z === cargoForceZoneID) {
                ret.lift[force[c].ft]++;
                ret.hasLift = true;
              }
            }
          }

          if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
            ret.aircraftCount++;
          }
        }
        else if(game.teams < 2 || game.team[localPlayerIndex] !== game.team[forceOwner]) {
          // Not a localPlayer's force nor a team member's force.

          if(game.forceFlags[forceType] & BF.FORCE_FLAG_BLOCKS_AIR) {
            ret.blockedAirCount++;
          }
          if(game.forceFlags[forceType] & BF.FORCE_FLAG_BLOCKS_SURFACE) {
            ret.blockedSurfaceCount++;
          }
        }
      }
    }
    for(let forceIndex in working.landing) {
      if( working.landing[forceIndex] === zoneID ) {
        ret.landingCount++;
      };
    }
  }
  else {
    // Replay mode, so calculate working values from replay position.

    for(let f = 0; f < force.length; f++) {
      if(force[f].rz[replayStage] === zoneID) {
        const forceOwner = force[f].owner;
        const forceType = force[f].ft;

        // ret.forcesByRange[forceOwner][force[f].r][forceType]++;
        ret.forcesTotal[forceOwner][forceType]++;
        ret.forceCount[forceOwner]++;
      }
    }
  }

  for(let p = 0; p <= game.players; p++) {
    for(let f = 0; f < game.forceID.length; f++) {
      if(ret.forcesTotal[p][f]) {
        ret.playersWithForces.push(p);
        break;
      }
    }
  }

  if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) {
    if(working.hasFactory[zoneID]) {
      ret.hasFactory = true;
    }
    ret.productionPoints = working.production[zoneID];
  }
  else if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_WATER_ZONE) {
    // Water zone, so sum of all potential production of land neighbours.
    for(let b = 0; b < game.bordersLand[zoneID].length; b++) {
      const borderID = game.bordersLand[zoneID][b];
      if(game.zoneOwner[borderID] === localPlayerIndex && working.hasFactory[borderID]) {
        ret.hasFactory = true;
        ret.productionPoints += working.production[borderID];
      }
    }
  }

  ret.cash = working.cash;
  ret.buy = zoneID2 ? working.buy[zoneID2] : working.buy[zoneID];
  if(!ret.buy) {
    ret.buy = {};
  }

  // This zone:
  for(let f = 0; f < force.length; f++) {
    if(force[f].z === zoneID && force[f].owner === localPlayerIndex) {
      const forceType = force[f].ft;
      if(game.forceLiftCapacity[forceType] > 0 &&
          (game.forceFlags[forceType] & BF.FORCE_FLAG_LOAD_SAME_ZONE)) {
        ret.zoneLifters++;
        ret.zoneLiftPoints += force[f].llp;
      }
    }
  }

  // Selected neighbouring zone:
  if(zoneID2) {
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID2 && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0 &&
            (game.forceFlags[forceType] & BF.FORCE_FLAG_LOAD_SAME_ZONE) === 0) {
          ret.zone2Transports++;
          ret.zone2LoadPoints += force[f].lp;
        }
      }
    }
  }

  ret.transportsByCargo = calcTransportsByCargo(game, working, zoneID, replayStage);
  ret.transportsByCargoSimple = calcTransportsByCargoSimple(game, working, zoneID, replayStage);

  ret.liftersByLifts = calcLiftersByLifts (game, working, zoneID, replayStage);
  ret.liftersByLiftsSimple = calcLiftersByLiftsSimple(game, working, zoneID, replayStage);

  if(replayStage < 0 && zoneID2) {
    // Normal mode, not a replay and a second zone is also selected.
    ret.transportsByCargo2 = calcTransportsByCargo(game, working, zoneID2, replayStage);
  }

  return ret;
}


function calcTransportsByCargo (
  game: Game, working: WorkingData, zoneID: number, replayStage: number,
): Dictionary<number> {
  const transportsByCargo: Dictionary<number> = {};
    // For local player only and not during replay:
    // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
    // Cargo sorted type forceTypeIndex.

  const { force, localPlayerIndex } = working;

  if(replayStage < 0) {
    // Normal mode, not a replay.

    // calc: transportsByCargo: {},
    // [forceTypeIndex:Range:Cargo1TypeIndex:Cargo2TypeIndex...]: count
    // Cargo sorted type forceTypeIndex.
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const cargoStr = cargo.sort().join(':');
          const key =
            forceType + ':' + force[f].r + ':' + force[f].lp + ':' + cargoStr;
          if(!transportsByCargo[key]) {
            transportsByCargo[key] = 0;
          }
          transportsByCargo[key]++;
        }
      }
    }

    return transportsByCargo;
  }

  // Replay mode:

  // Do not calc transportsByCargo.

  return transportsByCargo;
}


export function calcTransportsByCargoSimple (
  game: Game, working: WorkingData, zoneID: number, replayStage: number,
): Dictionary<number>  {
  // For all players all transports and cargo if applicable.
  // Has owner, but not any range or load information.
  // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]

  const transportsByCargoSimple: Dictionary<number> = {};

  const { force } = working;

  if(replayStage < 0) {
    // Normal mode, not a replay.

    // calc: transportsByCargoSimple: {},
    // In zone1, all players all transports and cargo if applicable.
    // Has owner, but not any range or load information.
    // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID) {
        const owner = force[f].owner;
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const key = owner + ':' + forceType + ':' + cargo.sort().join(':');
          if(!transportsByCargoSimple[key]) {
            transportsByCargoSimple[key] = 0;
          }
          transportsByCargoSimple[key]++;
        }
      }
    }
 
    return transportsByCargoSimple;
  }

  // Replay mode:

  // calc: transportsByCargoSimple: {},
  // In zone1, all players all transports and cargo if applicable.
  // Has owner, but not any range or load information.
  // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
  for(let f = 0; f < force.length; f++) {
    if(force[f].rz[replayStage] === zoneID) {
      const owner = force[f].owner;
      const forceType = force[f].ft;
      if(game.forceCargoCapacity[forceType] > 0) {
        const cargo = [];
        const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
        for(let c = 0; c < force.length; c++) {
          if(force[c].rz[replayStage] === cargoZoneID) {
            cargo.push(force[c].ft);
          }
        }
        const key = owner + ':' + forceType + ':' + cargo.sort().join(':');
        if(!transportsByCargoSimple[key]) {
          transportsByCargoSimple[key] = 0;
        }
        transportsByCargoSimple[key]++;
      }
    }
  }

  return transportsByCargoSimple;
}


function calcLiftersByLifts (
  game: Game, working: WorkingData, zoneID: number, replayStage: number,
): Dictionary<number> {
  // For local player only and not during replay:
  // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
  // Cargo sorted type forceTypeIndex.
  const liftersByLifts: Dictionary<number> = {};

  const { force, localPlayerIndex } = working;

  if(replayStage < 0) {
    // Normal mode, not a replay.

    // calc: ret.liftersByLifts: {},
    // [forceTypeIndex:Range:Lift1TypeIndex:Lift2TypeIndex...]: count
    // Lifts sorted type forceTypeIndex.
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceLiftCapacity[forceType] > 0) {
          const lifts = [];
          const liftZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === liftZoneID) {
              lifts.push(force[c].ft);
            }
          }
          const key = forceType + ':' + force[f].r + ':' + force[f].llp + ':' +
                      lifts.sort().join(':');
          if(!liftersByLifts[key]) {
            liftersByLifts[key] = 0;
          }
          liftersByLifts[key]++;
        }
      }
    }

    return liftersByLifts;
  }

  // Replay mode.

  // Do not calc liftersByLifts.

  return liftersByLifts;
}

export function calcLiftersByLiftsSimple (
  game: Game, working: WorkingData, zoneID: number, replayStage: number,
): Dictionary<number> {
  // For all players all transports and cargo if applicable.
  // Has owner, but not any range or load information.
  // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
  const liftersByLiftsSimple: Dictionary<number> = {};

  const { force } = working;

  if(replayStage < 0) {
    // Normal mode, not a replay.

    // calc: liftersByLiftsSimple: {},
    // In zone1, all players all lifters and lifts if applicable.
    // Has owner, but not any range or load information.
    // [owner:forcetypeIndex:Lift1TypeIndex:Lift2TypeIndex...]
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID) {
        const owner = force[f].owner;
        const forceType = force[f].ft;
        if(game.forceLiftCapacity[forceType] > 0) {
          const lift = [];
          const liftZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === liftZoneID) {
              lift.push(force[c].ft);
            }
          }
          const key = owner + ':' + forceType + ':' + lift.sort().join(':');
          if(!liftersByLiftsSimple[key]) {
            liftersByLiftsSimple[key] = 0;
          }
          liftersByLiftsSimple[key]++;
        }
      }
    }

    return liftersByLiftsSimple;
  }

  // Replay mode.

  // calc: liftersByLiftsSimple: {},
  // In zone1, all players all lifters and lifts if applicable.
  // Has owner, but not any range or load information.
  // [owner:forcetypeIndex:Lift1TypeIndex:Lift2TypeIndex...]
  for(let f = 0; f < force.length; f++) {
    if(force[f].rz[replayStage] === zoneID) {
      const owner = force[f].owner;
      const forceType = force[f].ft;
      if(game.forceLiftCapacity[forceType] > 0) {
        const lift = [];
        const liftZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
        for(let c = 0; c < force.length; c++) {
          if(force[c].rz[replayStage] === liftZoneID) {
            lift.push(force[c].ft);
          }
        }
        const key = owner + ':' + forceType + ':' + lift.sort().join(':');
        if(!liftersByLiftsSimple[key]) {
          liftersByLiftsSimple[key] = 0;
        }
        liftersByLiftsSimple[key]++;
      }
    }
  }

  return liftersByLiftsSimple;
}
