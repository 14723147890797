/*
  GuiForceMoveLine.tsx
  (c) Human Cube Inc.
*/

import { Flag, FlagNameValues } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import { Game } from '../../bfcore/gameTypes';
import GuiButton from './GuiButton';


export default function GuiForceMoveLine ({
  forceTypeIndex, 
  forceType,
  transportCargoKey,
  range,
  count,
  flag,
  localPlayerIndex,
  zoneIDFrom,
  zoneIDTo,
  game,
}: {
  forceTypeIndex: number, 
  forceType: number,
  transportCargoKey: string,
  range: number,
  count: number,
  flag: string,
  localPlayerIndex: number,
  zoneIDFrom: number,
  zoneIDTo: number,
  game: Game,
}) {

  function onPointerUpMoveOne() {
    store.dispatch({
      type: actions.MOVE_SOME_FORCES,
      game: game,
      forceTypeIndex: forceTypeIndex,
      range: range,
      transportCargoKey: transportCargoKey,
      count: 1,
      playerIndex: localPlayerIndex,
      zoneIDFrom: zoneIDFrom,
      zoneIDTo: zoneIDTo,
    });
  }

  function onPointerUpMoveAll() {
    store.dispatch({
      type: actions.MOVE_SOME_FORCES,
      game: game,
      forceTypeIndex: forceTypeIndex,
      range: range,
      transportCargoKey: transportCargoKey,
      count: count,
      playerIndex: localPlayerIndex,
      zoneIDFrom: zoneIDFrom,
      zoneIDTo: zoneIDTo,
    });
  }

  const cargo = [];
  let loadPointsDisplay = null;
  if(transportCargoKey) {
    const cargoParts = transportCargoKey.split(':');
    loadPointsDisplay = <small>&nbsp;&nbsp;LP:&nbsp;{cargoParts[2]}&nbsp;</small>;
    for(let i = 3; i < cargoParts.length; i++) {
      if(cargoParts[i].length > 0) {
        cargo.push(
          <GuiForce key={'c' + i} forceType={game.forceID[parseInt(cargoParts[i])]} half />
        );
      }
    }
  }

  return (
    <div className='flex flex-row justify-between' >
      <div style={{ marginLeft: -5, marginTop: 2 }}>
        <Flag name={flag as FlagNameValues} />
      </div>
      <div className='mt-1'>
        <b>{count}</b>&nbsp;
      </div>
      <GuiForce forceType={forceType} />
      <div className='flex flex-col'>
        {cargo}
      </div>
      <div className='mt-1'>
        {loadPointsDisplay}
        &nbsp;<small>range&nbsp;{range}</small>&nbsp;
      </div>
      <div className='flex flex-row' style={{ height: 30, marginBottom: 2 }}>
        <div style={{ marginLeft: 3 }}>
          <GuiButton onClick={onPointerUpMoveOne}>
            Move&nbsp;1
          </GuiButton>
        </div>
        <div style={{ marginLeft: 3 }}>
          <GuiButton onClick={onPointerUpMoveAll}>
            Move&nbsp;ALL
          </GuiButton>
        </div>
      </div>
    </div>
  );
}
