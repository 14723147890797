/*
  GuiMapEditSave.tsx
  (c) Human Cube Inc.
*/

import { Button, Input, Modal, Segment } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import { sendPacket } from '../../network/network';
import { autoGenerateMapBorders, sanitizeMapDescription, sanitizeMapName } from '../../helper/map';


export default function GuiMapEditSave ({closeHandle, store}: {closeHandle: any, store: any}) {

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  const cancelClose = (e: any) => {
    e.stopPropagation();
    if(closeHandle) {
      closeHandle();
    }
  }

  const createNewMapClose = (e: any) => {
    e.stopPropagation();
    if(closeHandle) {
      closeHandle();
    }
		const { mapEdit } = store;

		const map = Object.assign({}, mapEdit);
		const message = autoGenerateMapBorders(map);

		if(message) {
			store.dispatch({
				type: actions.NOTIFY_MODAL_PUSH,
				title: 'New Map Needs Adjustment Before Saving',
				body: message,
			});
			return;
		}

		sendPacket('bf_mapSave', { map });
  };

  const nameChange = (e: any) => {
    const name = sanitizeMapName(e.target.value);
    if(name) {
      store.dispatch({ type: actions.MAP_EDIT_NAME, name });
    }
  };

  const descriptionChange = (e: any) => {
    const description = sanitizeMapDescription(e.target.value);
    if(description) {
      store.dispatch({ type: actions.MAP_EDIT_DESCRIPTION, description });
    }
  };

  const { mapEdit } = store;

  return (
    <Modal
        onPointerDown={ stopPropagation }
        closeOnDimmerClick={ false }
        closeOnEscape={ false }
        dimmer='inverted'
        open={ true }
        size='small'>
      <Modal.Header>Save Map</Modal.Header>
      <Modal.Description>
        <div>
          <Segment key='mapName' compact style={{ float:'left' }} >
            <span>
              Map Name (maximum 47 characters):<br />
              <Input placeholder='Map Name...'
                      defaultValue={ mapEdit.name ? mapEdit.name : ' ' }
                      onChange={ nameChange }
                      style={{ width: '666px' }}
              />
            </span>
          </Segment>

          <Segment key='mapDescription' compact style={{ float:'left' }} >
            <span>
              Map Description (maximum 999 characters):<br />
              <Input placeholder='Map Description...'
                      defaultValue={ mapEdit.description ? mapEdit.description : ' ' }
                      onChange={ descriptionChange }
                      style={{ width: '666px' }}
              />
            </span>
          </Segment>

          <Segment key='cancelok' compact style={{ float:'left' }} >
            &nbsp;&nbsp;&nbsp;
            <Button onClick={ cancelClose } negative >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={ createNewMapClose } positive >
              &nbsp;&nbsp;Save&nbsp;&nbsp;
            </Button>
          </Segment>

        </div>
      </Modal.Description>
    </Modal>
  );
}
