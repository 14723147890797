/*
  GuiUnloadLiftersWindow.tsx
  (c) Human Cube Inc.

  Display hardcoded to 'Paratroopers' for human readability.
*/

import actions from '../../reducers/actions';
import { storeDispatch } from '../../app/store';
import { Game } from '../../bfcore/gameTypes';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiButton from './GuiButton';
import GuiForce from './GuiForce';
import GuiTile from './GuiTile';


function GuiForceUnloadLine (
  { count, forceTypeIndex, game, localPlayerIndex, zoneID }:
  { count: number, forceTypeIndex: number, game: Game,
    localPlayerIndex: number, zoneID: number }
) {

  function unlift (amount: number) {
    storeDispatch({
      type: actions.UNLOAD_SOME_LIFT_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
      forceTypeIndex,
      count: amount,
    });
  };

  return (
    <div className='flex flex-row space-x-2' style={{ marginTop: 2 }}>
      <div style={{ marginTop: 5 }}><b>{ count }</b></div>
      <div style={{ marginTop: 3 }}>
        <GuiForce forceType={ game.forceID[forceTypeIndex] } />
      </div>
      <GuiButton key='unloadOne' onClick={ () => { unlift(1); } }>Unload 1</GuiButton>
      <GuiButton key='unloadAll' onClick={ () => { unlift(count); } }>Unload All</GuiButton>
    </div>
  );
}

export default function GuiUnloadLiftersWindow (
  { details, game, localPlayerIndex, zoneID, hideWindow }:
  { details: ForceDetailsForZone, game: Game,
    localPlayerIndex: number, zoneID: number, 
    hideWindow: () => void  }
) {

  if(!details.hasLift) {
    return (
      <GuiTile>
        <p><b>No remaining paratroopers to unload.</b></p>
        <GuiButton onClick={ hideWindow } center={ true } >
          Close
        </GuiButton>
      </GuiTile>
    );
  }

  const unloadLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    const count = details.lift[f]
    if(count > 0) {
      unloadLine.push(
        <GuiForceUnloadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
                            localPlayerIndex={ localPlayerIndex } count={ count }
        />
      );
    }
  }

  return (
    <GuiTile>
      <p><b>Unload Paratroopers</b></p>
      { unloadLine }
      <br />
      <GuiButton onClick={ hideWindow } center={ true } >
        Close
      </GuiButton>
    </GuiTile>
  );
}
