/*
  GuiMapEdit.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { store } from '../../app/store';
import actions from '../../reducers/actions';
import { Dropdown, Icon, Input, Label, Menu, Segment } from 'semantic-ui-react';
import GuiModal from './GuiModal';
import GuiMapEditSave from './GuiMapEditSave';
import { goBack } from '../../reducers/commands';
import BF from '../../bfcore/bfconst1';


export default class GuiMapEdit extends React.Component {
  isMouseDown = false;
  mouseDownX = 0;
  mouseDownY = 0;
  mouseLastX = 0;
  mouseLastY = 0;
  mouseMoved = false;

  state = {
    camera: {},
    mapEdit: {},
    mapEditSaved: false,
    mapData: [],
    store: {},
    xCenter: 0,
    yCenter: 0,

    backModalMessage: '',
    showMapEditSave: false,
    pointerMode: -2,  // -2 for select for info, -1 for select for draw, 0..zones for draw zone.
    selectedZone: 0,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.

    this.state.camera = props.store.camera;
    this.state.mapEdit = props.store.mapEdit;
    this.state.mapEditSaved = props.store.mapEditSaved;
    this.state.mapData = props.store.mapEdit.mapData;
    this.state.store = props.store;
    this.state.xCenter = props.xCenter;
    this.state.yCenter = props.yCenter;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store.camera !== this.state.camera ||
        nextProps.store.mapEdit !== this.state.mapEdit ||
        nextProps.store.mapEditSaved !== this.state.mapEditSaved ||
        nextProps.store !== this.state.store ||
        nextProps.xCenter !== this.state.xCenter ||
        nextProps.yCenter !== this.state.yCenter) {
      this.setState({
        camera: nextProps.store.camera,
        mapEdit: nextProps.store.mapEdit,
        mapEditSaved: nextProps.store.mapEditSaved,
        mapData: nextProps.store.mapEdit.mapData,
        store: nextProps.store,
        xCenter: nextProps.xCenter,
        yCenter: nextProps.yCenter,
      });
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleMapTap = (zoneID, offset, pointerMode, selectedZone) => {
    // A zone, or no zone has been tapped.
    if(!zoneID || zoneID < 0) {
      zoneID = 0;
    }

    if(pointerMode === -1 && zoneID) {
      this.setState({ pointerMode: zoneID });
      this.setState({ selectedZone: zoneID });
      store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: zoneID});
    }
    else if(pointerMode === -2 && selectedZone !== zoneID) {
      this.setState({ selectedZone: zoneID });
      store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: zoneID});
    }
    else if(pointerMode >= 0 && offset >= 0 && pointerMode !== zoneID) {
      store.dispatch({type: actions.MAP_EDIT_PIXEL, offset, zoneID: pointerMode});
    }
  };

  clickBack = (e) => {
    e.stopPropagation();
    if(!this.state.mapEditSaved) {
      this.setState({ backModalMessage:
        'You have not saved your changes to the map. Tap [SAVE MAP] before exiting to save your changes.'
      });
      return;
    }
    goBack();
  };

  clickBackModalCancel = (e) => {
    this.setState({ backModalMessage: '' });
  }

  clickBackModalOK = (e) => {
    goBack();
  }

  closeMapEditSave = () => {
    this.setState({ showMapEditSave: false });
  };

  mapEditSave = (e) => {
    e.stopPropagation();
    this.setState({ showMapEditSave: true });
  }

  zoomIn = (e) => {
    e.stopPropagation();
    store.dispatch({ type: actions.MAP_EDIT_ZOOM_IN });
  }

  zoomOut = (e) => {
    e.stopPropagation();
    store.dispatch({ type: actions.MAP_EDIT_ZOOM_OUT });
  }

  onMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.backModalMessage) {
      this.isMouseDown = true;
      this.mouseLastX = e.clientX;
      this.mouseLastY = e.clientY;
      this.mouseDownX = e.clientX;
      this.mouseDownY = e.clientY;
      this.mouseMoved = false;
    }
    else {
      this.isMouseDown = false;
    }
  };

  onMouseMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.backModalMessage && this.isMouseDown) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const { mapEdit, pointerMode, xCenter, yCenter } = this.state;
      const { xs, ys } = mapEdit;
      const { mapEditZoom } = this.state.store;

      const mapY = Math.floor(((mouseY - yCenter) / mapEditZoom) / 2) + (ys / 2);
      const mapX = Math.floor(((mouseX - xCenter) / mapEditZoom +
                              ((mapY & 1) === 0 ? -1 : 0)) / 2) +
                              (xs / 2);

      let zoneID = 0;
      let offset = -1;
      if(mapX >= 0 && mapX < xs && mapY >= 0 && mapY < ys) {
        offset = mapY * xs + mapX;
        zoneID = parseInt(mapEdit.mapData.substring(offset * 2, offset * 2 + 2), 16);
      }

      if(pointerMode >= 0 && offset >= 0 && pointerMode !== zoneID) {
        store.dispatch({type: actions.MAP_EDIT_PIXEL, offset, zoneID: pointerMode});
      }

      this.mouseLastX = mouseX;
      this.mouseLastY = mouseY;
      this.mouseMoved = true;
    }
    else {
      this.isMouseDown = false;
    }
  };

  onMouseUp = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.backModalMessage && this.isMouseDown) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const { mapEdit, pointerMode, selectedZone, xCenter, yCenter } = this.state;
      const { xs, ys } = mapEdit;
      const { mapEditZoom } = this.state.store;

      const mapY = Math.floor(((mouseY - yCenter) / mapEditZoom) / 2) + (ys / 2);
      const mapX = Math.floor(((mouseX - xCenter) / mapEditZoom +
                              ((mapY & 1) === 0 ? -1 : 0)) / 2) +
                              (xs / 2);

      let zoneID = 0;
      let offset = -1;
      if(mapX >= 0 && mapX < xs && mapY >= 0 && mapY < ys) {
        offset = mapY * xs + mapX;
        zoneID = parseInt(mapEdit.mapData.substring(offset * 2, offset * 2 + 2), 16);
      }
      this.handleMapTap(zoneID, offset, pointerMode, selectedZone);
    }
    this.isMouseDown = false;
  };

  pointerModeChange = (e, { value }) => {
    this.setState({ pointerMode: value });
    if(value > 0) {
      this.setState({ selectedZone: value });
      store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: value});
    }
    else {
      this.setState({ selectedZone: 0 });
      store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: 0});
    }
  };

  selectDraw = (e) => {
    this.setState({ pointerMode: -1 });
    store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: 0});
  };

  selectSelect = (e) => {
    this.setState({ pointerMode: -2 });
    store.dispatch({type: actions.MAP_EDIT_SET_SELECTED_ZONE_ID, zoneID: 0});
  };

  zoneFlagsChange = (e, { value }) => {
    const { selectedZone } = this.state;
    if(selectedZone > 0 && value) {
      store.dispatch({type: actions.MAP_EDIT_ZONE_FLAGS,
                      zoneID: selectedZone,
                      zoneFlags: value});
    }
  };

  zoneNameChange = (e, { value }) => {
    const { selectedZone } = this.state;
    if(selectedZone > 0 && value) {
      if(selectedZone > 0) {
        store.dispatch({type: actions.MAP_EDIT_ZONE_NAME,
                        zoneID: selectedZone,
                        zoneName: value});
      }
    }
  };

  render() {
    const { pointerMode, selectedZone, mapEdit, mapEditSaved } = this.state;

    const pointerModeOptions = [
      { key: -2, text: 'Pick Zone For Details', value: -2 },
      { key: -1, text: 'Pick Zone To Draw', value: -1 },
      { key: 0, text: 'Clear', value: 0 },
    ];
    for(let z = 1; z <= mapEdit.zones; z++) {
      pointerModeOptions.push({ key: z, text: 'Draw ' + mapEdit.zoneName[z], value: z });
    }

    return (
      <div style={{ height: '100%', width: '100%', border: 0, padding: 0 }}
           onPointerDown={ this.onMouseDown }
           onPointerMove={ this.onMouseMove }
           onPointerUp={ this.onMouseUp }
        >
        <Menu inverted fluid widths={ 5 }
              style={{ opacity: 0.75 }}
              onPointerDown={ this.stopPropagation } >
          <Menu.Item name='BACK' onPointerUp={ this.clickBack } />

          <Menu.Item >
            <Icon.Group onPointerUp={ this.zoomOut }>
              <Icon name='zoom out' />
            </Icon.Group>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Icon.Group onPointerUp={ this.zoomIn } >
              <Icon name='zoom in' />
            </Icon.Group>
          </Menu.Item>

          <Menu.Item>
            <Icon.Group onPointerUp={ this.selectSelect }>
              <Icon name='eyedropper' />
            </Icon.Group>
            &nbsp;&nbsp;&nbsp;
            <Dropdown
              onChange={ this.pointerModeChange }
              options={ pointerModeOptions }
              placeholder='Choose an option'
              selection
              value={ pointerMode }
            />
            &nbsp;&nbsp;&nbsp;
            <Icon.Group onPointerUp={ this.selectDraw } >
              <Icon name='paint brush' />
            </Icon.Group>
          </Menu.Item>

          <Menu.Item onPointerUp={ this.mapEditSave }>
            <Label color ="green">SAVE MAP{ mapEditSaved ? '' : '*' }</Label>
          </Menu.Item>;

          <Menu.Item>MAP EDIT</Menu.Item>
        </Menu>

        { selectedZone > 0 &&
          <Segment key={ 'sz' + selectedZone }
                   inverted
                   compact
                   style={{ float: 'left' }}
                   onPointerDown={ this.stopPropagation } >
            Edit Zone Name and Type:
            <br />
            <Input placeholder='Zone Name...'
                   defaultValue={ mapEdit.zoneName[selectedZone] }
                   onChange={ this.zoneNameChange }
                   style={{ width: '196px' }}
            />
            <br />
            <Dropdown
              onChange={ this.zoneFlagsChange }
              options={[
                { key: BF.ZONE_FLAG_LAND_ZONE, text: 'Land Zone', value: BF.ZONE_FLAG_LAND_ZONE },
                { key: BF.ZONE_FLAG_WATER_ZONE, text: 'Water Zone', value: BF.ZONE_FLAG_WATER_ZONE },
              ]}
              placeholder='Choose an option'
              selection
              value={ mapEdit.zoneFlags[selectedZone] }
            />
          </Segment>
        }

        { this.state.showMapEditSave &&
          <GuiMapEditSave store={ this.state.store }
                          closeHandle={ this.closeMapEditSave } />
        }

        { this.state.backModalMessage &&
          <GuiModal
            title='Exit Warning'
            body={ this.state.backModalMessage }
            cancelCallback={ this.clickBackModalCancel }
            okCallback={ this.clickBackModalOK }
            okText='Exit Without Saving'
          />
        }
      </div>
    );
  }
}
