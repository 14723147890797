/*
  GuiZoneMoveInfo.tsx
  (c) Human Cube Inc.
*/

import { FaDownload, FaArrowUpFromWaterPump } from 'react-icons/fa6';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { ForceDetailsForZone } from '../../helper/forceDetailsForZone';
import GuiForceMoveLine from './GuiForceMoveLine';
import BF from '../../bfcore/bfconst1';
import { Game } from '../../bfcore/gameTypes';
import GuiTile from './GuiTile';
import GuiButton from './GuiButton';


export default function GuiZoneMoveInfo (
  {game, details, zoneID, zoneID2, localPlayerIndex}:
  {game: Game, details: ForceDetailsForZone, zoneID: number, zoneID2: number,
  localPlayerIndex: number}
) {

  function showLoadTransports () {
    store.dispatch({
      type: actions.SHOW_LOAD_TRANSPORTS_WINDOW,
    });
  }

  function showUnloadTransports () {
    store.dispatch({
      type: actions.SHOW_UNLOAD_TRANSPORTS_WINDOW,
    });
  }

  let moveableTotal = 0;
  const yourForces = [];

  for(let f = 0; f < game.forceID.length; f++) {
    if(game.forceCargoCapacity[f] === 0 && game.forceLiftCapacity[f] === 0) {
      // Only non-Transports and non-lifters, they are handled later.
      for(let r = 1; r <= BF.FORCE_MAX_MOVES; r++) { // Only those with range.
        const count = details.forcesByRange[localPlayerIndex][r][f];
        if(count <= 0) {
          continue;
        }
        if(((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_LAND) &&
            (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE))
            ||
            ((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_WATER) &&
            (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_WATER_ZONE))) {
          const line =
            <GuiForceMoveLine
              key={f + 'a:a' + r}
              forceTypeIndex={f}
              forceType={game.forceID[f]}
              transportCargoKey={''}
              range={r}
              count={count}
              flag={game.playerFlag[localPlayerIndex]}
              localPlayerIndex={localPlayerIndex}
              zoneIDFrom={zoneID}
              zoneIDTo={zoneID2}
              game={game}
            />;
          yourForces.push(line);
          moveableTotal += count;
        }
      }
    }
  }

  for (let key in details.transportsByCargo) {
    const count = details.transportsByCargo[key];
    // key is:  transportForceTypeIndex:range:cargotypeindex1:cargotypeindex2 ...
    const parts = key.split(':');
    const f = parseInt(parts[0]);
    const range = parseInt(parts[1]);
    if(range > 0) {
      if(((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_LAND) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE))
          ||
          ((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_WATER) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_WATER_ZONE))) {
        const line =
          <GuiForceMoveLine
            key={'ft' + key}
            forceTypeIndex={f}
            forceType={game.forceID[f]}
            transportCargoKey={key}
            range={range}
            count={count}
            flag={game.playerFlag[localPlayerIndex]}
            localPlayerIndex={localPlayerIndex}
            zoneIDFrom={zoneID}
            zoneIDTo={zoneID2}
            game={game}
          />;
        yourForces.push(line);
        moveableTotal += count;
      }
    }
  }

  for (let key in details.liftersByLifts) {
    const count = details.liftersByLifts[key];
    // key is:  lifterForceTypeIndex:range:lifttypeindex1:lifttypeindex2 ...
    const parts = key.split(':');
    const f = parseInt(parts[0]);
    const range = parseInt(parts[1]);
    if(range > 0) {
      if(((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_LAND) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE))
          ||
          ((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_WATER) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_WATER_ZONE))) {
        const line =
          <GuiForceMoveLine
            key={'ft' + key}
            forceTypeIndex={f}
            forceType={game.forceID[f]}
            transportCargoKey={key}
            range={range}
            count={count}
            flag={game.playerFlag[localPlayerIndex]}
            localPlayerIndex={localPlayerIndex}
            zoneIDFrom={zoneID}
            zoneIDTo={zoneID2}
            game={game}
          />;
        yourForces.push(line);
        moveableTotal += count;
      }
    }
  }

  if(moveableTotal === 0) {
    // TODO: Give a better explanation if they have locked forces here, need full range to load
    // onto transports, etc.
    yourForces.push(
      <div key='noDirectMove' className='flex flex-col space-y-1'>
        <div className='max-w-64'>
          You do not have any forces in<br /><b>{ game.zoneName[zoneID] }</b><br />
          that can move directly to<br /><b>{ game.zoneName[zoneID2] }</b>.
        </div>
      </div>
    );
  }

  if(details.zone2Transports > 0 && (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
    yourForces.push(
      <div key='transportLoad'>
        <br />
        <GuiButton onClick={ showLoadTransports } center={ true } >
          <FaDownload /><div>Load Transports</div>
        </GuiButton>
      </div>
    );
  }

  if(details.hasCargo && (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE)) {
    yourForces.push(
      <div key='transportUnload'>
        <br />
        <GuiButton onClick={ showUnloadTransports } center={ true } >
          <FaArrowUpFromWaterPump /><div>Unload Transports</div>
        </GuiButton>
      </div>
    );
  }

  return (
    <GuiTile edge='left' maxWidth={450}>
      <div className='flex flex-col space-y-1'>
        { yourForces }
      </div>
    </GuiTile>
  );
}
