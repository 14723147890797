/*
  BaseMain.tsx
  (c) Human Cube Inc.
*/

import mainModes from '../../reducers/mainModes';
import BaseBlockList from './BaseBlockList';
import BaseConduct from './BaseConduct';
import BaseFinishedGames from './BaseFinishedGames';
import BaseForceTypes from './BaseForceTypes';
import BaseFriendFoeList from './BaseFriendFoeList';
import BaseGameDetail from './BaseGameDetail';
import BaseHome from './BaseHome';
import BaseJoinMulti from './BaseJoinMulti';
import BaseJoinSolo from './BaseJoinSolo';
import BaseLeaderboardScore from './BaseLeaderboardScore';
import BaseLeaderboardXP from './BaseLeaderboardXP';
import BaseLoading from './BaseLoading';
import BaseLogin from './BaseLogin';
import BaseMapDetail from './BaseMapDetail';
import BaseMembershipOptions from './BaseMembershipOptions';
import BaseMyMaps from './BaseMyMaps';
import BaseMyScenarios from './BaseMyScenarios';
import BaseNews from './BaseNews';
import BasePlayerDetail from './BasePlayerDetail';
import BaseRanks from './BaseRanks';
import BaseRulesLandWar from './BaseRulesLandWar';
import BaseRulesStandard from './BaseRulesStandard';
import BaseScenarioDetail from './BaseScenarioDetail';
import BaseLocalSoloStart from './BaseLocalSoloStart';


export default function BaseMain ({ store }: { store: any }) {
  switch(store.mainMode) {
    case mainModes.START_UP:
      return <BaseLoading store={store} />;
    case mainModes.LOGIN:
      return <BaseLogin store={store} />;
    case mainModes.HOME:
      return <BaseHome store={store} />;
    case mainModes.PLAYER_DETAIL:
      return <BasePlayerDetail store={store} />;
    case mainModes.GAME_DETAIL:
      return <BaseGameDetail store={store} />;
    case mainModes.FINISHED_GAMES:
      return <BaseFinishedGames store={store} />;
    case mainModes.MEMBERSHIP_OPTIONS:
      return <BaseMembershipOptions store={store} />;
    case mainModes.RANKS:
      return <BaseRanks store={store} />;
    case mainModes.LEADERBOARD_SCORE:
      return <BaseLeaderboardScore store={store} />;
    case mainModes.LEADERBOARD_XP:
      return <BaseLeaderboardXP store={store} />;
    case mainModes.BLOCK_LIST:
      return <BaseBlockList store={store} />;
    case mainModes.FRIEND_FOE_LIST:
      return <BaseFriendFoeList store={store} />;
    case mainModes.JOIN_SOLO:
      return <BaseJoinSolo store={store} />;
    case mainModes.JOIN_MULTI:
      return <BaseJoinMulti store={store} />;
    case mainModes.NEWS:
      return <BaseNews news={ store.news } />;
    case mainModes.MAP_DETAIL:
      return <BaseMapDetail store={ store } />;
    case mainModes.SCENARIO_DETAIL:
      return <BaseScenarioDetail store={ store } />;
    case mainModes.RULES_LAND_WAR:
      return <BaseRulesLandWar />;
    case mainModes.RULES_STANDARD:
      return <BaseRulesStandard />;
    case mainModes.FORCE_TYPES:
      return <BaseForceTypes />;
    case mainModes.CONDUCT:
      return <BaseConduct />;
    case mainModes.MY_MAPS:
      return <BaseMyMaps store={ store } />;
    case mainModes.MY_SCENARIOS:
      return <BaseMyScenarios store={ store } />;
    case mainModes.LOCAL_SOLO_START:
      return <BaseLocalSoloStart store={ store } />;
    default:
      return <div><h1>Contact Support.  :-)</h1></div>;
  }
}
