/*
  workingForceIndex.ts
  (c) Human Cube Inc.
  Utils to obtain the force index into the forces turn working data.
*/

import BF from '../bfcore/bfconst1';
import { WorkingData } from './workingTypes';


export function forceIndexMatchingForceID (working: WorkingData, forceID: number) {
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;
  for (let i = 0; i < forces; i++) {
    if (force[i].id === forceID) {
      return i;
    }
  }
  return -1;
}

export function forceIndexMatchingAttributes (
  working: WorkingData, playerIndex: number, zoneID: number, forceType: number, range: number,
) {
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;
  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range) {
      return i;
    }
  }
  return -1;
}

export function forceIndexMatchingAttributesRetreat (
  working: WorkingData,
  playerIndex: number,
  zoneID: number,
  forceType: number,
  range: number,
  retreatZoneID: number,
) {
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;
  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range &&
      force[i].zr === retreatZoneID) {
      return i;
    }
  }
  return -1;
}

export function forceIndexMatchingTransportCargoKey (
  working: WorkingData, playerIndex: number, zoneID: number, transportCargoKey: string,
) {
  // transportCargoKey is:  "forceType:range:loadPoints:cargotypeindex1:cargotypeindex2:..."
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;

  const parts = transportCargoKey.split(':');
  const forceType = parseInt(parts[0]);
  const range = parseInt(parts[1]);
  const loadPoints = parseInt(parts[2]);

  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range &&
      force[i].lp === loadPoints) {
      const cargo = [];
      const cargoZoneID = BF.FORCE_ZONE_CARGO - force[i].id;
      for (let c = 0; c < force.length; c++) {
        if (force[c].z === cargoZoneID) {
          cargo.push(force[c].ft);
        }
      }
      const key = forceType + ':' + range + ':' + loadPoints + ':' + cargo.sort().join(':');
      if (key === transportCargoKey) {
        return i;
      }
    }
  }
  return -1;
}

export function forceIndexMatchingLifterLiftKey (
  working: WorkingData, playerIndex: number, zoneID: number, lifterLiftKey: string,
) {
  // lifterLiftKey is:  "forceType:range:loadPoints:cargotypeindex1:cargotypeindex2:..."
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;

  const parts = lifterLiftKey.split(':');
  const forceType = parseInt(parts[0]);
  const range = parseInt(parts[1]);
  const liftPoints = parseInt(parts[2]);

  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range &&
      force[i].llp === liftPoints) {
      const cargo = [];
      const cargoZoneID = BF.FORCE_ZONE_CARGO - force[i].id;
      for (let c = 0; c < force.length; c++) {
        if (force[c].z === cargoZoneID) {
          cargo.push(force[c].ft);
        }
      }
      const key = forceType + ':' + range + ':' + liftPoints + ':' + cargo.sort().join(':');
      if (key === lifterLiftKey) {
        return i;
      }
    }
  }
  return -1;
}

export function forceIndexMatchingTransportCargoKeyRetreat (
  working: WorkingData,
  playerIndex: number,
  zoneID: number,
  transportCargoKey: string,
  retreatZoneID: number,
) {
  // transportCargoKey is:  "forceType:range:loadPoints:cargotypeindex1:cargotypeindex2:..."
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;

  const parts = transportCargoKey.split(':');
  const forceType = parseInt(parts[0]);
  const range = parseInt(parts[1]);
  const loadPoints = parseInt(parts[2]);

  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range &&
      force[i].lp === loadPoints &&
      force[i].zr === retreatZoneID) {
      const cargo = [];
      const cargoZoneID = BF.FORCE_ZONE_CARGO - force[i].id;
      for (let c = 0; c < force.length; c++) {
        if (force[c].z === cargoZoneID) {
          cargo.push(force[c].ft);
        }
      }
      const key = forceType + ':' + range + ':' + loadPoints + ':' + cargo.sort().join(':');
      if (key === transportCargoKey) {
        return i;
      }
    }
  }
  return -1;
}

export function forceIndexMatchingLifterLiftKeyRetreat (
  working: WorkingData,
  playerIndex: number,
  zoneID: number,
  lifterLiftKey: string,
  retreatZoneID: number,
) {
  // lifterLiftKey is:  "forceType:range:loadPoints:cargotypeindex1:cargotypeindex2:..."
  // returns -1 if not found.
  const force = working.force;
  const forces = force.length;

  const parts = lifterLiftKey.split(':');
  const forceType = parseInt(parts[0]);
  const range = parseInt(parts[1]);
  const loadPoints = parseInt(parts[2]);

  for (let i = 0; i < forces; i++) {
    if (force[i].z === zoneID &&
      force[i].owner === playerIndex &&
      force[i].ft === forceType &&
      force[i].r === range &&
      force[i].lp === loadPoints &&
      force[i].zr === retreatZoneID) {
      const cargo = [];
      const cargoZoneID = BF.FORCE_ZONE_CARGO - force[i].id;
      for (let c = 0; c < force.length; c++) {
        if (force[c].z === cargoZoneID) {
          cargo.push(force[c].ft);
        }
      }
      const key = forceType + ':' + range + ':' + loadPoints + ':' + cargo.sort().join(':');
      if (key === lifterLiftKey) {
        return i;
      }
    }
  }
  return -1;
}
