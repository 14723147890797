/*
  workingUtil.ts
  (c) Human Cube Inc.
  Functions to help manipulate the player turn state.
  Used while the player or AI is working on their turn.
*/

import BF from '../bfcore/bfconst1';
import bfH from '../bfcore/bf_helper';
import { getNewZoneXY } from '../bfcore/bf_hitmap';
import { forceIndexMatchingForceID } from './workingForceIndex';
import { Game } from '../bfcore/gameTypes';
import { WorkingData } from './workingTypes';


function _buildTurnWorkingData (game: Game, working: WorkingData, localPlayerIndex: number) {
  // Extra data we add to the working object that is utilized while the user creates their turn.
  // We do not modify the original turn data while the user creates their turn.
  // Working.force must be initialized first!!!

  let force = working.force;

  working.localPlayerIndex = localPlayerIndex;
  working.distance = bfH.calcDistanceTable(game);
  working.cash = 0;
  working.buy = {};  // object of objects indexed by zoneID and forceTypeIndex
  working.production = [];
  working.productionFrom = []; // Entry for each force produced in zone.  Used for undo to credit
                               // production points back.
                               // Array of arrays: [zoneID][factoryZoneID1, factoryZoneID2, ...]
  working.hasFactory = []; // Entry for each zone for all players.

  working.landing = {};  // forceIndex: zoneID of specified landing location.
  working.landingCapacity = [];  // total capacity per zoneID if unlimited if above 10000000.
  working.landingUsed = [];      // per zoneID actual landing points used.
  working.carrierCapacity = [];  // total carrier capacity points per zone.
  working.carrierUsed = [];      // carrier capacity points used per zone.

  if(localPlayerIndex >= 0 && localPlayerIndex < game.cash.length) {
    working.cash = game.cash[localPlayerIndex];
  }

  for(let z = 0; z <= game.zones; z++) {
    working.production[z] = 0;
    working.productionFrom[z] = [];
    working.hasFactory[z] = 0;
    working.carrierCapacity[z] = 0;
    working.carrierUsed[z] = 0;
    working.landingCapacity[z] = 0;
    working.landingUsed[z] = 0;
    if(game.zoneOwner[z] === localPlayerIndex ||
        (game.teams > 1 && game.team[game.zoneOwner[z]] === game.team[localPlayerIndex])) {
      working.landingCapacity[z] = 10000000;
    }
  }

  for(let f = 0; f < force.length; f++) {
    const zoneID = force[f].z;
    const forceOwner = force[f].owner;
    const forceType = force[f].ft;

    if(forceOwner === localPlayerIndex && zoneID > 0) {
      if(game.forceCarrierCapacity[forceType] > 0) {
        working.carrierCapacity[zoneID] += game.forceCarrierCapacity[forceType];
      }

      if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
        if(working.landingCapacity[zoneID] - working.landingUsed[zoneID] <= 0 &&
            game.forceCarrierSpace[forceType] >= 0) {
          working.carrierUsed[zoneID] += game.forceCarrierSpace[forceType];
        }
        else {
          working.landingUsed[zoneID]++;
        }

        working.landing[f] = zoneID;
      }

      // TODO Later: we do not support BF.FORCE_FLAG_MUST_LAND_ANY anywhere yet.

    }

    if(game.forceProduction[forceType] && game.zoneOwner[zoneID] === forceOwner) {
      working.hasFactory[zoneID] = 1;

      if(forceOwner === localPlayerIndex) {
        if((game.forceFlags[forceType] & BF.FORCE_FLAG_CAPITAL_PROD_UNLIMITED) &&
            bfH.isCapital(game, zoneID)) {
          working.production[zoneID] += 20000000;
        }
        else {
          working.production[zoneID] += game.forceProduction[forceType] * game.zoneIncome[zoneID];
        }
      }
    }
  }

  working.cash = game.cash[localPlayerIndex];
}

function forceLocationArray (game: Game, z: number, x: number, y: number) {
  // return [zone, x, y] but generate new X and Y values.
  if(z > BF.FORCE_ZONE_PRODUCED ) {
    const p = getNewZoneXY(game, z);
    return [z, p.x / BF.WORLD_WIDTH, p.y / BF.WORLD_HEIGHT];
  }
  return [z, x / BF.WORLD_WIDTH, y / BF.WORLD_HEIGHT];
}

export function establishRootState (game: Game, localPlayerIndex: number) {

  let working: WorkingData = {
    gameID: game._id,  // Store the gameID here for reference and cross-referencing.
    force: [],
    localPlayerIndex: localPlayerIndex,
    zoneHits: [],  // Indexed by zoneID and each is an array of forceIDs hit in zone.
    nextForceID: 1,  // Used during startingForces manipulation in scenario editing.
    replayStage: [], // Array of replayStage index values appropriate for this game/turn.

    distance: [],  // Zone distance table.
    cash: 0,
    buy: {}, //  {};  // object of objects indexed by zoneID and forceTypeIndex
    production: [],
    productionFrom: [],   // Entry for each force produced in zone.
    // Used for undo to credit production points back.
    // Array of arrays: [zoneID][factoryZoneID1; factoryZoneID2; ...]
    hasFactory: [], // Entry for each zone for all players (0 for does not have).

    landing: {},  // forceIndex: zoneID of specified landing location.
    landingCapacity: [],  // total capacity per zoneID if unlimited if above 10000000.
    landingUsed: [],      // per zoneID actual landing points used.
    carrierCapacity: [],  // total carrier capacity points per zone.
    carrierUsed: [],      // carrier capacity points used per zone.

    ai: {
      moveStage: 0,  // Which move is being worked on. Force move 0..MAX_MOVES.
      // data?: any;  // Data used by the AI turn generator.
    },
  };

  let forces = 0;
  const force = working.force;

  for(let p = 0; p <= game.players; p++) {
    if(Array.isArray(game.force[p])) {
      for(let f = 0; f < game.force[p].length; f++) {
        const playerForce = game.force[p][f];
        if(Array.isArray(playerForce)) {

          if (working.nextForceID <= playerForce[BF.FORCE_INDEX_ID]) {
            working.nextForceID = playerForce[BF.FORCE_INDEX_ID] + 1;
          }

          const [z, x, y] = forceLocationArray(
            game,
            playerForce[BF.FORCE_INDEX_ZONE],
            playerForce[BF.FORCE_INDEX_X],
            playerForce[BF.FORCE_INDEX_Y],
          );

          let rsz: number;
          let rsx: number;
          let rsy: number;
          if (playerForce[BF.FORCE_INDEX_X] !== playerForce[BF.FORCE_INDEX_X_PREV] ||
            playerForce[BF.FORCE_INDEX_Y] !== playerForce[BF.FORCE_INDEX_Y_PREV]) {
            [rsz, rsx, rsy] = forceLocationArray(
              game,
              playerForce[BF.FORCE_INDEX_ZONE_PREV],
              playerForce[BF.FORCE_INDEX_X_PREV],
              playerForce[BF.FORCE_INDEX_Y_PREV],
            );
          }
          else {
            // If X and Y match, then preserve them since it is probably a build.
            rsz = playerForce[BF.FORCE_INDEX_ZONE_PREV];
            rsx = x;
            rsy = y;
          }

          force[forces++] = {
            // Fixed attributes:
            id: playerForce[BF.FORCE_INDEX_ID],
            ft: playerForce[BF.FORCE_INDEX_TYPE],
            owner: p,
            // Current Turn Starting Positions:
            sx: x,
            sy: y,
            sz: z,
            sr: playerForce[BF.FORCE_INDEX_MOVE_POINTS],
            slp: playerForce[BF.FORCE_INDEX_LOAD_POINTS],
            sllp: playerForce[BF.FORCE_INDEX_LIFT_POINTS],
            szr: playerForce[BF.FORCE_INDEX_ZONE_RETREAT],
            // Current Turn Animation Start Positions:
            ax: x,
            ay: y,
            az: z,
            // Current Turn Positions:
            x, y, z,
            r: playerForce[BF.FORCE_INDEX_MOVE_POINTS],
            lp: playerForce[BF.FORCE_INDEX_LOAD_POINTS],
            llp: playerForce[BF.FORCE_INDEX_LIFT_POINTS],
            zr: playerForce[BF.FORCE_INDEX_ZONE_RETREAT],
            // Replay Starting Postions:
            rsx, rsy, rsz,
            rsr: playerForce[BF.FORCE_INDEX_MOVE_POINTS],
            // Replay state (stage 0):
            rx: [rsx],
            ry: [rsy],
            rz: [rsz],
            // This turns movement commands:
            move: []  // Array of moves
          };
        }
      }
    }
  }

  // working.zoneOwnerStart = [0];
  // working.zoneOwnerMid = [0];
  // working.zoneOwnerEnd = [0];
  // for(let z = 1; z <= game.zones; z++) {
  //   working.zoneOwnerStart[z] = game.zoneOwnerPrev[z];
  //   working.zoneOwnerEnd[z] = game.zoneOwner[z];
  // }

  // Create list of replay stages that apply for this game/turn:
  for(let i = 0; i < BF.MAX_REPLAY_STAGES; i++) {
    if(i <= BF.REPLAY_STAGE_MOVE1 || i > BF.REPLAY_STAGE_MOVE8) {
      working.replayStage.push(i);
    }
    else {
      // Skip any movement replay stages that do not have any moves this turn.
      // Always show move stage 1 at it might contain retreats and clearly shows that nothing moved.
      if(game.replay && Array.isArray(game.replay.move)) {
        const replayArray = game.replay.move[i];
        if(Array.isArray(replayArray) && replayArray.length > 0) {
          working.replayStage.push(i);
        }
      }
    }
  }

  if(game.replay) {
    /*
    if(Array.isArray(game.replay.arrange)) {
      for(let r = 0; r < game.replay.arrange.length; r++) {
        const arrangeItem = game.replay.arrange[r];
        if(Array.isArray(arrangeItem) && arrangeItem.length === 3) {
          const forceIndex = forceIndexMatchingForceID(working, arrangeItem[0]);
          if(forceIndex >= 0) {
            const rs = BF.REPLAY_STAGE_ADJUST;
            [force[forceIndex].rz[rs], force[forceIndex].rx[rs], force[forceIndex].ry[rs]] =
              forceLocationArray(game, force[forceIndex].rsz, arrangeItem[1], arrangeItem[2]);

            // force[forceIndex].rz[BF.REPLAY_STAGE_ADJUST] = force[forceIndex].rsz;
            // force[forceIndex].rx[BF.REPLAY_STAGE_ADJUST] = arrangeItem[1] / BF.WORLD_WIDTH;
            // force[forceIndex].ry[BF.REPLAY_STAGE_ADJUST] = arrangeItem[2] / BF.WORLD_HEIGHT;
          }
        }
      }
    }
    */

    if(Array.isArray(game.replay.retreat)) {
      for(let r = 0; r < game.replay.retreat.length; r++) {
        const replayItem = game.replay.retreat[r];
        if(Array.isArray(replayItem) && replayItem.length === 4) {
          const forceIndex = forceIndexMatchingForceID(working, replayItem[0]);
          if(forceIndex >= 0) {
            const rs = BF.REPLAY_STAGE_MOVE1;
            [force[forceIndex].rz[rs], force[forceIndex].rx[rs], force[forceIndex].ry[rs]] =
              forceLocationArray(game, replayItem[1], replayItem[2], replayItem[3]);

            // force[forceIndex].rz[BF.REPLAY_STAGE_MOVE1] = replayItem[1];
            // force[forceIndex].rx[BF.REPLAY_STAGE_MOVE1] = replayItem[2] / BF.WORLD_WIDTH;
            // force[forceIndex].ry[BF.REPLAY_STAGE_MOVE1] = replayItem[3] / BF.WORLD_HEIGHT;
          }
        }
      }
    }

    if(Array.isArray(game.replay.move)) {
      for(let rs = 0; rs < game.replay.move.length; rs++) {
        const replayStageArray = game.replay.move[rs];
        if(Array.isArray(replayStageArray)) {
          for(let r = 0; r < replayStageArray.length; r++) {
            const replayItem = replayStageArray[r];
            if(Array.isArray(replayItem) && replayItem.length === 4) {
              const forceIndex = forceIndexMatchingForceID(working, replayItem[0]);
              if(forceIndex >= 0) {
                [force[forceIndex].rz[rs], force[forceIndex].rx[rs], force[forceIndex].ry[rs]] =
                  forceLocationArray(game, replayItem[1], replayItem[2], replayItem[3]);

                // force[forceIndex].rz[rs] = replayItem[1];
                // force[forceIndex].rx[rs] = replayItem[2] / BF.WORLD_WIDTH;
                // force[forceIndex].ry[rs] = replayItem[3] / BF.WORLD_HEIGHT;
              }
            }
          }
        }
      }
    }

    if(Array.isArray(game.replay.hit)) {
      for(let r = 0; r < game.replay.hit.length; r++) {
        const hitItem = game.replay.hit[r];
        if(Array.isArray(hitItem) && hitItem.length > 0) {
          const forceIndex = forceIndexMatchingForceID(working, hitItem[0]);
          if(forceIndex >= 0) {
            force[forceIndex].rz[BF.REPLAY_STAGE_DESTROY] = BF.FORCE_ZONE_DESTROYED;
            force[forceIndex].rx[BF.REPLAY_STAGE_DESTROY] = force[forceIndex].x;
            force[forceIndex].ry[BF.REPLAY_STAGE_DESTROY] = force[forceIndex].y;
          }
        }
      }
    }

    if(Array.isArray(game.replay.takeover)) {
      // Nothing that effects the forces.
    }

    if(Array.isArray(game.replay.land)) {
      for(let rs = 0; rs < game.replay.land.length; rs++) {
        const forceIndex = forceIndexMatchingForceID(working, game.replay.land[rs]);
        if(forceIndex >= 0) {
          force[forceIndex].rz[BF.REPLAY_STAGE_LANDING] = force[forceIndex].z;
          force[forceIndex].rx[BF.REPLAY_STAGE_LANDING] = force[forceIndex].x;
          force[forceIndex].ry[BF.REPLAY_STAGE_LANDING] = force[forceIndex].y;
        }
      }
    }

    if(Array.isArray(game.replay.crash)) {
      for(let rs = 0; rs < game.replay.crash.length; rs++) {
        const forceIndex = forceIndexMatchingForceID(working, game.replay.crash[rs]);
        if(forceIndex >= 0) {
          force[forceIndex].rz[BF.REPLAY_STAGE_CRASH_SPLASH] = BF.FORCE_ZONE_CRASH;
          force[forceIndex].rx[BF.REPLAY_STAGE_CRASH_SPLASH] = force[forceIndex].x;
          force[forceIndex].ry[BF.REPLAY_STAGE_CRASH_SPLASH] = force[forceIndex].y;
        }
      }
    }

    if(Array.isArray(game.replay.splash)) {
      for(let rs = 0; rs < game.replay.splash.length; rs++) {
        const forceIndex = forceIndexMatchingForceID(working, game.replay.splash[rs]);
        if(forceIndex >= 0) {
          force[forceIndex].rz[BF.REPLAY_STAGE_CRASH_SPLASH] = BF.FORCE_ZONE_SPLASH;
          force[forceIndex].rx[BF.REPLAY_STAGE_CRASH_SPLASH] = force[forceIndex].x;
          force[forceIndex].ry[BF.REPLAY_STAGE_CRASH_SPLASH] = force[forceIndex].y;
        }
      }
    }

    if(Array.isArray(game.replay.purchase)) {
      for(let rs = 0; rs < game.replay.purchase.length; rs++) {
        const forceIndex = forceIndexMatchingForceID(working, game.replay.purchase[rs]);
        if(forceIndex >= 0) {
          force[forceIndex].rz[BF.REPLAY_STAGE_PLACEMENT] = force[forceIndex].z;
          force[forceIndex].rx[BF.REPLAY_STAGE_PLACEMENT] = force[forceIndex].x;
          force[forceIndex].ry[BF.REPLAY_STAGE_PLACEMENT] = force[forceIndex].y;
        }
      }
    }

  }

  // Fill in the blanks for the replay stages for all forces:
  for(let rs = 1; rs < BF.MAX_REPLAY_STAGES; rs++) {
    for(let i = 0; i < forces; i++) {
      if(force[i].rz[rs] === undefined) {
        force[i].rz[rs] = force[i].rz[rs - 1];
        force[i].rx[rs] = force[i].rx[rs - 1];
        force[i].ry[rs] = force[i].ry[rs - 1];
      }
    }
  }

  // Do the eliminations (due to loss of capitals) last and show them during placement stage:
  if(game.replay && Array.isArray(game.replay.eliminate)) {
    for(let e = 0; e < game.replay.eliminate.length; e++) {
      const p = game.replay.eliminate[e];
      for(let i = 0; i < forces; i++) {
        if(force[i].owner === p) {
          force[i].rz[BF.REPLAY_STAGE_PLACEMENT] = BF.FORCE_ZONE_DESTROYED;
        }
      }
    }
  }

  function transportForceIndexFromCargoZoneID (cargoZoneID: number) {
    // returns -1 if not found.
    const forceID = -(cargoZoneID - BF.FORCE_ZONE_CARGO);
    for(let i = 0; i < forces; i++) {
      if(force[i].id === forceID) {
        return i;
      }
    }
    return -1;
  }

  // Collect hit forces (not eliminations or crashes) and store for easy reference by last zone:
  for(let i = 0; i < forces; i++) {
    if(force[i].rz[BF.REPLAY_STAGE_DESTROY] === BF.FORCE_ZONE_DESTROYED) {
      const lastZoneID = force[i].rz[BF.REPLAY_STAGE_DESTROY - 1];
      if(lastZoneID > 0) {
        if(!working.zoneHits[lastZoneID]) {
          working.zoneHits[lastZoneID] = [];
        }
        working.zoneHits[lastZoneID].push(force[i].id);
      }
      else if(lastZoneID <= BF.FORCE_ZONE_CARGO) {
        // Explicitly breakout destroyed cargo.
        const transportForceIndex = transportForceIndexFromCargoZoneID(lastZoneID);
        if(transportForceIndex >= 0) {
          const lastZoneID = force[transportForceIndex].rz[BF.REPLAY_STAGE_DESTROY - 1];
          if(lastZoneID > 0) {
            if(!working.zoneHits[lastZoneID]) {
              working.zoneHits[lastZoneID] = [];
            }
            working.zoneHits[lastZoneID].push(force[i].id);
          }
        }
      }
    }
  }

  // Take the appropriate transport location into account for generating the location for cargo.
  for(let i = 0; i < forces; i++) {

    if(force[i].sz < BF.FORCE_ZONE_CARGO) {
      const transportForceIndex = transportForceIndexFromCargoZoneID(force[i].sz);
      if(transportForceIndex >= 0) {
        force[i].sx = force[transportForceIndex].sx;
        force[i].sy = force[transportForceIndex].sy;
      }
    }

    if(force[i].az < BF.FORCE_ZONE_CARGO) {
      const transportForceIndex = transportForceIndexFromCargoZoneID(force[i].az);
      if(transportForceIndex >= 0) {
        force[i].ax = force[transportForceIndex].ax;
        force[i].ay = force[transportForceIndex].ay;
      }
    }

    if(force[i].z < BF.FORCE_ZONE_CARGO) {
      const transportForceIndex = transportForceIndexFromCargoZoneID(force[i].z);
      if(transportForceIndex >= 0) {
        force[i].x = force[transportForceIndex].x;
        force[i].y = force[transportForceIndex].y;
      }
    }

    if(force[i].rsz < BF.FORCE_ZONE_CARGO) {
      const transportForceIndex = transportForceIndexFromCargoZoneID(force[i].rsz);
      if(transportForceIndex >= 0) {
        force[i].rsx = force[transportForceIndex].rsx;
        force[i].rsy = force[transportForceIndex].rsy;
      }
    }

    for(let rs = 0; rs < BF.MAX_REPLAY_STAGES; rs++) {
      if(force[i].rz[rs] < BF.FORCE_ZONE_CARGO) {
        const transportForceIndex = transportForceIndexFromCargoZoneID(force[i].rz[rs]);
        if(transportForceIndex >= 0) {
          force[i].rx[rs] = force[transportForceIndex].rx[rs];
          force[i].ry[rs] = force[transportForceIndex].ry[rs];
        }
      }
    }
  }

  _buildTurnWorkingData(game, working, localPlayerIndex);

    // lastRenderedReplayStage = -1;
    // lastRenderedReplayTime = 0;
    // this.replayAnimateTo(game, 0, 0);

  return working;
}
