/*
  packet.js
  (c) 2013-present Human Cube Inc.
*/

import { store } from '../app/store';
import actions from '../reducers/actions';
import mainModes from '../reducers/mainModes';

import { sendPacket } from './network';
import { bfGetResPacket } from './packetBFGet';
import { bfInResPacket } from './packetBFIn';
import { log } from '../helper/log';


export function hookPackets (socket) {
  socket.on('hc_hello', function(data) {
    log('hc_hello');
    log(data);

    if(data.now) {
      const serverTime = new Date(data.now);
      if( serverTime.getTime() ) {
        const offset = Math.floor((serverTime.getTime() - Date.now()) / 1000);
        store.dispatch({type: actions.SET_SERVER_SECONDS_OFFSET, offset});
      }
    }

    const session = store.getState().userSession;

    if(session.key) {  // TODO: This is not implemented in this react client (is it needed?)
      log('Auto Guest Re-Authing...');
      socket.emit('hc_guest', { key: session.key });
    }
    else if( session.uName && session.password ) {
      log('Auto Re-Authing...');
      socket.emit('hc_login', { uName: session.uName, password: session.password });
    }
    else {
      // If session is not resumed, then head back to the login page:
      store.dispatch({type: actions.SET_USER_SESSION_DATA, data: {}});
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.LOGIN});
    }
  });

  socket.on('hc_session', function (data) {
    log('hc_session');
    log(data);

    if(data.result === 'success') {
      if(data.uName) {
        localStorage.setItem('loginUserName', data.uName);
      }
      if(data.password && localStorage.getItem('loginRemember') === '1') {
        localStorage.setItem('loginPassword', data.password);
      }
      else {
        localStorage.setItem('loginPassword', '');
      }

      let goHome = false;
      if(store.getState().userSession.uid !== data.uid) {
        // User has logged in for the first time for this session, or is a different user.
        // It is important to not go home if the user is the same as they might be doing a turn.
        goHome = true;
      }

      store.dispatch({type: actions.SET_USER_SESSION_DATA, data: data});
      if(goHome) {
        store.dispatch({type: actions.GO_HOME});
        // redirect('/home');
        if(store.getState().news.length === 0) {
          socket.emit('bf_get', {e: 'newsFetch', count: 10});
        }
      }
      return;
    }

    if(data.result === 'badUNameOrPassword') {
      store.dispatch({
        type: actions.SET_LOGIN_ERROR_MESSAGE,
        message: 'Incorrect user name or password.  Please try again.',
      });
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.LOGIN});
    }
    else if(data.result === 'banned') {
      store.dispatch({
        type: actions.SET_LOGIN_ERROR_MESSAGE,
        message: 'Your account has been flagged.  Please check the forum for details.',
      });
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.LOGIN});
    }
    else if(data.result === 'emailNotConfirmed') {
      store.dispatch({
        type: actions.SET_LOGIN_ERROR_MESSAGE,
        message: 'You must confirm your email address in the forum before playing.',
      });
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.LOGIN});
    }
    else {
      store.dispatch({
        type: actions.SET_LOGIN_ERROR_MESSAGE,
        message: 'Error.  Please try again later.',
      });
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.LOGIN});
    }
  });

  socket.on('bf_getRes', function(data) {
    bfGetResPacket(data);
  });

  socket.on('bf_inRes', function(data) {

    console.log('bf_inRes', data);

    bfInResPacket(data);
  });

/*
    // socket.on('bf_getMapListRes', function (data) {
    //     log('bf_getMapListRes');
    //     log(data);

    //     $.mobile.hidePageLoadingMsg();

    //     if( data.result == 'success' ) {
    //         if( mapMakeData.mapID == -1 ) {
    //             // Map editing.
    //             var md = '<h2>Map List</h2>' +
    //                      '<p>Choose a map to start with:</p>';
    //             for(var i = 0; i < data.data.length; i++) {
    //                 md += '<p><a href="#" data-role="button" data-inline="true" data-icon="arrow-r" ' +
    //                     'data-iconpos="right" onclick="mapMakeStartFromMap(' +
    //                     data.data[i].id + '); return false" rel="external">';
    //                 md += data.data[i].name;
    //                 md += '</a></p>';
    //             }
    //             $('#pageChooseMapForMapContent').html(md).trigger('create');
    //             changePage('pageChooseMapForMap');
    //         }
    //         else {
    //             // Scenario editing.
    //             var md = '<h2>Map List</h2>' +
    //                      '<p>Choose a map for your scenario:</p>';
    //             for(var i = 0; i < data.data.length; i++) {
    //                 md += '<p><a href="#" data-role="button" data-inline="true" data-icon="arrow-r" ' +
    //                     'data-iconpos="right" onclick="scenarioMakeStartFromMap(' +
    //                     data.data[i].id + '); return false" rel="external">';
    //                 md += data.data[i].name;
    //                 md += '</a></p>';
    //             }
    //             $('#pageChooseMapForScenarioContent').html(md).trigger('create');
    //             changePage('pageChooseMapForScenario');
    //         }
    //     }
    //     else {
    //       store.dispatch({
    //         type: actions.NOTIFY_MODAL_PUSH,
    //         title: 'Unable to Fetch Map List',
    //         body: 'Please try again later.',
    //       });
    //     }
    // });


    socket.on('bf_getScenarioListRes', function (data) {
        log('bf_getScenarioListRes');
        log(data);

        $.mobile.hidePageLoadingMsg();
        if( data.result == 'success' ) {
            var md = '<h2>Scenario List</h2>' +
                     '<p>Choose a scenario for the new game:</p>';
            for(var i = 0; i < data.data.length; i++) {
                md += '<p>';
                md += '<a href="#" data-role="button" data-icon="arrow-r" data-iconpos="right"' +
                        'onclick="changePage(\'scenario?sid=' + data.data[i].id + '\');" ' +
                        'return false" rel="external">' +
                        data.data[i].name + ' #' + data.data[i].id + '</a>';
                md += '</p>';
            }
            $('#pageChooseScenarioForGameContent').html(md).trigger('create');
            changePage('pageChooseScenarioForGame');
        }
        else {
          store.dispatch({
            type: actions.NOTIFY_MODAL_PUSH,
            title: 'Unable to Fetch Scenario List',
            body: 'Please try again later.',
          });
        }
    });
*/

  socket.on('bf_getMapRes', function (data) {
    log('bf_getMapRes');
    log(data);
    if( data.result === 'success' ) {
      store.dispatch({type: actions.MAP_ADD, map: data.map});
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Fetch Map',
        body: 'Error: ' + data.result,
      });
    }
  });

  socket.on('bf_getScenarioRes', function (data) {
    log('bf_getScenarioRes');
    log(data);
    if( data.result === 'success' ) {
      const mapID = data.scenario.mapID;
      if(mapID && !store.getState().map[mapID]) {
        // Load up the map for the scenario incase preview is desired.
        sendPacket('bf_getMap', { mapID });
      }
      if(store.getState().scenarioID === data.scenario._id) {
        store.dispatch({type: actions.SET_SCENARIO, scenario: data.scenario});
      }
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Fetch Scenario',
        body: 'Error: ' + data.result,
      });
    }
  });

  socket.on('bf_mapSaveRes', function (data) {
    log('bf_mapSaveRes');
    log(data);
    if(data.result === 'success') {
      store.dispatch({ type: actions.MAP_EDIT_SAVED, mapID: data.mapID });

      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'New Map Saved Successfully',
        body: 'New map has been successfully saved and added to your My Map list.',
      });
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Save New Map. Please try again later. ',
        body: '[' + data.result + ']: ' + data.message,
      });
    }
  });

  socket.on('bf_scenarioSaveRes', function (data) {
    log('bf_scenarioSaveRes');
    log(data);

    if( data.result === 'success' ) {
      store.dispatch({ type: actions.SCENARIO_EDIT_SAVED, scenarioID: data.scenarioID });

      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'New Scenario Saved Successfully',
        body: 'New scenario has been successfully saved and added to your My Scenarios list.',
      });
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Save New Scenario. Please try again later. ',
        body: '[' + data.result + ']: ' + data.message,
      });
    }
  });

  socket.on('bf_gameCreateRes', function (data) {
    log('bf_gameCreateRes');
    log(data);
    if( data.result === 'success' ) {
      if(Array.isArray(data.userGame)) {
        store.dispatch({
          type: actions.USER_SESSION_UPDATE_SOME_BF,
          game: data.userGame,
        });
      }
      if( data.PIN ) {
        store.dispatch({
          type: actions.NOTIFY_MODAL_PUSH,
          title: 'Private Game Created Successfully',
          body: 'PIN required for others to access it, is: ' + data.PIN,
        });
      }
      sendPacket('bf_get', {e: 'gameString', gameID: data.game});
      store.dispatch({type: actions.GO_HOME});
    }
    else if( data.result === 'maxGamesReached' ) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Create New Game',
        body: 'You have already reached the maximum number of active games permitted for your membership level.',
      });
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Create New Game',
        body: '',
      });
    }
  });

  socket.on('bf_gameJoinRes', function (data) {
    log('bf_gameJoinRes');
    log(data);

    if(data.result === 'success') {
      if(data.userGame) {
        store.dispatch({
          type: actions.USER_SESSION_UPDATE_SOME_BF,
          game: data.userGame,
        });
      }
      sendPacket('bf_get', {e: 'gameString', gameID: data.gameID});
    }
    else if(data.result === 'maxGamesReached') {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Join Game',
        body: 'You have already reached the maximum number of active games permitted for your membership level.',
      });
    }
    else if(data.result === 'blocked') {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Join Game',
        body: 'One or more players already registered for this game have you on their block list. Please choose a different game.',
      });
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Join Game',
        body: 'Please try again later.',
      });
    }

    // TODO:  ???
    // Force an update of watch status for the game.
    // This will unwatch if in game, or watch if not in game:
    // if(viewedGameID)
    //   socket.emit('bf_get', {e: 'watch', gameID: viewedGameID});
  });

  socket.on('bf_gameUnjoinRes', function (data) {
    log('bf_gameUnjoinRes');
    log(data);

    if( data.result === 'success' ) {
      if( data.userGame ) {
        store.dispatch({
          type: actions.USER_SESSION_UPDATE_SOME_BF,
          game: data.userGame,
        });
      }
      sendPacket('bf_get', {e: 'gameString', gameID: data.gameID});
    }

    // Force an update of watch status for the game.
    // This will unwatch if in game, or watch if not in game:
    // if(viewedGameID)
    //   socket.emit('bf_get', {e: 'watch', gameID: viewedGameID});
  });

  socket.on('bf_gameCancelRes', function (data) {
    log('bf_gameCancelRes');
    log(data);

    if(data.result === 'success') {
      if( data.userGame ) {
        store.dispatch({
          type: actions.USER_SESSION_UPDATE_SOME_BF,
          game: data.userGame,
          finishedCount: data.userFinishedCount
        });
      }
      sendPacket('bf_get', {e: 'gameString', gameID: data.gameID});
    }
    else {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Unable to Cancel Game',
        body: 'It may have already started.',
      });
    }
  });

  socket.on('bf_getUserFinishedListRes', function (data) {
    log('bf_getUserFinishedListRes');
    log(data);
    if( data.result === 'success' ) {
      const fetchList = [];
      const gameStatus = store.getState().gameStatus;
      for(let i = 0; i < data.finished.length; i++) {
        const gameID = data.finished[i];
        if(!gameStatus[gameID]) {
          fetchList.push(gameID);
        }
      }
      if(fetchList.length > 0) {
        sendPacket('bf_get', {e: 'gameString', gameID: fetchList.slice(0, 100)});
      }
      store.dispatch({type: actions.SET_USER_FINISHED_LIST, data: data.finished});
    }
  });

  socket.on('bf_submitTurnRes', function (data) {
    log('bf_submitTurnRes');
    log(data);
    // TODO: Some state change or feedback needed here for the player...
  });

  socket.on('bf_sawFinishedGameRes', function (data) {
    log('bf_sawFinishedGameRes');
    log(data);
    if( data.result === 'success' ) {
      store.dispatch({
        type: actions.USER_SESSION_UPDATE_SOME_BF,
        game: data.game,
        finishedCount: data.finishedCount
      });
    }
  });

}
