/*
  Root.tsx
  (c) Human Cube Inc.
*/

import React from 'react';
import { connect } from 'react-redux';
import { Stage } from '@pixi/react';
import BaseNotifyModal from '../components/BaseGui/BaseNotifyModal';
import Forces from '../components/GamePixi/Forces';
import GuiLayer from '../components/GuiLayer';
import Map from '../components/GamePixi/Map';
import MapEdit from '../components/GamePixi/MapEdit';
import MapIncome from '../components/GamePixi/MapIncome';
// import actions from '../reducers/actions';
// import { store } from '../app/store';
import mainModes from '../reducers/mainModes'


const mapStateToProps = (state: any) => {
  return {
    store: state,
  };
};

const ConnectedApp = (props: any) => {
  const { dimensions } = props;
  const width = dimensions.width;
  const height = dimensions.height - 4;
  const xCenter = Math.floor(width / 2);
  const yCenter = Math.floor(height / 2);

  let stage = null;
  switch (props.store.mainMode) {
    case mainModes.GAME_LOADING:
      stage = (
        <div>LOADING...</div>
      );
      break;
    case mainModes.GAME_REPLAY:
    case mainModes.GAME_DOING_TURN:
    case mainModes.GAME_SUBMITTING_TURN:
    case mainModes.SCENARIO_PREVIEW:
    case mainModes.SCENARIO_EDIT:
      stage = (
        <Stage width={width} height={height} options={{ backgroundColor: 0x1099bb }} >
          <Map store={props.store} x={xCenter} y={yCenter} />
          <Forces store={props.store} x={xCenter} y={yCenter} />
          <MapIncome store={props.store} x={xCenter} y={yCenter} />
        </Stage>
      );
      break;
    case mainModes.MAP_EDIT:
      stage = (
        <Stage width={width} height={height} options={{ backgroundColor: 0x1099bb }} >
          <MapEdit store={props.store} xCenter={xCenter} yCenter={yCenter} />
        </Stage>
      );
      break;
    default:
      break;
  }

  return (
    <React.Fragment>
      <div id="guidiv"
        style={{
          top: '0px', border: '0px', padding: '0px', borderRadius: '0', margin: '0',
          width: dimensions.width, height: dimensions.height, overflow: 'hidden'
        }} >
        <GuiLayer store={props.store} xCenter={xCenter} yCenter={yCenter} />
      </div>
      {stage}
      <BaseNotifyModal notifyModals={props.store.notifyModals} />
    </React.Fragment>
  );
};

const Root = connect(mapStateToProps)(ConnectedApp);
export default Root;
