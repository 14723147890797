/*
  GuiMouseoverInfo.tsx
  (c) 2024 Human Cube Inc.
*/

import { Flag, FlagNameValues } from 'semantic-ui-react';

import BF from '../../bfcore/bfconst1';
import { Game } from '../../bfcore/gameTypes';
import { WorkingData } from '../../bfworking/workingTypes';
import ForcesGroup from './GuiForcesGroup';
import GuiCircleNumber from './GuiCircleNumber';
import { calcLiftersByLiftsSimple, calcTransportsByCargoSimple }
  from '../../helper/forceDetailsForZone';


const GuiMouseoverInfo = ({game, working, zoneID, replayStage}:
  {game: Game, working: WorkingData, zoneID: number, replayStage: number}
) => {
  const players = game.players;
  const forcetypes = game.forceID.length;

  const zoneOwner =
    (replayStage >= BF.REPLAY_STAGE_ADJUST && replayStage < BF.REPLAY_STAGE_ZONE_CHANGE)
    ? game.zoneOwnerPrev[zoneID]
    : game.zoneOwner[zoneID];

  const transportsByCargoSimple = calcTransportsByCargoSimple(game, working, zoneID, replayStage);
  const liftersByLiftsSimple = calcLiftersByLiftsSimple(game, working, zoneID, replayStage);

  // Array of [playerIndex][forceTypeIndex] count values:
  const here: number[][] = [];
  const some: boolean[] = [];
  for(let p = 0; p <= players; p++) {
    here[p] = new Array(forcetypes).fill(0);
  }
  if(replayStage < 0) {
    for(let f = 0; f < working.force.length; f++) {
      const { owner, z, ft } = working.force[f];
      if(z === zoneID) {
        here[owner][ft]++;
        some[owner] = true;
      }
    }
  }
  else { // Replay mode, so use current position from rz.
    for(let f = 0; f < working.force.length; f++) {
      const { owner, rz, ft } = working.force[f];
      const z = rz[replayStage];
      if(z === zoneID) {
        here[owner][ft]++;
        some[owner] = true;
      }
    }
  }

  const line = [];
  if(some[0]) {  // Neutrals set first.
    line.push(
      <ForcesGroup
        key={ 'fg' + 0 } playerIndex={0} game={game} counts={here[0]}
        transportsByCargoSimple={transportsByCargoSimple}
        liftersByLiftsSimple={liftersByLiftsSimple}
      />
    );
  }
  if(zoneOwner > 0) {  // Owner set next.
    if(some[zoneOwner]) {
      line.push(
        <ForcesGroup
          key={ 'fg' + zoneOwner }  playerIndex={zoneOwner} game={game} counts={here[zoneOwner]}
          transportsByCargoSimple={transportsByCargoSimple} 
          liftersByLiftsSimple={liftersByLiftsSimple}
        />
      );
    }
  }
  for(let p = 1; p <= players; p++) {  // Then everyone else.
    if(some[p] && p !== zoneOwner) {
      line.push(
        <ForcesGroup
          key={ 'fg' + p } playerIndex={p} game={game} counts={here[p]}
          transportsByCargoSimple={transportsByCargoSimple} 
          liftersByLiftsSimple={liftersByLiftsSimple}
        />
      );
    }
  }

  return (
    <div
      className='flex items-center justify-center w-full'
      style={{ position: 'absolute', top: 46, left: 1 }}
    >
      <div
        className='bg-slate-300 pt-2 px-2 rounded-md border border-slate-500 opacity-85'
        style={{ height: 53 }}
      >
        <div className='flex flex-row'>
          <div className='flex flex-col'>
            <div style={{ marginTop: 0, marginLeft: 4, width: 20, height: 16 }}>
              { zoneOwner > 0 &&
                <div><Flag name={ game.playerFlag[zoneOwner] as FlagNameValues } /></div>
              }
            </div>
            <div style={{ marginTop: -3 }}>
              <GuiCircleNumber value={ game.zoneIncome[zoneID] } />
            </div>
          </div>
          <div className='flex flex-row'>
            { line }
          </div>
        </div>
      </div>
    </div>
  );
};


export default GuiMouseoverInfo;
